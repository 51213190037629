import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { ToggleSwitch } from 'custom_components/ToggleSwitch'
import { useDebounce } from 'helpers'
import { useCreatePrivateEvent, useGetPrivateEventsV2 } from 'privateEvents/api/useQueries'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2, { TASKS_PRIVATE_EVENT_URL } from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetTaskEvents, useGetTasks } from 'tasksV2/api/useQueries'
import { CREATE_TASK_BUTTON_CONTENT, TaskV2 } from 'tasksV2/constants/tasks'
import {
    useCompanyContactsQuery,
    useCompanyEmailsQuery,
    useCompanyIncomingEmailsQuery,
    useCompanyNotesQuery,
} from '../../api/useQueries'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import FilterToolBarV2 from '../FilterToolBarV2'
import { CompanyEvents } from './CompanyEvents'

export default function ActivityListV3({ company }: any) {
    const { users } = useContext<ProcurementContextType>(ProcurementContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get('pModalSearch') || ''
    const user = useSelector<any, any>((state) => state.user)
    const [privateEventLogged, setPrivateEventLogged] = useState(false)
    const queryClient = useQueryClient()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [showAll, setShowAll] = useState(false)

    const companyContactsQuery = useCompanyContactsQuery({ companyId: company.id })
    const { contacts } = companyContactsQuery.data || {}

    const companyEventsQuery = useCompanyNotesQuery({ companyId: company.id, users, search: searchParam })
    const { isLoading: loadingEvents } = companyEventsQuery
    const { events: companyEvents }: { events: EventType[] } = companyEventsQuery?.data || {}

    const edits = companyEvents?.filter((event) => event.type != 'note')
    const notes = companyEvents?.filter((event) => event.type == 'note')
    const createPrivateEvent = useCreatePrivateEvent()

    const tasksQuery = useGetTasks(
        { resourceTable: 'company_tasks', resourceIds: [company.id], search: searchParam },
        keepPreviousData
    )
    const { tasks }: { tasks: TaskV2[] } = tasksQuery?.data || {}

    const taskIds = tasks?.map((taskV2: any) => taskV2.id.toString())
    const taskEventsQuery = useGetTaskEvents(taskIds)
    const { events: taskEvents } = taskEventsQuery?.data || {}

    const privateEventsQuery = useGetPrivateEventsV2({
        privateEventUrl: TASKS_PRIVATE_EVENT_URL,
        ids: taskIds,
        enabled: taskIds?.length > 0,
    })
    const { taskEvents: privateEvents } = privateEventsQuery?.data || {}
    useEffect(() => {
        if (!privateEventLogged && taskIds?.length) {
            createPrivateEvent.mutate({ ids: taskIds, user_id: user.id, privateEventUrl: TASKS_PRIVATE_EVENT_URL })
            setPrivateEventLogged(true)
        }
    }, [taskIds])

    tasks?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    const companyIncomingEmailsQuery = useCompanyIncomingEmailsQuery({
        companyId: company.id,
        companyEmail: company.email,
        contactList: contacts || [],
        search: searchParam,
    })
    const { emails: incomingEmails }: { emails: any[] } = companyIncomingEmailsQuery?.data || {}

    const companyEmailsQuery = useCompanyEmailsQuery({ companyId: company.id, search: searchParam })
    const { isLoading: loadingEmails } = companyEmailsQuery
    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    const sortedTasks = tasks?.toSorted((a: any, b: any) => {
        if (a.id < b.id) {
            return -1
        } else {
            return 1
        }
    })

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'pModalSearch',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
    ]
    // ACTIVE FILTERS

    const events: any[] = [edits, notes, taskEvents, emails, incomingEmails].flat().filter((v) => v)
    const eventsLoaded =
        !loadingEvents && !taskEventsQuery.isLoading && !loadingEmails && !companyIncomingEmailsQuery.isLoading

    return (
        <div className=' p-0 h-[87vh]'>
            <FilterToolBarV2 activeFilters={activeFilters} showLoading={true} isLoading={!eventsLoaded}>
                <div className='flex flex-col gap-2 items-center'>
                    <Button variant={'outline'} size={'sm'} className='flex gap-1' onClick={() => setShowAll(!showAll)}>
                        <p className='text-sm'>Completed Tasks</p>
                        <ToggleSwitch
                            className=''
                            checked={showAll}
                            onClick={() => setShowAll(!showAll)}
                        ></ToggleSwitch>
                    </Button>
                </div>
            </FilterToolBarV2>

            <div className='grid grid-cols-[3fr_2fr] gap-[4px] items-start justify-start h-full min-h-0'>
                {/* left column  */}
                <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] p-1 grow shrink max-h-full overflow-auto col-span-1'>
                    <CompanyEvents events={events} tasks={tasks} company={company} eventsLoaded={eventsLoaded} />
                </div>
                {/* right column */}
                <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start pr-[2px]  max-h-full overflow-scroll'>
                    <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                        <div className='grid gap-2 rounded p-1 h-full'>
                            <div className='flex flex-col gap-3'>
                                <div className='flex flex-col gap-3'>
                                    {tasks?.length > 0 &&
                                        sortedTasks
                                            ?.filter((task: any) => task.status != 'Archived')
                                            ?.filter((task: any) => task.status !== 'Completed')
                                            .map((task: any) => (
                                                <React.Fragment key={task.id.toString() + 'procurementTask'}>
                                                    <TaskEditCreateModalV2
                                                        expand={true}
                                                        variant='small'
                                                        taskId={task.id.toString()}
                                                        createEvent={false}
                                                        privateEvents={privateEvents}
                                                        fetchPrivateEvents={false}
                                                    />
                                                </React.Fragment>
                                            ))}
                                </div>
                                <div className='flex flex-col gap-[8px] '>
                                    {tasks?.length > 0 &&
                                        showAll &&
                                        sortedTasks
                                            ?.filter((task: any) => task.status != 'Archived')
                                            ?.filter((task: any) => task.status === 'Completed')
                                            .map((task: any) => (
                                                <React.Fragment key={task.id.toString() + 'procurementTask'}>
                                                    <TaskEditCreateModalV2
                                                        expand={true}
                                                        variant='small'
                                                        taskId={task.id.toString()}
                                                        createEvent={false}
                                                        privateEvents={privateEvents}
                                                        fetchPrivateEvents={false}
                                                    />
                                                </React.Fragment>
                                            ))}
                                </div>
                                <div className='flex flex-col justify-center items-center gap-[8px]'>
                                    {tasks?.length == 0 && <div>No Tasks Found</div>}
                                    <Button
                                        variant={'outline'}
                                        size={'sm'}
                                        onClick={() => toggleCreateModal()}
                                        className='flex gap-1 items-center'
                                    >
                                        <p>{CREATE_TASK_BUTTON_CONTENT}</p>
                                    </Button>
                                </div>
                                <div className='h-[50px]'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
