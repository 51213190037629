import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import queryKeys from './queryKeys'
import * as orderQueryKeys from '../../orderHubScreen/api/queryKeys'
import * as service from './service'
import { useGetPathname } from 'productSetupV2/api/useQueries'
import draftOrderQueryKeys from 'orderHubScreenDraft/api/draftOrderQueryKeys'
import { procurementQueries } from 'procurement/api/procurementQueryKeys'

export const useGetDepartments = (filters: any) => {
    return useQuery({
        ...queryKeys.taskDepartments.list(filters),
        placeholderData: keepPreviousData,
    })
}

export const useGetDepartmentConfig = (departmentId: string) => {
    return useQuery({
        ...queryKeys.taskDepartments.detail(departmentId),
    })
}

export const useGetAllDepartmentConfigs = (userId: string) => {
    return useQuery({
        ...queryKeys.taskDepartments.userDepartments(userId),
        gcTime: 1000 * 60 * 15,
    })
}

export const useGetDevelopmentDepartment = () => {
    return useQuery({
        queryKey: ['development_task_department'],
        queryFn: () => service.getDevelopmentDepartment(),
    })
}

export const useCreateDepartment = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (department: any) => service.createDepartment(department),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments.list._def })
        },
    })
}

export const useUpdateDepartment = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (department: any) => service.updateDepartment(department),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
        },
    })
}

export const useDeleteDepartment = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (departmentId: number) => service.deleteDepartment(departmentId),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
        },
    })
}

// department_estimates / department_types

export const useCreateDepartmentOption = (type: 'estimates' | 'types' | 'statuses') => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (options: {
            task_type_id?: number
            task_estimate_id?: number
            task_status_id?: number
            position: number
            department_id: string
        }) => service.createDepartmentOption({ ...options, type }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks.options._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
        },
    })
}

export const useUpdateDepartmentOption = (type: 'estimates' | 'types' | 'statuses' | 'statuses') => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (options: { id: number; position?: number }) =>
            service.updateDepartmentOption({ ...options, type }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks.options._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
        },
    })
}

export const useDeleteDepartmentOption = (type: 'estimates' | 'types' | 'statuses' | 'statuses') => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (options: { id: number }) => service.deleteDepartmentOption({ ...options, type }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks.options._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
        },
    })
}

// task_estimates / task_types

export const useCreateOption = (type: 'estimates' | 'types' | 'statuses') => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (options: { value: string }) => service.createTaskOption({ ...options, type }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.tasks.options._def })
        },
    })
}

export const useUpdateOption = (type: 'estimates' | 'types' | 'statuses') => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (options: { id: number; value: string }) => service.updateTaskOption({ ...options, type }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.tasks.options._def })
        },
    })
}

export const useDeleteOption = (type: 'estimates' | 'types' | 'statuses') => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (options: { id: number }) => service.deleteTaskOption({ ...options, type }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.taskDepartments._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.tasks.options._def })
        },
    })
}

export const useGetRecurringTasks = (filters: any) => {
    return useQuery({
        ...queryKeys.recurringTasks.list(filters),
    })
}

export const useGetTasks = (filters: any, placeholderData?: any) => {
    return useQuery({
        ...queryKeys.tasks.list(filters),
        placeholderData: placeholderData,
        enabled: Boolean(filters.resourceIds ? filters.resourceIds?.length : true),
    })
}
export const useGetTasksSearchBar = (filters: any, placeholderData?: any) => {
    return useQuery({
        ...queryKeys.tasks.list(filters),
        placeholderData: placeholderData,
        enabled: Boolean(filters.search),
    })
}

export function useGetTasksQueryCalendar({ filters, day }: { filters: any; day: any }): any {
    const query = useQuery({
        ...queryKeys.tasks.list(filters),
        staleTime: 50000,
        placeholderData: keepPreviousData,
        select: (data) => {
            const filteredTasks: object[] = []
            data.tasks?.forEach((task: any) => {
                const taskDay = new Date(task.due_at)
                taskDay.setHours(0, 0, 0, 0)
                if (taskDay.toISOString() == day.toISOString()) {
                    filteredTasks.push(task)
                }
            })
            return {
                ...data,
                tasks: filteredTasks,
            }
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useTasksQueryCalendar',
        },
    })

    return query
}

export const useGetTask = (task_id: string) => {
    return useQuery({
        ...queryKeys.tasks.detail(task_id),
        enabled: task_id !== '',
    })
}

export const useGetTaskEvents = (taskIds: string[] | undefined) => {
    return useQuery({
        ...queryKeys.events.list(taskIds),
        enabled: Boolean(taskIds?.length),
    })
}

export const useCreateTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (task: any) => service.createTask(task),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.resourceTasks._def })
            queryClient.invalidateQueries({ queryKey: queryKeys.projects._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.tasks.list._def })
        },
    })
}

export const useUpdateTask = (task_id: string) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (task: any) => service.updateTask(task),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.resourceTasks._def })
            queryClient.invalidateQueries({ queryKey: queryKeys.projects._def })
            queryClient.invalidateQueries({ queryKey: queryKeys.events.list._def })
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies.detail._def })
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies.list._def })
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks.list._def })
            queryClient.invalidateQueries({ queryKey: orderQueryKeys.orders.orderTaskEvents._def })
            queryClient.invalidateQueries({ queryKey: draftOrderQueryKeys.draftOrders.orderTaskEvents._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.tasks.detail(task_id.toString()).queryKey })
        },
    })
}

export const useDeleteTask = (task_id: string) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async () => service.deleteTask(parseInt(task_id)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.resourceTasks._def })
            queryClient.invalidateQueries({ queryKey: queryKeys.projects._def })
            queryClient.invalidateQueries({ queryKey: orderQueryKeys.orders.orderTaskEvents._def })
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks.detail(task_id).queryKey })
            return queryClient.invalidateQueries({ queryKey: queryKeys.tasks.list._def })
        },
    })
}

export const useGetTaskOptions = ({
    type,
    enabled = true,
}: {
    type: 'estimates' | 'types' | 'statuses' | 'statuses'
    enabled?: boolean
}) => {
    return useQuery({
        ...queryKeys.tasks.options(type),
        enabled,
    })
}

export const useGetOrders = (search: string) => {
    return useQuery({
        ...queryKeys.associations.orders(search),
    })
}

export const useGetOrderLineItemsSearch = (search: string) => {
    return useQuery({
        ...queryKeys.associations.orderLineItemsSearch(search),
        enabled: Boolean(search),
    })
}
export const useGetOrderLineItems = ({
    orderLineItemId,
    orderIds,
}: {
    orderLineItemId?: string | undefined
    orderIds?: string[] | undefined
}) => {
    return useQuery({
        ...queryKeys.associations.orderLineItems(orderLineItemId, orderIds),
        enabled: Boolean(orderLineItemId || orderIds?.length),
    })
}

export const useGetDraftOrderLineItemsSearch = (search: string) => {
    return useQuery({
        ...queryKeys.associations.draftOrderLineItemsSearch(search),
        enabled: Boolean(search),
    })
}
export const useGetDraftOrderLineItems = ({
    draftOrderLineItemId,
    draftOrderIds,
}: {
    draftOrderLineItemId?: string | undefined
    draftOrderIds?: string[] | undefined
}) => {
    return useQuery({
        ...queryKeys.associations.draftOrderLineItems(draftOrderLineItemId, draftOrderIds),
        enabled: Boolean(draftOrderLineItemId || draftOrderIds?.length),
    })
}

export const useGetDraftOrders = (search: string) => {
    return useQuery({
        ...queryKeys.associations.draftOrders(search),
    })
}

export const useCreateRecurringTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (recurringTask: any) => service.createRecurringTask(recurringTask),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.recurringTasks._def })
        },
    })
}
export const useUpdateRecurringTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (recurringTask: any) => service.updateRecurringTask(recurringTask),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.recurringTasks._def })
        },
    })
}
export const useDeleteRecurringTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (recurringTaskId: string) => service.deleteRecurringTask(recurringTaskId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKeys.tasks._def })
            return queryClient.invalidateQueries({ queryKey: queryKeys.recurringTasks._def })
        },
    })
}

export const useCreateProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (project: any) => service.createProject(project),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.projects.list._def })
        },
    })
}

export const useGetProjects = (filters: any) => {
    return useQuery({
        ...queryKeys.projects.list(filters),
        placeholderData: keepPreviousData,
    })
}

export const useGetProject = (projectId: string | number) => {
    return useQuery({
        ...queryKeys.projects.detail(projectId),
    })
}

export const useGetProjectTasks = (projectId: string | number) => {
    return useQuery({
        ...queryKeys.projects.detail(projectId)._ctx.tasks(),
    })
}

export const useUpdateProject = (projectId: string | number) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (update: any) => service.updateProject({ id: projectId, ...update }),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.projects.list._def }),
                queryClient.invalidateQueries({ queryKey: queryKeys.projects.detail(projectId).queryKey }),
            ])
        },
    })
}

export const useDeleteProject = (projectId: string | number) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async () => service.deleteProject(projectId),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.projects.list._def })
        },
    })
}

export const useGetProjectEvents = (project_id: string) => {
    return useQuery({
        ...queryKeys.projects.detail(project_id)._ctx.events(),
    })
}

export const useCreateRoadmap = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (roadmap: any) => service.createRoadmap(roadmap),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.roadmaps.list._def })
        },
    })
}

export const useGetRoadmaps = (filters: any) => {
    return useQuery({
        ...queryKeys.roadmaps.list(filters),
        placeholderData: keepPreviousData,
    })
}

export const useGetRoadmap = (roadmapId: string | number) => {
    return useQuery({
        ...queryKeys.roadmaps.detail(roadmapId),
    })
}

export const useGetRoadmapProjects = (roadmapId: string | number) => {
    return useQuery({
        ...queryKeys.roadmaps.detail(roadmapId)._ctx.projects(),
    })
}

export const useUpdateRoadmap = (roadmapId: string | number) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (update: any) => service.updateRoadmap({ id: roadmapId, ...update }),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.roadmaps.detail(roadmapId).queryKey }),
                queryClient.invalidateQueries({ queryKey: queryKeys.roadmaps.list._def }),
            ])
        },
    })
}

export const useDeleteRoadmap = (roadmapId: string | number) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async () => service.updateProject(roadmapId),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: queryKeys.roadmaps.list._def })
        },
    })
}

export const useGetRoadmapEvents = (roadmap_id: string) => {
    return useQuery({
        ...queryKeys.roadmaps.detail(roadmap_id)._ctx.events(),
    })
}

export const useGetResourceTasks = (resource_name: string, resource_id: number) => {
    return useQuery({
        ...queryKeys.resourceTasks.list(resource_name, resource_id),
    })
}
