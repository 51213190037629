import { useContext, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { MdContentCopy, MdDoubleArrow } from 'react-icons/md'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn, sendToast } from '../../../helpers'
import { VendorContactEditInit } from '../../../vendors/vendors.types'
import { useArchiveVendorContact, useCompanyContactsQuery, useVendorContactsQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import FilterToolBar from '../FilterToolBar'
import CompanyContactV2 from '../listItems/CompanyContactV2'
import CompanySideBarContact from '../listItems/CompanySideBarContact'
import { CompanyType } from 'procurement/constants'
import { useSearchParams } from 'react-router-dom'
import FilterToolBarV2 from '../FilterToolBarV2'

export default function ContactListV2({
    company,
    setSelectedLegacyContact,
}: {
    company: CompanyType
    setSelectedLegacyContact?: any
}) {
    const { setSelectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get('pModalSearch') || ''
    const [showLegacyContacts, setShowLegacyContacts] = useState(false)
    const [showLegacyHubspotContacts, setShowLegacyHubspotContacts] = useState(false)
    const [showArchiveAreYouSure, setShowArchiveAreYouSure] = useState()

    const companyContactsQuery = useCompanyContactsQuery({ companyId: company.id })

    const { contacts } = companyContactsQuery.data || {}

    const archiveVendorContact = useArchiveVendorContact()

    const handleArchive = (contact: any) => {
        archiveVendorContact.mutate({ contact })
    }

    const latestContacts = contacts?.filter((contact: any) => contact.updated_at != '2024-03-29T20:41:47.000Z')
    const hubspotContacts = contacts?.filter((contact: any) => {
        return contact.updated_at == '2024-03-29T20:41:47.000Z'
    })

    const vendorContactsQuery = useVendorContactsQuery({ companyId: company.id })
    const { vendorContacts } = vendorContactsQuery.data || {}

    const isLoading = companyContactsQuery.isLoading || vendorContactsQuery.isLoading

    const vendorContactClassName = (contact: VendorContactEditInit) =>
        `js-vendors-list__${contact.vendor.trim()}-contact`

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'pModalSearch',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
    ]
    // ACTIVE FILTERS

    return (
        <div className='flex flex-col gap-5 h-[89vh]'>
            <div className='max-h-full'>
                <FilterToolBarV2 activeFilters={activeFilters} showLoading={true} isLoading={isLoading}>
                    <div className='flex flex-col gap-2 items-center'>
                        <Button
                            variant={'outline'}
                            size={'sm'}
                            onClick={() => {
                                setSelectedCompany(company)
                                return setShowQuickModal('contact')
                            }}
                        >
                            + New Contact
                        </Button>
                    </div>
                </FilterToolBarV2>
                <div className='flex flex-col overflow-auto'>
                    <div className='border-b border-darkgrey mb-4'>
                        <CompanySideBarContact key={company.id + 'company'} company={company} />
                    </div>
                    <div className='border-t border-darkgrey'>
                        {latestContacts?.map((contact: any) => {
                            return <CompanyContactV2 key={contact.id} contact={contact} />
                        })}
                    </div>
                </div>
                {vendorContacts && Object.keys(vendorContacts).length > 0 && (
                    <Button
                        className='mb-3 mt-3 flex gap-2'
                        variant={'secondary'}
                        onClick={() => setShowLegacyContacts((prev: any) => (prev ? false : true))}
                    >
                        <p className='font-semibold'>Legacy Vendor Contacts</p>
                        <MdDoubleArrow
                            className={`w-[16px] h-[16px] transition-all duration-300 ${
                                showLegacyContacts ? 'rotate-90' : '-rotate-90'
                            }`}
                        />
                    </Button>
                )}
                <div
                    className={cn(
                        `text-base opacity-100 relative grid grid-cols-[repeat(6,auto)_auto] w-full pointer-events-none transition-all duration-300 `,
                        !showLegacyContacts && 'opacity-0 text-[2px]'
                    )}
                >
                    {vendorContacts?.length > 0 &&
                        Object.keys(vendorContacts).length &&
                        vendorContacts?.map((c: any, index: number) => {
                            const vendorValues = [
                                c.contact_type,
                                c.name,
                                [
                                    c.phone_prefix ? '+' + c.phone_prefix + '-' : null,
                                    c.phone
                                        ? [c.phone.slice(0, 3), c.phone.slice(3, 6), c.phone.slice(6)].join('-')
                                        : null,
                                    c.phone_extension ? ', EXT' + c.phone_extension : null,
                                ]
                                    .filter((p) => p)
                                    .join(''),
                                c.email,
                                c.cell_phone
                                    ? `Cell: ${c.cell_phone.slice(0, 3)}-${c.cell_phone.slice(
                                          3,
                                          6
                                      )}-${c.cell_phone.slice(6)}`
                                    : '',
                                c.alternate,
                            ].filter((v) => v)
                            while (vendorValues.length < 6) vendorValues.push('')
                            return (
                                <>
                                    <div
                                        className={`text-[14px] contents cursor-pointer [&>*]:hover:!bg-[rgb(199,202,209)] [&>*]:dark:hover:!bg-blue [&>*:first-child]:pl-[8px] [&>*:last-child]:pr-[8px] ${
                                            index % 2
                                                ? '[&>*]:bg-[rgb(224,228,235)] [&>*]:dark:bg-darkness'
                                                : '[&>*]:dark:bg-darkaccent'
                                        }`}
                                    >
                                        {vendorValues.map((v, i) => (
                                            <div className='flex justify-between items-center pointer-events-auto'>
                                                <p
                                                    style={{ gridRowStart: index + 1 }}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(v)
                                                        sendToast({ message: `Copied: ${v}` })
                                                    }}
                                                    className={`${vendorContactClassName(c)} px-[8px] grid-row-${
                                                        index + 1
                                                    }`}
                                                    draggable={true}
                                                >
                                                    {v}
                                                </p>
                                                {!!v && !!i && (
                                                    <MdContentCopy
                                                        title={`Copy "${v}"`}
                                                        className='js-contact__copy-button cursor-pointer opacity-50 hover:opacity-100 duration-300'
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(v)
                                                            sendToast({ message: `Copied: ${v}` })
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    {showArchiveAreYouSure != c.id && (
                                        <div className='flex gap-6 justify-end items-center p-1'>
                                            <Button
                                                variant={'outline'}
                                                className='pointer-events-auto'
                                                onClick={() => {
                                                    setSelectedCompany(company)
                                                    setShowQuickModal('import')
                                                    return setSelectedLegacyContact
                                                        ? setSelectedLegacyContact(c)
                                                        : undefined
                                                }}
                                            >
                                                Import
                                            </Button>
                                            <FaTrash
                                                className='pointer-events-auto cursor-pointer text-danger'
                                                onClick={() => setShowArchiveAreYouSure(c.id)}
                                            />
                                        </div>
                                    )}
                                    {showArchiveAreYouSure === c.id && (
                                        <div className='flex justify-end gap-2  p-1'>
                                            <Button
                                                variant={'outline'}
                                                className='pointer-events-auto text-danger'
                                                onClick={() => {
                                                    return handleArchive(c)
                                                }}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                variant={'outline'}
                                                className='pointer-events-auto'
                                                onClick={() => setShowArchiveAreYouSure(undefined)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    )}
                                </>
                            )
                        })}
                </div>
            </div>
            <div>
                {hubspotContacts && hubspotContacts.length > 0 && (
                    <Button
                        className='mb-3 flex gap-2'
                        variant={'secondary'}
                        onClick={() => setShowLegacyHubspotContacts((prev: any) => (prev ? false : true))}
                    >
                        <p className='font-semibold'>Legacy Hubspot Contacts</p>
                        <MdDoubleArrow
                            className={`w-[16px] h-[16px] transition-all duration-300 ${
                                showLegacyHubspotContacts ? 'rotate-90' : '-rotate-90'
                            }`}
                        />
                    </Button>
                )}
                <div
                    className={cn(
                        `opacity-100 relative grid grid-cols-[repeat(6,auto)_auto] w-full transition-all duration-300 `,
                        !showLegacyHubspotContacts && 'opacity-0'
                    )}
                >
                    <div className='flex flex-col overflow-auto'>
                        {hubspotContacts &&
                            hubspotContacts.length &&
                            hubspotContacts?.map((contact: any) => {
                                return <CompanyContactV2 key={contact.id} contact={contact} isLegacy={true} />
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}
