import { useEffect, useState } from 'react'
import { useContactTypesQuery, useDeleteContact, useUpdateContact } from '../../api/useQueries'
import { cn, sendToast } from '../../../helpers'
import Input from '../Input'
import { Button } from '../../../custom_components/component_Basics/Button'
import ContactDetails from './ContactDetails'
import Select from '../Select'

export default function CompanyContactV2({ contact, isLegacy }: { contact: any; isLegacy?: boolean }) {
    const [editableFields, setEditableFields] = useState<any>({})
    const [showDelete, setShowDelete] = useState(false)
    const [showDetails, setShowDetails] = useState(false)

    const isEdited = Boolean(Object.keys(editableFields).length)

    const updateContact = useUpdateContact()
    const deleteContact = useDeleteContact()

    const contactTypesQuery = useContactTypesQuery()
    const { types: contactTypes } = contactTypesQuery?.data || {}

    const { isPending: deletePending } = deleteContact
    const { isPending: updatePending } = updateContact
    const isPending = deletePending || updatePending

    const handleChange = ({ target }: any) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }

    const handleDelete = async (id: number | string) => {
        deleteContact.mutate({ contact })
    }

    useEffect(() => {
        if (showDelete) {
            setShowDelete(false)
        }
    }, [isEdited])

    const handleUpdate = async () => {
        await updateContact.mutateAsync(
            { contact: { ...contact, ...editableFields } },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }

    function SaveButtons() {
        return (
            <div className='flex gap-3 justify-end items-center '>
                <button
                    onClick={handleUpdate}
                    className={`${
                        updateContact.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                >
                    {updateContact.isPending ? 'SAVING...' : 'SAVE'}
                </button>
                <button
                    onClick={() => {
                        setEditableFields({})
                    }}
                    className={`${
                        updateContact.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                >
                    Cancel
                </button>
            </div>
        )
    }

    return (
        <div
            className={cn(
                isPending && 'opacity-50',
                'text-[14px] grid grid-cols-[250px_repeat(4,auto)_50px] dark:bg-darkbg2 w-full border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkbg2'
            )}
        >
            <Select
                className='w-full h-full p-1 text-[14px] bg-transparent max-w-[250px]'
                id='contact_type'
                name='contact_type'
                onChange={handleChange}
                value={contact.contact_type}
                disabled={true}
            >
                <option value={'General'}>General</option>
                <option hidden={true} value={'Sidebar Info'}>
                    Company Sidebar Info
                </option>
                {contactTypes?.map((type: string) => {
                    return <option value={type}>{type}</option>
                })}
            </Select>
            <div
                className=' flex justify-center'
                onClick={() => {
                    if (contact.name) {
                        navigator.clipboard.writeText(contact.name)
                        sendToast({ message: `Copied: ${contact.name}` })
                    }
                }}
            >
                <Input
                    id='name'
                    type='text'
                    className='w-full h-full p-1 text-[14px] bg-transparent cursor-pointer'
                    value={editableFields.name ?? contact.name}
                    name='name'
                    placeholder='Name'
                    onChange={handleChange}
                    readOnly={true}
                />
            </div>
            <div
                onClick={() => {
                    if (contact.email) {
                        navigator.clipboard.writeText(contact.email)
                        sendToast({ message: `Copied: ${contact.email}` })
                    }
                }}
                className=' flex justify-center'
            >
                <Input
                    id='email'
                    type='text'
                    className='w-full h-full p-1 text-[14px] bg-transparent cursor-pointer'
                    value={editableFields.email ?? contact.email}
                    name='email'
                    placeholder='Email'
                    onChange={handleChange}
                    readOnly={true}
                />
            </div>
            <div
                onClick={() => {
                    if (contact.phone) {
                        navigator.clipboard.writeText(contact.phone)
                        sendToast({ message: `Copied: ${contact.phone}` })
                    }
                }}
                className=' flex justify-center '
            >
                <Input
                    id='phone'
                    type='text'
                    className='w-full h-full p-1 text-[14px] bg-transparent cursor-pointer '
                    value={editableFields.phone ?? contact.phone}
                    name='phone'
                    placeholder='Phone'
                    onChange={handleChange}
                    readOnly={true}
                />
            </div>
            <textarea
                onClick={() => {
                    if (contact.alternate) {
                        navigator.clipboard.writeText(contact.alternate)
                        sendToast({ message: `Copied: ${contact.alternate}` })
                    }
                }}
                className='w-full h-full rounded-none border-0 p-1 bg-transparent outline-none text-[14px] cursor-pointer'
                value={editableFields.alternate ?? contact.alternate}
                name='alternate'
                placeholder='Alternate'
                onChange={handleChange}
                disabled={isPending}
                readOnly={true}
            ></textarea>
            {showDetails && (
                <ContactDetails setShowDetails={setShowDetails} contact={contact} contactTypes={contactTypes} />
            )}
            {isEdited && <SaveButtons />}
            {!isEdited && !showDelete && !isLegacy && (
                <Button
                    size={'sm'}
                    variant={'outline'}
                    onClick={() => setShowDetails(true)}
                    className='cursor-pointer justify-self-end place-self-center mr-3'
                >
                    <p>Edit</p>
                </Button>
            )}
            {!isEdited && showDelete && (
                <div className='flex gap-[8px] p-[8px] justify-end'>
                    <button
                        onClick={() => handleDelete(contact.id)}
                        className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase'
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => setShowDelete(false)}
                        className='text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase'
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    )
}
