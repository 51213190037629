import { addBusinessDays } from 'date-fns'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCreateTask, useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import TaskAlert from 'tasksV2/tasks/components/TaskAlert'
import TaskScreenDueAt from 'tasksV2/tasks/components/TaskScreenDueAt'
import { cn } from '../../../../helpers'
import { useCreateNote } from '../../../api/useQueries'
import { ProcurementContext, ProcurementContextType } from '../../../helpers'
import { Button } from 'custom_components/component_Basics/Button'

export default function CreateNoteV2({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector<any, any>((state) => state.user)
    const [note, setNote] = useState('')
    const [createTask, setCreateTask] = useState(false)
    const textRef = useRef<any>(null)

    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const { departments } = departmentConfigQuery?.data || {}

    const defaultTask = {
        alert_offset: '0',
        send_slack_alert: 1,
        HTMLTimeString: `08:00`,
        HTMLDateString: HTMLDateInputFormatter(addBusinessDays(new Date(), 1)),
        department_id: departments?.find((department: any) => department.title === 'Procurement').id,
        type_id: departments
            ?.find((department: any) => department.title === 'Procurement')
            ?.types?.find((type: any) => type.value === 'Company').id,
        priority: 'Low',
        status: 'To Do',
        associations: [
            {
                resource_name: 'user',
                readable_name: `${user.firstName} ${user.lastName}`,
                resource_id: user.id,
            },
            {
                resource_name: 'company',
                readable_name: `${selectedCompany?.name}`,
                resource_id: selectedCompany.id,
            },
        ],
    }
    useEffect(() => {
        if (textRef) {
            textRef.current?.focus()
        }
    }, [selectedCompany])
    const [task, setTask] = useState<any>(defaultTask)
    const createTaskMutation = useCreateTask()

    function parseHTMLInputToDate(htmlDate: string) {
        return new Date(`${htmlDate}T${task.HTMLTimeString}:00`).toISOString()
    }

    const createNote = useCreateNote(setShowQuickModal)

    const handleCreateTask = () => {
        if (createTaskMutation.isPending) {
            return
        }
        const formattedTask = {
            ...task,
            title: note ? note.trim() : '',
            description: task?.description ? task.description.trim() : '',
            due_at: new Date(`${task.HTMLDateString}T${task.HTMLTimeString}:00.000Z`).toISOString(),
        }
        createTaskMutation.mutate(formattedTask, {
            onSuccess: () => {},
        })
    }

    const handleSubmit = () => {
        if (createTask) {
            handleCreateTask()
        }
        createNote.mutate(
            { user, note, companyId: selectedCompany.id, createTask },
            {
                onSuccess: () => {
                    return setShowQuickModal(undefined)
                },
                onSettled: () => {},
            }
        )
    }

    const setDate = (HTMLDateString: string) => {
        setTask({
            ...task,
            HTMLDateString: HTMLDateString,
        })
    }
    const setTime = (HTMLTimeString: string) => {
        setTask({
            ...task,
            HTMLTimeString: HTMLTimeString,
        })
    }
    return (
        <>
            <div className={cn(createNote.isPending && 'opacity-50')}>
                <div className='flex gap-2 p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>{selectedCompany.name}</h3>
                </div>
                <textarea
                    ref={textRef}
                    className='w-full p-[16px] dark:bg-darkaccent border-none focus:outline-none h-[150px]'
                    placeholder='Type your note here...'
                    value={note}
                    onChange={({ target }) => setNote(target.value)}
                    disabled={createNote.isPending}
                ></textarea>
                <div className='py-[16px] w-full bg-lightgrey dark:bg-darkness flex flex-col gap-[8px]'>
                    <div className='flex gap-[8px] px-4'>
                        <input
                            type='checkbox'
                            checked={createTask}
                            onChange={({ target }) => {
                                return setCreateTask(target.checked)
                            }}
                            disabled={createNote.isPending}
                        />
                        <p>Create a task from this note?</p>
                    </div>

                    {createTask && (
                        <div className='flex justify-between gap-[16px] bg-white p-2 px-4 w-full dark:bg-darkbg1'>
                            <div className='flex flex-col gap-[4px] relative w-[49%]'>
                                <TaskScreenDueAt
                                    taskId={task.id || -1}
                                    dueAt={task.HTMLDateString ? parseHTMLInputToDate(task.HTMLDateString) : ''}
                                    mutation={() => {}}
                                    setParentDate={setDate}
                                    setParentTime={setTime}
                                    className='bg-lightgrey dark:bg-darkbg2'
                                />
                            </div>
                            <TaskAlert
                                taskId={task.id || -1}
                                alertOffset={task.alert_offset}
                                sendEmailAlert={task.send_email_alert}
                                sendSlackAlert={task.send_slack_alert}
                                mutation={() => {}}
                                parentAlertDetails={task}
                                setParentAlertDetails={setTask}
                            />
                        </div>
                    )}
                    <Button
                        variant={'outline'}
                        size={'sm'}
                        disabled={createNote.isPending ? true : false}
                        onClick={handleSubmit}
                        className=' disabled:opacity-50 w-fit m-auto'
                    >
                        {createNote.isPending ? 'Saving Note...' : 'Save Note'}
                    </Button>
                </div>
            </div>
        </>
    )
}
