import { PlusCircledIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import RefreshButton from 'custom_components/RefreshButton'
import { ProductSearchPopover } from 'development/components/ProductSearchPopover'
import queryKeys from 'orderHubScreen/api/queryKeys'
import { useGetOrderList, useGetOrderTags } from 'orderHubScreen/api/useQueries'
import { useShopifyVendorsQuery } from 'procurement/api/useQueries'
import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { AiFillCaretDown } from 'react-icons/ai'
import { BiLeftArrow, BiPurchaseTagAlt, BiRightArrow } from 'react-icons/bi'
import { BsListTask } from 'react-icons/bs'
import { FaRegCommentDots, FaTimes } from 'react-icons/fa'
import { IoMdWarning } from 'react-icons/io'
import { TbCircle, TbCircleFilled } from 'react-icons/tb'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { V2TaskCalendarRange } from 'tasksV2/tasks/components/V2TaskCalendar'
import { ToggleSwitch } from '../custom_components/ToggleSwitch'
import { capitalize, cn, dayMap, DayMap, formatDateToLocale, formatMoney, monthMap, MonthMap } from '../helpers'
import TaskPreviewNavBlock from '../tasks/components/TaskPreviewNavBlock'
import SelectedOrdersActions from './components/SelectedOrderActions'
import {
    FinancialStatusMap,
    financialStatusMap,
    FulfillmentStatusMap,
    fulfillmentStatusMap,
    LineItemInit,
    OrderInit,
} from './orders.types'
import OrdersListTable from './OrdersListTable'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { ActiveFilters } from 'custom_components/component_Basics/ActiveFilters'

function renderUniqueVendors(lineItems: LineItemInit[]) {
    const vendorArray: string[] = []
    lineItems?.forEach((item) => !vendorArray.includes(item.vendor) && vendorArray.push(item.vendor))

    return vendorArray.map((vendor) => (
        <p key={vendor} className='w-[100%]'>
            {
                <strong>
                    {vendor && vendor !== 'FactoryPure' && vendor !== 'Shipping'
                        ? `${vendor} - `
                        : vendor === 'FactoryPure'
                          ? 'FP - '
                          : vendor === 'Shipping'
                            ? 'S - '
                            : ''}
                </strong>
            }
            {lineItems
                .map((item) => {
                    if (item.vendor === vendor && vendor !== 'FactoryPure' && vendor !== 'Shipping') {
                        return item.sku
                    } else if (item.vendor === vendor && (vendor === 'FactoryPure' || vendor === 'Shipping')) {
                        return item?.variant_title
                            ?.split(' ')
                            .map((word) => word[0])
                            .join('')
                    }
                })
                .filter((item) => item)
                .join(', ')}
        </p>
    ))
}

function renderIconMessage(iconMessage: string | React.ReactElement) {
    let unparsedMessage = iconMessage
    let parsedMessage = []

    if (typeof unparsedMessage === 'string') {
        while (unparsedMessage.match('\n')) {
            parsedMessage.push(unparsedMessage.slice(0, unparsedMessage.indexOf('\n')))
            parsedMessage.push(<br></br>)
            unparsedMessage = unparsedMessage.replace(unparsedMessage.slice(0, unparsedMessage.indexOf('\n') + 1), '')
        }
    }
    parsedMessage.push(unparsedMessage)
    return parsedMessage
}

type IconMessage =
    | [string, string | Element | HTMLElement | React.ReactElement | Element[] | HTMLElement[] | React.ReactElement[]]
    | false

export default function OrdersListScreenV2() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [productOptions, setProductOptions] = useState([])
    const fromString = searchParams.get('from') || ''
    const toString = searchParams.get('to') || ''

    const [popover, setPopover] = useState<undefined | string>()
    const productSearchRef = useRef<HTMLDivElement>(null)
    const [rowSelection, setRowSelection] = React.useState({})

    const searchRef = useRef<any>(null)

    const vendorsString = searchParams.get('vendors') || ''
    const payStatusesString = searchParams.get('payStatuses') || ''
    const pageString = searchParams.get('page') || '1'
    const tagsString = searchParams.get('tags') || ''
    const fulfillmentStatusesString = searchParams.get('fulfillmentStatuses') || ''
    const timelineCommentsString = searchParams.get('timelineComments') || ''
    const invoicePaidString = searchParams.get('invoicePaid') || ''
    const idString = searchParams.get('products') || ''
    const [ordersDateRange, setOrdersDateRange] = useState<DateRange | undefined>({
        from: fromString ? new Date(fromString) : undefined,
        to: toString ? new Date(toString) : undefined,
    })
    const [POMode, setPOMode] = useState<boolean>(
        JSON.parse(localStorage.getItem('purchase_orders_mode') || 'false') || false
    )
    const payStatusValues = decodeURIComponent(payStatusesString)
        .split(',')
        .filter((v) => v)
    const fulfillmentStatusValues = decodeURIComponent(fulfillmentStatusesString)
        .split(',')
        .filter((v) => v)
    const timelineCommentsValues = decodeURIComponent(timelineCommentsString)
        .split(',')
        .filter((v) => v)
    const invoicePaidValues = decodeURIComponent(invoicePaidString)
        .split(',')
        .filter((v) => v)
    const vendorValues = decodeURIComponent(vendorsString)
        .split(',')
        .filter((v) => v)
    const tagValues = decodeURIComponent(tagsString)
        .split(',')
        .filter((v) => v)

    const productIdValues = decodeURIComponent(idString)
        .split(',')
        .filter((v) => v)
    const filters = {
        payStatuses: payStatusValues,
        fulfillmentStatuses: fulfillmentStatusValues,
        timelineComments: timelineCommentsValues,
        invoicePaid: invoicePaidValues,
        productIds: productIdValues,
        page: pageString || '1',
        dateRange: ordersDateRange,
        vendors: vendorValues,
        tags: tagValues,
    }

    const POModeFilters = {
        payStatuses: ['authorized', 'paid', 'partially_paid', 'partially_refunded'],
        fulfillmentStatuses: ['partial', 'unfulfilled'],
        timelineComments: ['0'],
        invoicePaid: invoicePaidValues,
        page: undefined,
        dateRange: ordersDateRange,
        productIds: productIdValues,
        vendors: undefined,
        tags: tagValues,
    }

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: vendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}

    const orderListQuery = useGetOrderList(POMode ? POModeFilters : filters, Object.keys(rowSelection).length == 0)

    const { orders }: { orders: OrderInit[] } = orderListQuery?.data || {}
    const orderListNextPage = useGetOrderList(
        POMode ? { ...POModeFilters, page: undefined } : { ...filters, page: (parseInt(pageString) + 1).toString() },
        !POMode && orders?.length === 50
    )

    const filteredOrders = POMode ? orders?.filter((order: any) => !order.cancelled_at) : orders

    // if POMode, only show vendors that appear on returned orders, do not pass vendors to query and filter orders by vendor on client.
    const POModeFilteredOrders = vendorValues.length
        ? filteredOrders?.filter((order) => {
              let foundVendor = false

              for (let lineItem of order.line_items) {
                  if (!vendorValues.length) {
                      foundVendor = true
                      break
                  }
                  if (vendorValues.length && vendorValues.includes(lineItem.vendor)) {
                      foundVendor = true
                      break
                  } else {
                      foundVendor = false
                  }
              }

              if (foundVendor) {
                  return order
              } else {
                  return
              }
          })
        : filteredOrders

    const allActiveVendors: any[] = []
    if (POMode) {
        filteredOrders?.map((order) => {
            order.line_items.forEach((lineItem: any) => {
                if (lineItem.vendor && lineItem.vendor != '') {
                    allActiveVendors.push(lineItem.vendor)
                }
            })
        })
    }
    allActiveVendors?.filter((v) => v)

    const filteredActiveVendors = Array.from(new Set(allActiveVendors))?.sort((a: any, b: any) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1
    })

    const sortedVendorNames = [vendorNames]?.flat()?.sort((a: any, b: any) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1
    })
    const filteredVendorNames = POMode ? filteredActiveVendors : sortedVendorNames

    const orderTagsQuery = useGetOrderTags()
    const { tags }: { tags: string[] } = orderTagsQuery?.data || {}

    const sortedTags = [tags]?.flat()?.sort((a: any, b: any) => {
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1
    })
    const navigate = useNavigate()
    const [initRender, setInitRender] = useState<boolean>(true)
    const [startDate, setStartDate] = useState(
        searchParams.get('startDate')
            ? new Date(searchParams.get('startDate') || '')
            : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999)
    )

    const [selected, setSelected] = useState<string[]>([])
    const [iconMessage, setIconMessage] = useState<IconMessage>(false)
    const [showLineItems, setShowLineItems] = useState<string>('')

    const payStatusOptions = ['paid', 'authorized', 'partially_paid', 'partially_refunded', 'refunded', 'voided'].map(
        (option) => {
            return {
                label: capitalize(option.replaceAll('_', ' ')),
                value: option,
                icon: undefined,
            }
        }
    )
    const fulfillmentStatusOptions = ['fulfilled', 'unfulfilled', 'partial', 'restocked'].map((option) => {
        return {
            label: capitalize(option.replaceAll('_', ' ')),
            value: option,
            icon: undefined,
        }
    })
    const timeLineCommentsOptions = ['true', 'false'].map((option) => {
        return {
            label: capitalize(option),
            value: option === 'true' ? '1' : '0',
            icon: undefined,
        }
    })
    const invoicePaidOptions = ['true', 'false'].map((option) => {
        return {
            label: capitalize(option),
            value: option === 'true' ? 'paid' : 'not paid',
            icon: undefined,
        }
    })
    const vendorOptions = filteredVendorNames.map((option) => {
        return {
            label: POMode
                ? `${option} (${allActiveVendors?.filter((vendor: any) => vendor == option).length})`
                : option,
            value: option,
            icon: undefined,
        }
    })
    const tagOptions = sortedTags.map((option) => {
        return {
            label: option,
            value: option,
            icon: undefined,
        }
    })

    const activeFilters = [
        {
            searchParamField: 'payStatuses',
            label: 'pay statuses',
            options: payStatusOptions,
            values: payStatusValues,
        },
        {
            searchParamField: 'fulfillmentStatuses',
            label: 'fulfillment statuses',
            options: fulfillmentStatusOptions,
            values: fulfillmentStatusValues,
        },
        {
            searchParamField: 'timelineComments',
            label: 'timeline comments',
            options: timeLineCommentsOptions,
            values: timelineCommentsValues,
        },
        {
            searchParamField: 'invoicePaid',
            label: 'invoice paid',
            options: invoicePaidOptions,
            values: invoicePaidValues,
        },
        {
            searchParamField: 'vendors',
            label: 'vendors',
            options: vendorOptions,
            values: vendorValues,
        },
        {
            searchParamField: 'products',
            label: 'products',
            options: productOptions,
            values: productIdValues,
        },
        {
            searchParamField: 'tags',
            label: 'tags',
            options: tagOptions,
            values: tagValues,
        },
    ]

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!productSearchRef.current!.contains(e.target as HTMLElement)) {
                setPopover(undefined)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    const orderExpiring = (order: OrderInit) =>
        new Date(order.created_at.getFullYear(), order.created_at.getMonth(), order.created_at.getDate()) <=
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6) &&
        order.financial_status !== 'paid' &&
        order.financial_status !== 'refunded' &&
        order.financial_status !== 'voided'

    useEffect(() => {
        localStorage.setItem('purchase_orders_mode', POMode ? JSON.stringify(POMode) : 'false')
        localStorage.removeItem('mode')
    }, [POMode])

    function handleCheck(event: any) {
        const { target } = event
        const orderId = target.name || target.id
        if (orderId === 'all') return setSelected(target.checked ? filteredOrders.map((o: any) => String(o.id)) : [])
        setSelected(selected.includes(orderId) ? selected.filter((id) => id !== orderId) : [...selected, orderId])
    }

    function handleNavigate(e: MouseEvent, orderId: number | undefined) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (
            !(e.target as HTMLElement).classList.contains('js-items-dropdown') &&
            !(e.target as HTMLElement).classList.contains('js-tags-dropdown') &&
            !(e.target as HTMLElement).classList.contains('js-dont-navigate')
        ) {
            navigate(`/orders/${orderId}`)
        }
    }
    const handlePageIncrease = () => {
        searchParams.set('page', (parseInt(pageString) + 1).toString())
        setSearchParams(searchParams)
        setRowSelection({})
    }
    const handlePageDecrease = () => {
        if (pageString === '1') {
            return
        }
        searchParams.set('page', (parseInt(pageString) - 1).toString())
        setSearchParams(searchParams)
        setRowSelection({})
    }
    useEffect(() => {
        if (!initRender) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
        }
    }, [ordersDateRange?.from, ordersDateRange?.to])
    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    const tableData = POMode ? POModeFilteredOrders || [] : filteredOrders?.length > 0 ? filteredOrders : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<OrderInit>()

    const columns = [
        {
            enableGlobalFilter: false,
            id: 'select-col',
            header: ({ table }: { table: any }) => (
                <div className='flex items-center justify-center'>
                    <input
                        className='h-[15px] w-[15px]'
                        type='checkbox'
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
                    />
                </div>
            ),
            cell: ({ row }: { row: any }) => (
                <div
                    onClick={(e) => {
                        e.stopPropagation()
                        row.getToggleSelectedHandler()
                    }}
                    className='h-full flex items-center justify-center'
                >
                    <input
                        className='h-[15px] w-[15px] '
                        type='checkbox'
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                </div>
            ),
            size: 30,
        },
        // columnHelper.accessor('id', {
        //     enableGlobalFilter: false,
        //     header: 'id',
        //     cell: (info) => {
        //         return <div className='flex font-semibold'>{info.getValue()}</div>
        //     },
        // }),
        columnHelper.accessor('name', {
            header: 'Order #',
            cell: (info) => {
                return <div className=''>{info.getValue()}</div>
            },
            size: 80,
        }),
        {
            size: 100,
            id: 'icons',
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return (
                    <div className='js-orders-icon-list relative flex gap-[8px] items-start h-[100%] w-[100%]'>
                        <div className='flex gap-[8px] items-center'>
                            {order.note && (
                                <div
                                    className='js-orders-icon'
                                    onMouseOver={() => {
                                        setIconMessage([order.name, `${order.note}`])
                                    }}
                                >
                                    <svg
                                        className={`stroke-black ${
                                            orderExpiring(order) && 'stroke-white'
                                        } dark:stroke-accent pointer-events-none`}
                                        stroke='currentColor'
                                        fill='currentColor'
                                        strokeWidth='0'
                                        viewBox='0 0 24 24'
                                        height='1em'
                                        width='1em'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            className='pointer-events-none'
                                            fill='none'
                                            strokeWidth='2'
                                            d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                        ></path>
                                    </svg>
                                </div>
                            )}
                            {order.has_timeline_comment ? (
                                <FaRegCommentDots
                                    onMouseOver={() => {
                                        setIconMessage([order.name, 'This order has timeline comments.'])
                                    }}
                                    className='dark:fill-accent h-[14px] w-[14px]'
                                />
                            ) : undefined}
                            {order.purchase_order_ids?.length > 0 && (
                                <BiPurchaseTagAlt
                                    className='h-[16px] w-[16px] dark:fill-accent'
                                    onMouseOver={() => {
                                        setIconMessage([
                                            order.name,
                                            <span>
                                                This order has{' '}
                                                {order.purchase_order_ids.length > 1
                                                    ? `${order.purchase_order_ids.length} purchase orders`
                                                    : 'a purchase order'}
                                                :{' '}
                                                {order.purchase_order_ids.map((PO: any) => (
                                                    <span
                                                        key={`${order.id}-${PO.id}`}
                                                        className='js-dont-navigate text-blue dark:text-accent hover:underline cursor-pointer font-semibold mr-[4px]'
                                                        onContextMenu={() => {
                                                            window.open(`/orders/purchase-orders/${PO.id}`)
                                                        }}
                                                        onClick={() => {
                                                            ;(window.event as MouseEvent).ctrlKey
                                                                ? window.open(`/orders/purchase-orders/${PO.id}`)
                                                                : navigate(`/orders/purchase-orders/${PO.id}`)
                                                        }}
                                                    >
                                                        {PO.id}
                                                    </span>
                                                ))}
                                            </span>,
                                        ])
                                    }}
                                />
                            )}
                            {order.tasks?.length > 0 && (
                                <BsListTask
                                    className='h-[16px] w-[16px] dark:fill-accent'
                                    onMouseOver={() =>
                                        setIconMessage([
                                            order.name,
                                            <div className='p-[4px]'>
                                                <h3 className='text-[16px] font-bold'>Order {order.name} - Tasks</h3>
                                                {order.tasks?.map((t: any) => (
                                                    <TaskPreviewNavBlock task={t} orderId={order.id} />
                                                ))}
                                            </div>,
                                        ])
                                    }
                                />
                            )}
                            {order.risk ? (
                                <>
                                    {!!order.risk.assessments.find(
                                        (assessment: any) => assessment.riskLevel === 'HIGH'
                                    ) ? (
                                        <IoMdWarning
                                            className='h-[16px] w-[16px] fill-[#da0000]'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order has a high risk of fraud.'])
                                            }}
                                        />
                                    ) : !!order.risk.assessments.find(
                                          (assessment: any) => assessment.riskLevel === 'MEDIUM'
                                      ) ? (
                                        <IoMdWarning
                                            className='h-[16px] w-[16px] fill-[#d19e1c]'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order might be fraudulent.'])
                                            }}
                                        />
                                    ) : undefined}
                                </>
                            ) : (
                                <>
                                    {order.risk_level === 'HIGH' ? (
                                        <IoMdWarning
                                            className='h-[16px] w-[16px] fill-[#da0000]'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order has a high risk of fraud.'])
                                            }}
                                        />
                                    ) : order.risk_level === 'MEDIUM' ? (
                                        <IoMdWarning
                                            className='h-[16px] w-[16px] fill-[#d19e1c]'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order might be fraudulent.'])
                                            }}
                                        />
                                    ) : undefined}
                                </>
                            )}
                        </div>
                        {iconMessage[0 as keyof IconMessage] === order.name && (
                            <div className='hidden js-orders-icon-message absolute hover:flex p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto js-dont-navigate'>
                                <div className='left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate'>
                                    {renderIconMessage(iconMessage[1 as keyof IconMessage])}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            header: 'Date',
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return (
                    <div className='flex flex-wrap gap-x-[4px]'>
                        <p>
                            {[
                                order.created_at.getMonth() + 1,
                                order.created_at.getDate(),
                                String(order.created_at.getFullYear()).slice(-2),
                            ].join('/')}
                        </p>
                        <p>{formatDateToLocale(order.created_at).split(' ').slice(-2).join(' ')}</p>
                    </div>
                )
            },
            size: 120,
        },
        columnHelper.accessor('customer', {
            header: 'Customer',
            cell: (info) => {
                const order = info.cell.row.original
                return (
                    <div className=''>
                        {order?.customer &&
                            `${order.customer.first_name ? order.customer.first_name : ''} ${
                                order.customer.last_name ? order.customer.last_name : ''
                            }`}
                    </div>
                )
            },
        }),
        columnHelper.accessor('current_total_price', {
            header: 'Total',
            cell: (info) => {
                return <div className=''>{formatMoney(Number(info.getValue()))}</div>
            },
            size: 90,
        }),
        {
            header: 'Pay STS',
            size: 80,
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return (
                    <div className='text-[12px]'>
                        <div
                            className={`${
                                (order.financial_status &&
                                    financialStatusMap[order.financial_status as keyof FinancialStatusMap].bg) ||
                                'bg-fire'
                            } w-[auto] flex gap-[4px] items-center text-darkgrey px-[4px] rounded-full`}
                        >
                            {(order.financial_status &&
                                financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol) || (
                                <TbCircle />
                            )}
                            <span className='inline-block font-bold text-center'>
                                {(order.financial_status &&
                                    financialStatusMap[order.financial_status as keyof FinancialStatusMap].abbr) ||
                                    'U'}
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            header: 'TTC',
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return (
                    <div className='flex justify-center'>
                        {Math.floor(
                            Math.max(
                                0,
                                new Date(order.created_at).getTime() -
                                    new Date(
                                        new Date().getFullYear(),
                                        new Date().getMonth(),
                                        new Date().getDate() - 7,
                                        new Date().getHours(),
                                        new Date().getMinutes()
                                    ).getTime()
                            ) / 86400000
                        )}
                    </div>
                )
            },
            size: 60,
        },
        {
            header: 'Ful. STS',
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return (
                    <div className='flex items-start justify-center text-[12px]'>
                        <div
                            className={`${
                                (order.fulfillment_status &&
                                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].bg) ||
                                'bg-fire'
                            } w-[40px] flex gap-[4px] items-center text-darkgrey px-[4px] rounded-full`}
                        >
                            {(order.fulfillment_status &&
                                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                    .symbol) || <TbCircle />}
                            <span className='inline-block w-[15px] font-bold text-center'>
                                {(order.fulfillment_status &&
                                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                        .abbr) ||
                                    order.fulfillment_status?.slice(0, 1).toUpperCase() ||
                                    'U'}
                            </span>
                        </div>
                    </div>
                )
            },
            size: 80,
        },
        {
            header: 'Vendors',
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return <div className='flex flex-wrap'>{renderUniqueVendors(order.line_items)}</div>
            },
            minSize: 400,
        },

        {
            header: 'Items',
            cell: ({ row }: { row: any }) => {
                const order = row.original
                return (
                    <div className='flex relative h-[100%] w-[100%] items-start justify-center'>
                        {order.line_items
                            ?.map((item: any) => {
                                return Number(item.quantity)
                            })
                            .reduce((acc: number, cur: number) => acc + cur, 0)}
                        <div
                            className='js-items-dropdown absolute top-0 flex w-[100%] h-[100%] hover:opacity-[1] opacity-0 bg-[rgba(0,0,0,0.1)] items-start justify-end'
                            onClick={(e) => {
                                e.stopPropagation()
                                showLineItems !== order.name ? setShowLineItems(order.name) : setShowLineItems('')
                            }}
                        >
                            <div className='pt-[10px] pointer-events-none'>
                                <AiFillCaretDown
                                    className={`pointer-events-none duration-300 ${
                                        showLineItems === order.name ? '!rotate-0' : 'rotate-180'
                                    }`}
                                />
                            </div>
                        </div>
                        {showLineItems === order.name && (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    showLineItems !== order.name ? setShowLineItems(order.name) : setShowLineItems('')
                                }}
                                className='[&>*]:pointer-events-none js-items-dropdown grid absolute top-[calc(100%-5px)] min-w-[300px] w-[25vw] right-[0] p-[8px] text-black bg-white shadow-small dark:text-offwhite dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey z-index-2 cursor-auto'
                            >
                                <span className='js-items-dropdown inline font-bold text-[16px] w-[auto] text-end'>
                                    Items
                                </span>
                                {order?.line_items?.filter(
                                    (item: any) => !item.fulfillment_status || item.fulfillment_status === 'unfulfilled'
                                ).length > 0 && (
                                    <>
                                        <div className='js-items-dropdown w-[40px] flex gap-[4px] items-center bg-fire text-darkgrey px-[4px] rounded-full mb-[16px]'>
                                            <TbCircle className='js-items-dropdown' />
                                            <span className='js-items-dropdown inline-block w-[15px] font-bold text-center'>
                                                U
                                            </span>
                                        </div>
                                        <div className='js-items-dropdown grid gap-[16px]'>
                                            {order.line_items.map((item: any) => {
                                                if (
                                                    item.fulfillment_status === 'scheduled' ||
                                                    item.fulfillment_status === 'unfulfilled' ||
                                                    (!item.fulfillment_status && item.requires_shipping)
                                                ) {
                                                    return (
                                                        <div
                                                            key={`${order.id}-${item.id}-unfulfilled`}
                                                            className='js-items-dropdown flex gap-[16px]'
                                                        >
                                                            <div className='js-items-dropdown relative flex items-center'>
                                                                <img
                                                                    className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                                                    src={
                                                                        window.IMAGE_MAP[
                                                                            `gid://shopify/Product/${item.product_id}`
                                                                        ]
                                                                    }
                                                                    alt={item.name}
                                                                    height='100px'
                                                                    width='100px'
                                                                />
                                                                <span className='js-items-dropdown absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                    {item.quantity}
                                                                </span>
                                                            </div>
                                                            <div className='js-items-dropdown my-[12px]'>
                                                                <a
                                                                    href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                                    target='_blank'
                                                                    className='js-dont-navigate js-items-dropdown font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                >
                                                                    {item.name}
                                                                </a>
                                                                <p className='js-items-dropdown pt-[4px]'>
                                                                    <strong className='js-items-dropdown'>SKU:</strong>{' '}
                                                                    {item.sku}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </>
                                )}
                                {order.line_items?.filter((item: any) => item.fulfillment_status === 'fulfilled')
                                    .length > 0 && (
                                    <>
                                        <div className='js-items-dropdown w-[40px] flex gap-[4px] items-center bg-[rgb(190,255,200)] text-darkgrey px-[4px] rounded-full mb-[16px]'>
                                            <TbCircleFilled className='js-items-dropdown' />
                                            <span className='js-items-dropdown inline-block w-[15px] font-bold text-center'>
                                                F
                                            </span>
                                        </div>
                                        <div className='js-items-dropdown grid gap-[16px]'>
                                            {order.line_items.map((item: any) => {
                                                if (
                                                    item.fulfillment_status === 'fulfilled' ||
                                                    (!item.fulfillment_status && item.requires_shipping)
                                                ) {
                                                    return (
                                                        <div
                                                            key={`${order.id}-${item.id}-fulfilled`}
                                                            className='js-items-dropdown flex gap-[16px]'
                                                        >
                                                            <div className='js-items-dropdown relative flex items-center'>
                                                                <img
                                                                    className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                                                    src={
                                                                        window.IMAGE_MAP[
                                                                            `gid://shopify/Product/${item.product_id}`
                                                                        ]
                                                                    }
                                                                    alt={item.name}
                                                                    height='100px'
                                                                    width='100px'
                                                                />
                                                                <span className='js-items-dropdown absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                    {item.quantity}
                                                                </span>
                                                            </div>
                                                            <div className='js-items-dropdown my-[12px]'>
                                                                <a
                                                                    href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                                    target='_blank'
                                                                    className='js-dont-navigate js-items-dropdown font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                >
                                                                    {item.name}
                                                                </a>
                                                                <p className='js-items-dropdown pt-[4px]'>
                                                                    <strong className='js-items-dropdown'>SKU:</strong>{' '}
                                                                    {item.sku}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )
            },
            size: 40,
        },
        columnHelper.accessor('tags', {
            header: 'Tags',
            cell: (info) => {
                return <div className='flex flex-wrap gap-[4px] w-full'>{info.getValue()}</div>
            },
            minSize: 550,
        }),
    ]

    return (
        <div className='relative text-black dark:text-offwhite h-[calc(100vh-215px)] min-h-0'>
            <div className='flex gap-2 w-full'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Orders</h1>
                <div className='flex items-center'>
                    <FetchingSpinner isFetching={orderListQuery.isFetching} />
                </div>
                <div className='flex justify-between items-end w-full dark:text-offwhite'>
                    <div className='flex gap-[16px] mb-[2px]'>
                        <h2 className='font-semibold text-[20px]'>
                            {ordersDateRange?.from
                                ?.toDateString()
                                .split(' ')
                                .map((dateItem) =>
                                    dayMap[dateItem as keyof DayMap]
                                        ? `${dayMap[dateItem as keyof DayMap]}, `
                                        : monthMap[dateItem as keyof MonthMap]
                                          ? `${monthMap[dateItem as keyof MonthMap]}`
                                          : Number(dateItem) < 32
                                            ? `${Number(dateItem)},`
                                            : dateItem
                                )
                                .join(' ')}
                            {ordersDateRange?.to && ' - '}
                            {ordersDateRange?.to
                                ?.toDateString()
                                .split(' ')
                                .map((dateItem) =>
                                    dayMap[dateItem as keyof DayMap]
                                        ? `${dayMap[dateItem as keyof DayMap]}, `
                                        : monthMap[dateItem as keyof MonthMap]
                                          ? `${monthMap[dateItem as keyof MonthMap]}`
                                          : Number(dateItem) < 32
                                            ? `${Number(dateItem)},`
                                            : dateItem
                                )
                                .join(' ')}
                        </h2>
                    </div>
                    <div className='flex gap-3 items-center'>
                        <div
                            onClick={() => {
                                setRowSelection({})
                            }}
                        >
                            <RefreshButton
                                queryKeys={[queryKeys.orders.list._def.toString()]}
                                isFetching={orderListQuery.isFetching || orderListQuery.isLoading}
                            />
                        </div>
                        <div className='flex items-center gap-2'>
                            <span>PO Mode </span>
                            <ToggleSwitch
                                defaultChecked={!!POMode}
                                onClick={() => {
                                    searchParams.set('page', '1')
                                    searchParams.delete('payStatuses')
                                    searchParams.delete('fulfillmentStatuses')
                                    searchParams.delete('timelineComments')
                                    searchParams.delete('invoicePaid')
                                    searchParams.delete('tags')
                                    searchParams.delete('products')
                                    searchParams.delete('vendors')
                                    searchParams.delete('from')
                                    searchParams.delete('to')
                                    setOrdersDateRange(undefined)
                                    setRowSelection({})
                                    setSearchParams(searchParams)
                                    setPOMode(!POMode)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-1'>
                <div className='flex gap-3'>
                    {!POMode && (
                        <>
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={payStatusOptions}
                                title={'Pay Status'}
                                field={'payStatuses'}
                                filterValues={payStatusValues}
                                searchToggle={false}
                                editSearchParams={true}
                                preview={false}
                            />
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={fulfillmentStatusOptions}
                                title={'Fulfillment Status'}
                                field={'fulfillmentStatuses'}
                                filterValues={fulfillmentStatusValues}
                                searchToggle={true}
                                editSearchParams={true}
                                preview={false}
                            />
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={timeLineCommentsOptions}
                                title={'Has Timeline Comment'}
                                field={'timelineComments'}
                                filterValues={timelineCommentsValues}
                                searchToggle={false}
                                editSearchParams={true}
                                preview={false}
                            />
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={invoicePaidOptions}
                                title={'Invoice Paid'}
                                field={'invoicePaid'}
                                filterValues={invoicePaidValues}
                                searchToggle={false}
                                editSearchParams={true}
                                preview={false}
                            />
                        </>
                    )}
                    <FilterBarSelectorButton
                        variant='outline'
                        align='end'
                        setFilterValues={() => {}}
                        options={tagOptions}
                        title={'Tags'}
                        field={'tags'}
                        filterValues={tagValues}
                        searchToggle={true}
                        editSearchParams={true}
                        preview={false}
                    />
                    <FilterBarSelectorButton
                        variant='outline'
                        align='end'
                        setFilterValues={() => {}}
                        options={vendorOptions}
                        title={'Vendors'}
                        field={'vendors'}
                        filterValues={vendorValues}
                        searchToggle={true}
                        editSearchParams={true}
                        preview={false}
                    />
                    <div ref={productSearchRef} className='flex relative'>
                        <Button
                            onClick={() => {
                                if (!popover) {
                                    setPopover('status')
                                    setTimeout(() => {
                                        searchRef.current?.focus()
                                    }, 100)
                                    return
                                }
                                setPopover(undefined)
                            }}
                            variant={'outline'}
                            size={'sm'}
                            className='border-dashed h-[29.5px]'
                        >
                            <PlusCircledIcon className='mr-2 h-4 w-4' />
                            {'Products'}
                        </Button>
                        {popover === 'status' && (
                            <ProductSearchPopover
                                values={productIdValues}
                                onClick={(option: string) => {}}
                                isPending={false}
                                className='left-0 translate-x-0 w-[500px]'
                                setProductOptions={setProductOptions}
                                ref={searchRef}
                            />
                        )}
                    </div>
                    <div className='flex gap-2 h-full min-w-[200px] items-center'>
                        <V2TaskCalendarRange dateRange={ordersDateRange} setDate={setOrdersDateRange} />
                        <div
                            onClick={() => {
                                searchParams.delete('calendarPreviewUser')
                                searchParams.delete('from')
                                searchParams.delete('to')
                                searchParams.delete('calendarPreviewType')
                                searchParams.set('page', '1')
                                setSearchParams(searchParams)
                                return setOrdersDateRange({ from: undefined, to: undefined })
                            }}
                            className={cn(
                                'text-text2 dark:text-darktext2 dark:border-darkbg2 border border-bg1 h-fit w-fit rounded-md px-1  text-sm',
                                (ordersDateRange?.from || ordersDateRange?.to) &&
                                    'border-lightgrey text-text1 cursor-pointer dark:text-darktext1 dark:border-lightgrey'
                            )}
                        >
                            Reset
                        </div>
                    </div>
                </div>
                <ActiveFilters activeFilters={activeFilters} />
            </div>

            <div className=' h-full min-h-0'>
                <div className='w-full relative h-full min-h-0 text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <OrdersListTable
                        columns={columns}
                        tableData={tableDataMemo}
                        showHeaders={true}
                        size={'auto'}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                    />
                </div>
                <div
                    className={cn(
                        ' text-[14px] flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-2 py-[2px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[8px] justify-between items-center mt-[16px] rounded z-50',
                        POMode && Object.keys(rowSelection).length < 1 && 'hidden'
                    )}
                >
                    <SelectedOrdersActions
                        selected={Object.entries(rowSelection)
                            .map(([index, value]: any) => {
                                if (value === true) {
                                    return tableDataMemo[index]?.id
                                }
                            })
                            .filter((v) => v)}
                        setSelectedOrders={setSelected}
                    />
                    {!POMode && (
                        <>
                            <div
                                className={cn(
                                    'min-w-[24px] min-h-[24px]',
                                    orderListQuery.isFetching && 'opacity-50 pointer-events-none'
                                )}
                            >
                                {parseInt(pageString) > 1 && (
                                    <div
                                        className='grid border-[1px] border-darkgrey dark:border-accent w-[24px] h-[24px] rounded items-center justify-center cursor-pointer'
                                        onClick={handlePageDecrease}
                                    >
                                        <BiLeftArrow className='fill-darkgrey dark:fill-accent' />
                                    </div>
                                )}
                            </div>
                            {filteredOrders?.length > 0 && (
                                <div className='flex min-w-[160px] justify-center grow font-bold dark:text-offwhite'>
                                    {filteredOrders[0].name} - {filteredOrders[filteredOrders.length - 1].name}
                                </div>
                            )}
                            <div
                                className={cn(
                                    'min-w-[24px] min-h-[24px]',
                                    orderListQuery.isFetching && 'opacity-50 pointer-events-none'
                                )}
                            >
                                {orders?.length == 50 && (
                                    <div
                                        className='grid border-[1px] border-darkgrey dark:border-accent w-[24px] h-[24px] rounded items-center justify-center cursor-pointer'
                                        onClick={handlePageIncrease}
                                    >
                                        <BiRightArrow className='fill-darkgrey dark:fill-accent' />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
