import { ActiveFilters } from 'custom_components/component_Basics/ActiveFilters'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { cn, DayMap, dayMap, monthMap, MonthMap } from 'helpers'
import { useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'
import { useGetRoles } from 'roles/api/useQueries'
import { V2TaskCalendarRange } from 'tasksV2/tasks/components/V2TaskCalendar'
import { useGetAllUsers } from 'usersV2/api/useQueries'
import UserLoginsList from './lists/UserLoginsList'
import { UserLoginsScreenSearchContainer } from './UserLoginsScreenSearchContainer'

export default function UserLoginsScreen() {
    const [searchParams, setSearchParams] = useSearchParams()

    const usersQuery = useGetAllUsers({})
    const users: any[] = usersQuery.data?.users || []

    const searchRef: any = useRef()

    const search = searchParams.get('search') || ''
    const typesString = searchParams.get('types') || ''
    const userIdString = searchParams.get('userIds') || ''
    const rolesString = searchParams.get('roles') || ''
    const fromString = searchParams.get('from') || ''
    const toString = searchParams.get('to') || ''

    const [dateRange, setDateRange] = useState<DateRange | undefined>({
        from: fromString ? new Date(fromString) : undefined,
        to: toString ? new Date(toString) : undefined,
    })

    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)
    const roleValues = decodeURIComponent(rolesString)
        .split(',')
        .filter((v) => v)
    const userIdValues = decodeURIComponent(userIdString)
        .split(',')
        .filter((v) => v)

    const rolesQuery = useGetRoles({})
    const roles: any[] = rolesQuery.data?.roles || []

    const typeOptions = ['SuperAdmin', 'Admin', 'Client', 'Archived'].map((type) => {
        return {
            label: type,
            value: type,
            icon: undefined,
        }
    })
    const roleOptions = roles?.map((role) => {
        return {
            label: role.title,
            value: role.id.toString(),
            icon: undefined,
        }
    })
    const userOptions = users?.map((user) => {
        return {
            label: user.first_name + ' ' + user.last_name,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    const filters = {
        types: typeValues,
        roles: roleValues,
        userIds: userIdValues,
        from: dateRange?.from,
        to: dateRange?.to,
        search,
    }

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'roles',
            label: 'roles',
            options: roleOptions,
            values: roleValues,
        },
        {
            searchParamField: 'types',
            label: 'types',
            options: typeOptions,
            values: typeValues,
        },
        {
            searchParamField: 'userIds',
            label: 'users',
            options: userOptions,
            values: userIdValues,
        },
        {
            searchParamField: 'search',
            label: 'search',
            options: [{ label: search, value: search, icon: undefined }],
            values: search ? [search] : [],
        },
    ]
    // ACTIVE FILTERS

    return (
        <div className='h-[calc(100vh-200px)]'>
            <div className='flex justify-between w-full mb-2'>
                <div className='flex gap-2 items-center w-full '>
                    <h1 className='font-semibold text-2xl'>Users</h1>
                    <UserLoginsScreenSearchContainer ref={searchRef} />
                    <div className='flex gap-[16px] mb-[2px] min-w-[500px]'>
                        <h2 className='font-semibold text-[20px]'>
                            {dateRange?.from
                                ?.toDateString()
                                .split(' ')
                                .map((dateItem) =>
                                    dayMap[dateItem as keyof DayMap]
                                        ? `${dayMap[dateItem as keyof DayMap]}, `
                                        : monthMap[dateItem as keyof MonthMap]
                                          ? `${monthMap[dateItem as keyof MonthMap]}`
                                          : Number(dateItem) < 32
                                            ? `${Number(dateItem)},`
                                            : dateItem
                                )
                                .join(' ')}
                            {dateRange?.to && ' - '}
                            {dateRange?.to
                                ?.toDateString()
                                .split(' ')
                                .map((dateItem) =>
                                    dayMap[dateItem as keyof DayMap]
                                        ? `${dayMap[dateItem as keyof DayMap]}, `
                                        : monthMap[dateItem as keyof MonthMap]
                                          ? `${monthMap[dateItem as keyof MonthMap]}`
                                          : Number(dateItem) < 32
                                            ? `${Number(dateItem)},`
                                            : dateItem
                                )
                                .join(' ')}
                        </h2>
                    </div>
                </div>
            </div>
            <div className='flex gap-2 mb-2'>
                {/* <FilterBarSelectorButton
                    variant='outline'
                    align='start'
                    setFilterValues={() => {}}
                    options={roleOptions}
                    title={'Roles'}
                    field={'roles'}
                    filterValues={roleValues}
                    searchToggle={false}
                    editSearchParams={true}
                    preview={false}
                />
                <FilterBarSelectorButton
                    variant='outline'
                    align='start'
                    setFilterValues={() => {}}
                    options={typeOptions}
                    title={'Types'}
                    field={'types'}
                    filterValues={typeValues}
                    searchToggle={false}
                    editSearchParams={true}
                    preview={false}
                /> */}
                <FilterBarSelectorButton
                    variant='outline'
                    align='start'
                    setFilterValues={() => {}}
                    options={userOptions}
                    title={'Users'}
                    field={'userIds'}
                    filterValues={userIdValues}
                    searchToggle={false}
                    editSearchParams={true}
                    preview={false}
                />
                <div className='flex gap-2 h-full min-w-[200px] items-center'>
                    <V2TaskCalendarRange dateRange={dateRange} setDate={setDateRange} />
                    <div
                        onClick={() => {
                            searchParams.delete('from')
                            searchParams.delete('to')
                            setSearchParams(searchParams)
                            return setDateRange({ from: undefined, to: undefined })
                        }}
                        className={cn(
                            'text-text2 dark:text-darktext2 dark:border-darkbg2 border border-bg1 h-fit w-fit rounded-md px-1  text-sm',
                            (dateRange?.from || dateRange?.to) &&
                                'border-lightgrey text-text1 cursor-pointer dark:text-darktext1 dark:border-lightgrey'
                        )}
                    >
                        Reset
                    </div>
                </div>
            </div>
            {/* ACTIVE FILTERS */}
            <ActiveFilters activeFilters={activeFilters} />
            {/* ACTIVE FILTERS */}
            <UserLoginsList filters={filters} />
        </div>
    )
}
