import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import LabelWithInfo from 'productSetupV2/components/LabelWithInfo'
import { useEffect, useRef, useState } from 'react'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { useDeleteDepartment, useGetDepartmentConfig, useUpdateDepartment } from 'tasksV2/api/useQueries'
import { useAllUsersQuery } from 'users/api/useQueries'
import DepartmentOptionEditor from '../component/DepartmentOptionEditor'
import { cn } from 'helpers'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { useSelector } from 'react-redux'

export default function EditTaskDepartmentModal({
    departmentId,
    closeModal,
}: {
    departmentId: string
    closeModal: () => void
}) {
    const settings = useSelector((state: any) => state.settings)
    const [showDelete, setShowDelete] = useState(false)
    const usersQuery = useAllUsersQuery()
    const departmentConfigQuery = useGetDepartmentConfig(departmentId)
    const department = departmentConfigQuery.data?.config
        ? Object.fromEntries(
              Object.entries(departmentConfigQuery.data.config).map(([key, value]: any) => {
                  try {
                      return [key, JSON.parse(value)]
                  } catch {
                      return [key, value]
                  }
              })
          )
        : {}

    const updateDepartmentMutation = useUpdateDepartment()
    const deleteDepartmentMutation = useDeleteDepartment()
    const users = usersQuery.data?.users || []
    const modalRef = useRef<HTMLDivElement>(null)
    const [editedFields, setEditedFields] = useState<any>({})

    const edited =
        Object.entries(editedFields)
            .map(([key, value]: any) => {
                if (key === 'users') {
                    return value.find(
                        (userId: any) => !department.users.includes(userId) || department.users.length !== value.length
                    )
                }
                return value !== department[key]
            })
            .filter((v) => v).length > 0

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev: any) => ({
            ...prev,
            [target.name]: target.type === 'checkbox' ? ((target as HTMLInputElement).checked ? 1 : 0) : target.value,
        }))
    }

    const handleToggleAdmin = (userId: number) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.admins) {
                newState.admins = department.admins
            }
            if (newState.admins.includes(userId)) {
                newState.admins = newState.admins.filter((id: number) => id !== userId)
            } else {
                newState.admins.push(userId)
            }
            return newState
        })
    }

    const handleToggleUser = (userId: number) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.users) {
                newState.users = department.users
            }
            if (newState.users.includes(userId)) {
                newState.users = newState.users.filter((id: number) => id !== userId)
            } else {
                newState.users.push(userId)
            }
            return newState
        })
    }

    const handleUpdate = () => {
        updateDepartmentMutation.mutate(
            {
                ...editedFields,
                id: department.id,
                title: editedFields?.title?.trim(),
                associations: editedFields.users
                    ? editedFields.users.filter(
                          (userId: number) => !department.users.find((id: number) => userId === id)
                      )
                    : undefined,
                disassociations: editedFields.users
                    ? department.users.filter(
                          (userId: number) => !editedFields.users.find((id: number) => userId === id)
                      )
                    : undefined,
                admin_associations: editedFields.admins
                    ? editedFields.admins.filter(
                          (userId: number) => !department.admins.find((id: number) => userId === id)
                      )
                    : undefined,
                admin_disassociations: editedFields.admins
                    ? department.admins.filter(
                          (userId: number) => !editedFields.admins.find((id: number) => userId === id)
                      )
                    : undefined,
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                },
            }
        )
    }

    const handleDelete = () => {
        deleteDepartmentMutation.mutate(department.id, {
            onSuccess: () => {
                closeModal()
            },
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md max-w-[1200px] w-[95%]'>
                <button onClick={() => closeModal()} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <div className='flex items-center justify-between mb-3'>
                    <div className='flex gap-4 items-center'>
                        <h2 className='text-lg font-bold'>Edit Task Department</h2>
                        <FetchingSpinner isFetching={updateDepartmentMutation.isPending} />
                    </div>
                    <div
                        className={cn(
                            'flex  gap-3 items-center justify-center',
                            (!edited || updateDepartmentMutation.isPending) && 'opacity-60 pointer-events-none '
                        )}
                    >
                        <Button
                            className={cn(!edited && 'border-dashed', 'min-w-[130px]')}
                            variant={'outline'}
                            onClick={handleUpdate}
                        >
                            {updateDepartmentMutation.isPending ? 'Saving...' : 'Save Changes'}
                        </Button>
                        <Button
                            className={cn(!edited && 'border-dashed')}
                            variant={'outline'}
                            onClick={() => setEditedFields({})}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className='grid grid-cols-4 gap-4'>
                    <div>
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-2'>
                                <Input
                                    type='text'
                                    label='Title'
                                    id='title'
                                    name='title'
                                    onChange={handleChange}
                                    value={editedFields.title ?? department.title}
                                />
                            </div>
                            <div className='flex items-center justify-between gap-3 mt-1 border rounded p-1'>
                                <LabelWithInfo
                                    htmlFor='allow_descriptions'
                                    info='Allow additional task description section on individual task screen'
                                    label='Allow Task Descriptions'
                                />
                                <input
                                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                    type='checkbox'
                                    id='allow_descriptions'
                                    name='allow_descriptions'
                                    checked={editedFields.allow_descriptions ?? department.allow_descriptions}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex items-center justify-between gap-3 mb-2 border rounded p-1'>
                                <LabelWithInfo
                                    htmlFor='prioritize_association_view'
                                    info='Try to navigate to association instead of task edit view when possible.'
                                    label='Prioritize Association View'
                                />
                                <input
                                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                    type='checkbox'
                                    id='prioritize_association_view'
                                    name='prioritize_association_view'
                                    checked={
                                        editedFields.prioritize_association_view ??
                                        department.prioritize_association_view
                                    }
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <LabelWithInfo
                                    htmlFor=''
                                    info='People who are alerted in response to task escalations'
                                    label='Escalation Alert Recipients'
                                />
                                <div className='mt-2 max-h-[150px] overflow-auto'>
                                    {users
                                        .filter((u: any) => u.type !== 'Archived')
                                        .map((user: any) => (
                                            <div
                                                onClick={() => handleToggleAdmin(user.id)}
                                                className='flex items-center gap-2 p-1 border-b border-lightgrey dark:border-darkgrey cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                                            >
                                                <div
                                                    className={`border border-lightgrey dark:border-darkgrey rounded w-4 h-4 ${
                                                        (editedFields.admins ?? department.admins)?.includes(user.id)
                                                            ? 'bg-accent1 dark:bg-darkaccent2'
                                                            : ''
                                                    }`}
                                                >
                                                    &nbsp;
                                                </div>
                                                {user.first_name} {user.last_name}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div>
                                <div className='flex gap-2 justify-between'>
                                    <LabelWithInfo
                                        htmlFor=''
                                        info='People who can view and edit tasks in this department'
                                        label='View and Edit Permissions'
                                    />
                                    <div className='flex items-center gap-3'>
                                        <LabelWithInfo
                                            htmlFor='private'
                                            info='Only show tasks to people in the department'
                                            label='Private'
                                        />
                                        <input
                                            className=''
                                            type='checkbox'
                                            id='private'
                                            name='private'
                                            checked={editedFields.private ?? department.private}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cn(
                                        'mt-2 max-h-[150px] overflow-auto',
                                        (editedFields.private ?? department.private) === 0 &&
                                            'opacity-60 pointer-events-none'
                                    )}
                                >
                                    {users
                                        .filter((u: any) => u.type !== 'Archived')
                                        .map((user: any) => (
                                            <div
                                                onClick={() => handleToggleUser(user.id)}
                                                className='flex items-center gap-2 p-1 border-b border-lightgrey dark:border-darkgrey cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                                            >
                                                <div
                                                    className={`border border-lightgrey dark:border-darkgrey rounded w-4 h-4 ${
                                                        (editedFields.users ?? department.users)?.includes(user.id)
                                                            ? 'bg-accent1 dark:bg-darkaccent2'
                                                            : ''
                                                    }`}
                                                >
                                                    &nbsp;
                                                </div>
                                                {user.first_name} {user.last_name}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <DepartmentOptionEditor
                        departmentId={department.id}
                        optionName='types'
                        options={department.types || []}
                    />
                    <DepartmentOptionEditor
                        departmentId={department.id}
                        optionName='statuses'
                        options={department.statuses || []}
                    />
                    <DepartmentOptionEditor
                        departmentId={department.id}
                        optionName='estimates'
                        options={department.estimates || []}
                    />
                </div>

                <div className='absolute bottom-2 right-2 flex gap-2 items-center'>
                    {showDelete ? (
                        <>
                            <Button
                                onClick={handleDelete}
                                size={'sm'}
                                variant='outline'
                                className='text-danger dark:text-darktext1 dark:border-danger'
                            >
                                Delete Department?
                            </Button>
                            <Button variant={'outline'} size={'sm'} onClick={() => setShowDelete(false)}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <button onClick={() => setShowDelete(true)}>
                            <FaTrash className='text-lg text-red dark:text-lightred' />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
