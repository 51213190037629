import { createColumnHelper } from '@tanstack/react-table'
import Table from 'analytics/reports/Table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { subDays } from 'date-fns'
import { ProcurementContext, ProcurementContextType, getFilterBarUsersActiveFirst } from 'procurement/helpers'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { cn, sendToastWarning } from '../../../helpers'
import { useCompanySalesListQuery } from '../../api/useQueries'
import FilterToolBar from '../FilterToolBar'
import { useGetPathname } from 'productSetupV2/api/useQueries'

export default function CompanySalesList({
    userSelectedCompanyFilterValues,
    setUserSelectedCompanyFilterValues,
}: {
    userSelectedCompanyFilterValues: any
    setUserSelectedCompanyFilterValues: Function
}) {
    const user = useSelector<any, any>((state) => state.user)
    const { users, toggleModal } = useContext<ProcurementContextType>(ProcurementContext)
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    const [selectedStartDate, setSelectedStartDate] = useState<undefined | Date>(
        new Date(subDays(new Date(), 30).toDateString())
    )
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedInterval, setSelectedInterval] = useState<undefined | Number>(30)

    useCompanySalesListQuery(undefined)

    const companySalesListQuery = useCompanySalesListQuery(selectedStartDate)
    const { salesList } = companySalesListQuery?.data || {}

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsersActiveFirst(user, users),
                title: 'Users',
                field: 'users',
                values: userSelectedCompanyFilterValues.users,
                searchToggle: true,
            },
        ],
        setFunction: setUserSelectedCompanyFilterValues,
        resetFunction: () =>
            setUserSelectedCompanyFilterValues({
                lead_types: [],
                lifecycle_statuses: [],
                users: [],
            }),
    }

    const handleChangeStartDate = (days: number) => {
        setSelectedInterval(days)
        setSelectedStartDate(new Date(subDays(new Date(), days).toDateString()))
    }

    type SalesData = {
        company_id: number | string
        vendor: string
        discounts: number
        gross_sales: number
        net_sales: number
        refunds: number
        unit_count: number
    }

    const filteredSalesInfo = salesList?.filter((info: SalesData) => {
        if (info?.vendor?.toLowerCase()?.includes(search?.toLowerCase())) {
            return info
        }
    })

    const tableData = filteredSalesInfo?.length > 0 ? filteredSalesInfo : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<SalesData>()
    const formatter = new Intl.NumberFormat('en-US')
    const pathname = useGetPathname()
    const address = pathname.includes('procurement') ? 'procurement' : 'vendors'

    const salesColumns = [
        columnHelper.accessor('vendor', {
            cell: (info) => {
                const companyId = info.row.original.company_id || undefined
                return (
                    <div className='flex flex-col gap-2 cursor-pointer'>
                        <span
                            onClick={() => {
                                if (!companyId) {
                                    sendToastWarning({ message: 'No Linked Company exists' })
                                    return
                                }
                                navigate(`/${address}/${companyId}?${searchParams.toString()}`)
                            }}
                            className='font-semibold'
                        >
                            {info.getValue()}
                        </span>
                    </div>
                )
            },
        }),
        columnHelper.accessor('gross_sales', {
            header: 'Gross Sales',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        ${typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('unit_count', {
            header: 'Unit Count',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('refunds', {
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        -${typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('discounts', {
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        -${typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('net_sales', {
            header: 'Net Sales',

            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        ${typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
    ]

    const columnCount = salesColumns?.length.toString() || 10

    return (
        <>
            <FilterToolBar search={search} setSearch={setSearch} filterBarObject={companyFilterBarObject}>
                <FetchingSpinner isFetching={companySalesListQuery.isPending} />
                <Button
                    className={cn(selectedInterval === 30 && 'border-accent2')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => handleChangeStartDate(30)}
                >
                    Last 30 Days
                </Button>
                <Button
                    className={cn(selectedInterval === 60 && 'border-accent2')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => handleChangeStartDate(60)}
                >
                    Last 60 Days
                </Button>
                <Button
                    className={cn(selectedInterval === 90 && 'border-accent2')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => handleChangeStartDate(90)}
                >
                    Last 90 Days
                </Button>
                <Button
                    className={cn(selectedInterval === 365 && 'border-accent2')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => handleChangeStartDate(365)}
                >
                    Last 365 Days
                </Button>
                <Button
                    className={cn(!selectedInterval && 'border-accent2')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => {
                        setSelectedStartDate(undefined)
                        return setSelectedInterval(undefined)
                    }}
                >
                    Totals
                </Button>
                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div>
                <>
                    <div>
                        {tableDataMemo && (
                            <div className='w-fit  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <Table columns={salesColumns} tableData={tableDataMemo} columnCount={columnCount} />
                            </div>
                        )}
                    </div>
                </>
            </div>
        </>
    )
}
