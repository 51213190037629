import { CheckIcon } from '@radix-ui/react-icons'
import { ActiveFilters } from 'custom_components/component_Basics/ActiveFilters'
import { CustomTabs, TabsContent, TabsList, TabsTrigger } from 'custom_components/component_Basics/CustomTabs'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { useContext, useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { FaBars, FaBuilding, FaClipboardList, FaSyncAlt, FaTasks } from 'react-icons/fa'
import {
    MdOutlineSignalCellularAlt,
    MdOutlineSignalCellularAlt1Bar,
    MdOutlineSignalCellularAlt2Bar,
} from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import queryKeys from 'tasksV2/api/queryKeys'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { allowedToPin, TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'
import { V2TaskCalendarRange } from 'tasksV2/tasks/components/V2TaskCalendar'
import TaskListGrouped from 'tasksV2/tasks/lists/TaskListGrouped'
import TaskListSmallPinned from 'tasksV2/tasks/lists/TaskListSmallPinned'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import VendorsListScreen from 'vendors/VendorsListScreen'
import RefreshButton from '../custom_components/RefreshButton'
import { Button } from '../custom_components/component_Basics/Button'
import { cn, vFetch } from '../helpers'
import MoreActions from '../processingTimes/components/MoreActions'
import { UserSelectedCompanyFilterValues } from './Procurements.types'
import { procurementQueries } from './api/procurementQueryKeys'
import { useCompanyDetailQuery } from './api/useQueries'
import CategoryList from './components/lists/CategoryList'
import CompanyListV3 from './components/lists/CompanyListV3'
import CompanySalesList from './components/lists/CompanySalesList'
import CompanyModalV2 from './components/modals/CompanyModalV2'
import CompanyModalV2Loading from './components/modals/CompanyModalV2Loading'
import CreateCategory from './components/modals/CreateCategory'
import CreateCompanyModalV2 from './components/modals/CreateCompanyModalV2'
import MissingVendorsModal from './components/modals/MissingVendorsModal'
import ParentCompanyModal from './components/modals/ParentCompanyModal'
import { getDateToday } from './constants'
import { CompanyProvider, ProcurementContext, ProcurementContextType } from './helpers'
import { useGetPathname } from 'productSetupV2/api/useQueries'

export default function ProcurementCompanies() {
    const user = useSelector<any, any>((state) => state.user)
    const { companyId } = useParams()
    const [showListQuickModal, setShowListQuickModal] = useState<string | undefined>()

    const { activeModal, toggleModal, categories, selectedCompany, setSelectedCompany } =
        useContext<ProcurementContextType>(ProcurementContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get('tab')
    const googleCode = searchParams.get('code')
    const scope = searchParams.get('scope')
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [tokens, setTokens] = useState(null)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id') || '']
    const { write, read } = permissionSet?.procurement
    const tabOptions = write ? ['companies', 'tasks', 'categories', 'sales', 'legacy'] : ['companies', 'legacy']
    const searchRef: any = useRef()
    const taskSearchParam = searchParams.get('taskSearch') || ''
    const pathname = useGetPathname()
    const address = pathname.includes('procurement') ? 'procurement' : 'vendors'

    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const fromString = searchParams.get('from') || ''
    const toString = searchParams.get('to') || ''
    const [taskDueDateRange, setTaskDueDateRange] = useState<DateRange | undefined>({
        from: fromString ? new Date(fromString) : getDateToday(),
        to: toString ? new Date(toString) : undefined,
    })
    const departmentsString = searchParams.get('departments') || ''
    const prioritiesString = searchParams.get('priorities') || ''
    const taskAssignedToString = searchParams.get('task_assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''
    const typesString = searchParams.get('types') || ''

    const stringGroupTasksSetupOptions = localStorage.getItem('groupTasksSetupOptions')
    const [groupTasksSetupOptions, setGroupTasksSetupOptions] = useState(
        stringGroupTasksSetupOptions ? JSON.parse(stringGroupTasksSetupOptions) : []
    )

    const stringGroupFilterValues = localStorage.getItem('groupFilterValues')
    const [groupFilterValues, setGroupFilterValues] = useState<any>(
        stringGroupFilterValues ? JSON.parse(stringGroupFilterValues) : {}
    )

    const departmentValues = decodeURIComponent(departmentsString)
        .split(',')
        .filter((v) => v)
    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)
    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)
    const taskAssignedToValues = decodeURIComponent(taskAssignedToString)
        .split(',')
        .filter((v) => v)

    const priorityIcons = [MdOutlineSignalCellularAlt1Bar, MdOutlineSignalCellularAlt2Bar, MdOutlineSignalCellularAlt]
    const priorityOptions = TASK_PRIORITIES?.map((priority: any, index) => {
        return {
            label: priority,
            value: priority,
            icon: priorityIcons[index],
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const selectionOptions = [{ title: 'Closed', id: 'closed' }]
    const depSelectionOptions = [
        { title: 'Procurement', id: 'procurement' },
        { title: 'All', id: 'allDep' },
    ]
    const userFilterOptions = [
        { title: 'Myself', id: 'showMyself' },
        { title: 'All', id: 'showAll' },
    ]
    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('groupFilterValues', JSON.stringify(newValue))
        }
    }
    const { departments } = departmentConfigQuery?.data || {}

    const departmentOptions = departments?.map((department: any) => {
        return {
            label: department.title,
            value: department.id.toString(),
            icon: undefined,
        }
    })

    const types = departments
        ?.map((department: any) => {
            return department.types.flat()
        })
        .flat()
    const typeOptions = types?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })
    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => user.type !== 'Archived')
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'priorities',
            label: 'priorities',
            options: priorityOptions,
            values: priorityValues,
        },
        {
            searchParamField: 'statuses',
            label: 'statuses',
            options: statusOptions,
            values: statusValues,
        },
        {
            searchParamField: 'types',
            label: 'types',
            options: typeOptions,
            values: typeValues,
        },
        {
            searchParamField: 'departments',
            label: 'departments',
            options: departmentOptions,
            values: departmentValues,
        },
        {
            searchParamField: 'task_assigned_to',
            label: 'users',
            options: userOptions,
            values: taskAssignedToValues,
        },
        {
            searchParamField: 'taskSearch',
            label: 'search',
            options: [{ label: taskSearchParam, value: taskSearchParam, icon: undefined }],
            values: taskSearchParam ? [taskSearchParam] : [],
        },
    ]
    // ACTIVE FILTERS

    const filters = {
        userIds: taskAssignedToValues.length
            ? taskAssignedToValues
            : groupFilterValues['showMyself']
              ? [user.id]
              : undefined,
        departmentIds:
            departmentValues.length > 0
                ? departmentValues
                : groupFilterValues['procurement']
                  ? [
                        departments?.find((department: any) => department.title === 'Procurement')
                            ? departments?.find((department: any) => department.title === 'Procurement').id
                            : undefined,
                    ]
                  : undefined,
        typeIds: typeValues,
        priorities: priorityValues,
        statuses:
            statusValues.length > 0
                ? statusValues
                : groupFilterValues['closed']
                  ? [...TASK_STATUSES, 'Archived']
                  : [
                        ...TASK_STATUSES.filter(
                            (status) => status != 'Completed' && status != 'Archived' && status != 'Cancelled'
                        ),
                    ],
        dateRange: taskDueDateRange,
        overdue: true,
        search: taskSearchParam,
        limit: 2000,
    }

    const lastViewedCompany = localStorage.getItem('lastViewedCompany') || undefined
    const lastViewedView = localStorage.getItem('lastViewedView') || undefined

    const [userSelectedCompanyFilterValues, setUserSelectedCompanyFilterValues] =
        useState<UserSelectedCompanyFilterValues>({
            lifecycle_statuses: [],
            lead_types: [],
            users: [user.id],
        })

    const companyDetailQuery = useCompanyDetailQuery(companyId ? parseInt(companyId) : undefined)
    const company = companyDetailQuery?.data.company || {}

    function linkGmailOAuth() {
        if (!googleCode && !scope) {
            vFetch(`/v2/google/auth?user_id=${user.id}`, {
                cb: (res: any) => {
                    if (res.success && res.tokens) setTokens(res.tokens)
                    else if (res.authorizationURL) window.location.replace(res.authorizationURL)
                },
            })
        }
    }

    const options = [
        {
            displayText: `Sync Gmail Account`,
            icon: FaSyncAlt,
            action: linkGmailOAuth,
        },
        {
            displayText: `Missing Vendors List`,
            icon: FaSyncAlt,
            action: () => toggleModal('missing-vendors'),
        },
    ]

    useEffect(() => {
        if (googleCode && scope && !tokens) {
            const { REACT_APP_MODE, REACT_APP_LOCAL_API_URL, REACT_APP_PRODUCTION_URL, REACT_APP_DEVELOPEMENT_URL } =
                process.env
            const API_URL =
                REACT_APP_MODE === 'production'
                    ? REACT_APP_PRODUCTION_URL
                    : REACT_APP_MODE === 'development'
                      ? REACT_APP_DEVELOPEMENT_URL
                      : REACT_APP_LOCAL_API_URL
            vFetch(`/v2/google/auth`, {
                method: 'POST',
                body: JSON.stringify({ name: 'Google', code: googleCode, user_id: user.id, email: user.email }),
                cb: (res: any) => {
                    if (res.success && res.tokens) {
                        setTokens(res.tokens)
                        window.history.pushState({ data: 'Token success' }, 'Token sucess', `${API_URL}/procurement`)
                    } else if (res.authorizationURL) {
                        window.location.replace(res.authorizationURL)
                    }
                },
            })
        }
    }, [])

    useEffect(() => {
        if (activeModal) {
            document.body.classList.add('overflow-y-hidden')
        } else {
            document.body.classList.remove('overflow-y-hidden')
        }
        return () => document.body.classList.remove('overflow-y-hidden')
    }, [activeModal])

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    const handleTableSetupEdit = (tableSetup: any) => {
        if (Object.keys(tableSetup)?.length) {
            searchParams.delete('Pinned_page')
            setSearchParams(searchParams)
            localStorage.setItem('groupTasksSetupOptions', JSON.stringify(tableSetup))
        }
    }

    useEffect(() => {
        if (!groupTasksSetupOptions.length) {
            if (departments?.length) {
                const defaultTableSetup = [
                    {
                        id: -1,
                        hidden: false,
                    },
                ]
                localStorage.setItem('groupTasksSetupOptions', JSON.stringify(defaultTableSetup))
                setGroupTasksSetupOptions(defaultTableSetup)
            }
        }
    }, [departments])
    return (
        <div className=''>
            <CustomTabs defaultValue={tab || 'companies'} value={tab || 'companies'} className=''>
                <div className='flex justify-between  bg-bg2 relative dark:bg-darkbg2'>
                    <div className='flex gap-4'>
                        <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>
                            {write ? 'Procurement' : 'Vendors'}
                        </h1>
                        <TabsList className={cn('grid grid-cols-5 w-[520px]', !write && 'grid-cols-2 w-[200px]')}>
                            {tabOptions.map((option) => {
                                return (
                                    <TabsTrigger
                                        onClick={() => {
                                            searchParams.set('tab', `${option}`)
                                            setSearchParams(searchParams)
                                        }}
                                        className='capitalize'
                                        value={option}
                                    >
                                        {option}
                                    </TabsTrigger>
                                )
                            })}
                        </TabsList>
                    </div>
                    <div className='flex gap-[8px] absolute items-center top-[-16px] right-2'>
                        <div className='flex gap-6 mr-4'>
                            <div className='flex gap-8'>
                                <button
                                    className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all mr-[-14px]'
                                    onClick={() => {
                                        setShowListQuickModal(undefined)
                                        setSelectedCompany(undefined)
                                        toggleCreateModal()
                                    }}
                                >
                                    <FaTasks className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                                    <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                                </button>
                                {write && (
                                    <button
                                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                                        onClick={() => {
                                            setShowListQuickModal(undefined)
                                            setSelectedCompany(undefined)
                                            toggleModal('create-company')
                                        }}
                                    >
                                        <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                                            <FaBuilding />
                                        </div>
                                        <p className='font-medium leading-[1] uppercase text-[12px]'>Company</p>
                                    </button>
                                )}
                                {write && (
                                    <button
                                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                                        onClick={() => {
                                            setShowListQuickModal(undefined)
                                            setSelectedCompany(undefined)
                                            toggleModal('create-category')
                                        }}
                                    >
                                        <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                                            <FaClipboardList />
                                        </div>
                                        <p className='font-medium leading-[1] uppercase text-[12px]'>Category</p>
                                    </button>
                                )}
                            </div>
                        </div>
                        <Link
                            draggable={false}
                            className={cn(!lastViewedCompany && 'pointer-events-none opacity-50', '')}
                            to={`/${address}/${lastViewedCompany}?${searchParams.toString()}${
                                lastViewedView ? `&view=${lastViewedView}` : ''
                            }`}
                        >
                            <Button variant={'outline'}>Return to Last Company</Button>
                        </Link>
                        {write && <MoreActions options={options} displayContent={{ icon: FaBars }} />}
                        <RefreshButton
                            queryKeys={[
                                procurementQueries.companies._def.toString(),
                                procurementQueries.tasks._def.toString(),
                                queryKeys.tasks.list._def.toString(),
                            ]}
                        />
                    </div>
                </div>
                <TabsContent value='companies'>
                    {!companyId && (
                        <CompanyListV3
                            showListQuickModal={showListQuickModal}
                            setShowListQuickModal={setShowListQuickModal}
                        />
                    )}
                </TabsContent>
                {write && !companyId && (
                    <>
                        <TabsContent value='tasks'>
                            <div className='flex gap-4 my-2 justify-between items-center'>
                                <div className='w-full flex flex-col gap-2 '>
                                    <div className='flex items-center justify-between'>
                                        <div className='w-1/3'>
                                            <TasksScreenV2SearchContainer ref={searchRef} field={'taskSearch'} />
                                        </div>
                                        <div className='grid grid-cols-[auto_auto_auto_auto_auto_auto_auto] grid-rows-1 gap-x-2 gap-y-1 items-center h-fit'>
                                            <FilterBarSelectorButton
                                                variant='outline'
                                                align='end'
                                                setFilterValues={() => {}}
                                                options={departmentOptions}
                                                title={'Department'}
                                                field={'departments'}
                                                filterValues={departmentValues}
                                                searchToggle={false}
                                                editSearchParams={true}
                                                preview={false}
                                            />
                                            <FilterBarSelectorButton
                                                variant='outline'
                                                align='end'
                                                setFilterValues={() => {}}
                                                options={statusOptions}
                                                title={'Status'}
                                                field={'statuses'}
                                                filterValues={statusValues}
                                                searchToggle={false}
                                                editSearchParams={true}
                                                preview={false}
                                            />
                                            <FilterBarSelectorButton
                                                variant='outline'
                                                align='end'
                                                setFilterValues={() => {}}
                                                options={priorityOptions}
                                                title={'Priority'}
                                                field={'priorities'}
                                                filterValues={priorityValues}
                                                searchToggle={false}
                                                editSearchParams={true}
                                                preview={false}
                                            />
                                            <FilterBarSelectorButton
                                                variant='outline'
                                                align='end'
                                                setFilterValues={() => {}}
                                                options={typeOptions}
                                                title={'Type'}
                                                field={'types'}
                                                filterValues={typeValues}
                                                searchToggle={false}
                                                editSearchParams={true}
                                                preview={false}
                                            />
                                            <FilterBarSelectorButton
                                                variant='outline'
                                                align='end'
                                                setFilterValues={() => {}}
                                                options={userOptions}
                                                title={'Assigned To'}
                                                field={'task_assigned_to'}
                                                filterValues={taskAssignedToValues}
                                                searchToggle={true}
                                                editSearchParams={true}
                                                preview={false}
                                            />
                                            <V2TaskCalendarRange
                                                dateRange={taskDueDateRange}
                                                setDate={setTaskDueDateRange}
                                            />
                                            <div
                                                onClick={() => {
                                                    departments?.map((department: any) => {
                                                        searchParams.set(`${department.title}_page`, '1')
                                                    })
                                                    searchParams.set('page', '1')
                                                    searchParams.delete('calendarPreviewUser')
                                                    searchParams.delete('calendarPreviewType')
                                                    setSearchParams(searchParams)
                                                    return setTaskDueDateRange({ from: getDateToday() })
                                                }}
                                                className={cn(
                                                    ' text-text2 dark:text-darktext2 dark:border-darkbg2 border border-bg1 h-fit w-fit rounded-md px-1  text-sm',
                                                    (taskDueDateRange?.from?.toDateString() !=
                                                        getDateToday().toDateString() ||
                                                        taskDueDateRange.to) &&
                                                        'border-lightgrey text-text1 cursor-pointer dark:text-darktext1 dark:border-lightgrey'
                                                )}
                                            >
                                                Reset
                                            </div>
                                        </div>
                                    </div>
                                    {/* ACTIVE FILTERS */}
                                    <div className='flex items-center gap-2'>
                                        <div className='border rounded h-fit dark:border-darkgrey  p-[2px] px-1 flex justify-end gap-2 text-sm'>
                                            {depSelectionOptions?.map((option: any) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            if (groupFilterValues[option.id]) {
                                                                return
                                                            }
                                                            setGroupFilterValues((prev: any) => {
                                                                const newValue = {
                                                                    ...prev,
                                                                }
                                                                if (option.id === 'allDep') {
                                                                    newValue['procurement'] = false
                                                                }
                                                                if (option.id === 'procurement') {
                                                                    newValue['allDep'] = false
                                                                }
                                                                newValue[option.id] = !newValue[option.id]
                                                                handleFilterValuesEdit(newValue)
                                                                return newValue
                                                            })
                                                        }}
                                                        className='flex gap-1 cursor-pointer items-center'
                                                    >
                                                        <div
                                                            className={cn(
                                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                                groupFilterValues[option.id]
                                                                    ? 'bg-primary text-primary-foreground'
                                                                    : 'opacity-50 [&_svg]:invisible'
                                                            )}
                                                        >
                                                            <CheckIcon className={cn('h-4 w-4')} />
                                                        </div>
                                                        <p>{option.title}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='border rounded h-fit dark:border-darkgrey p-[2px] px-1 flex justify-end gap-2 text-sm'>
                                            {selectionOptions?.map((option: any) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setGroupFilterValues((prev: any) => {
                                                                const newValue = {
                                                                    ...prev,
                                                                }
                                                                newValue[option.id] = !newValue[option.id]
                                                                handleFilterValuesEdit(newValue)
                                                                return newValue
                                                            })
                                                        }}
                                                        className='flex gap-1 cursor-pointer items-center'
                                                    >
                                                        <div
                                                            className={cn(
                                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                                groupFilterValues[option.id]
                                                                    ? 'bg-primary text-primary-foreground'
                                                                    : 'opacity-50 [&_svg]:invisible'
                                                            )}
                                                        >
                                                            <CheckIcon className={cn('h-4 w-4')} />
                                                        </div>
                                                        <p>{option.title}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='border rounded h-fit dark:border-darkgrey  p-[2px] px-1 flex justify-end gap-2 text-sm'>
                                            {userFilterOptions?.map((option: any) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            if (groupFilterValues[option.id]) {
                                                                return
                                                            }
                                                            setGroupFilterValues((prev: any) => {
                                                                const newValue = {
                                                                    ...prev,
                                                                }
                                                                if (option.id === 'showAll') {
                                                                    newValue['showMyself'] = false
                                                                }
                                                                if (option.id === 'showMyself') {
                                                                    newValue['showAll'] = false
                                                                }
                                                                newValue[option.id] = !newValue[option.id]
                                                                handleFilterValuesEdit(newValue)
                                                                return newValue
                                                            })
                                                        }}
                                                        className='flex gap-1 cursor-pointer items-center'
                                                    >
                                                        <div
                                                            className={cn(
                                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                                groupFilterValues[option.id]
                                                                    ? 'bg-primary text-primary-foreground'
                                                                    : 'opacity-50 [&_svg]:invisible'
                                                            )}
                                                        >
                                                            <CheckIcon className={cn('h-4 w-4')} />
                                                        </div>
                                                        <p>{option.title}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <ActiveFilters activeFilters={activeFilters} />
                                    </div>
                                    {/* ACTIVE FILTERS */}
                                </div>
                            </div>
                            {!departmentConfigQuery.isLoading && (
                                <div className='flex flex-col gap-3 h-[80vh] min-h-0 text-sm'>
                                    {(user.type.includes('Admin') || allowedToPin.includes(user.id)) && (
                                        <TaskListSmallPinned
                                            department={{ id: -1, title: 'Pinned' }}
                                            filters={filters}
                                            tableSetup={groupTasksSetupOptions}
                                            setTableSetup={setGroupTasksSetupOptions}
                                            position={0}
                                            handleTableSetupEdit={handleTableSetupEdit}
                                            setGroupTasksSetupOptions={setGroupTasksSetupOptions}
                                        />
                                    )}
                                    <TaskListGrouped filters={filters} />
                                </div>
                            )}
                        </TabsContent>
                        <TabsContent value='categories'>
                            <CategoryList categories={categories} />
                        </TabsContent>
                        <TabsContent value='sales'>
                            <CompanySalesList
                                userSelectedCompanyFilterValues={userSelectedCompanyFilterValues}
                                setUserSelectedCompanyFilterValues={setUserSelectedCompanyFilterValues}
                            />
                        </TabsContent>
                    </>
                )}
                <TabsContent value='legacy'>
                    <VendorsListScreen />
                </TabsContent>
            </CustomTabs>

            {activeModal === 'missing-vendors' && <MissingVendorsModal />}
            {activeModal === 'create-company' && <CreateCompanyModalV2 />}
            {activeModal === 'create-category' && <CreateCategory />}
            {companyId && Object.keys(company).length && <CompanyProvider>{<CompanyModalV2 />}</CompanyProvider>}
            {companyId && !Object.keys(company).length && (
                <CompanyProvider>{<CompanyModalV2Loading />}</CompanyProvider>
            )}

            {activeModal === 'parent' && <ParentCompanyModal parentCompany={selectedCompany.parent_company} />}
            {showCreateModal && (
                <CreateTaskModal
                    prePopulatedValues={{
                        department_id: departments?.find((department: any) => department.title === 'Procurement')
                            ? departments?.find((department: any) => department.title === 'Procurement').id
                            : undefined,
                        priority: 'Low',
                        status: 'In Progress',
                        associations:
                            companyId && Object.keys(company).length
                                ? [
                                      {
                                          resource_name: 'user',
                                          readable_name: `${user.firstName} ${user.lastName}`,
                                          resource_id: user.id,
                                      },
                                      {
                                          resource_name: 'company',
                                          readable_name: `${company?.name}`,
                                          resource_id: company.id,
                                      },
                                  ]
                                : selectedCompany
                                  ? [
                                        {
                                            resource_name: 'user',
                                            readable_name: `${user.firstName} ${user.lastName}`,
                                            resource_id: user.id,
                                        },
                                        {
                                            resource_name: 'company',
                                            readable_name: `${selectedCompany?.name}`,
                                            resource_id: selectedCompany.id,
                                        },
                                    ]
                                  : [
                                        {
                                            resource_name: 'user',
                                            readable_name: `${user.firstName} ${user.lastName}`,
                                            resource_id: user.id,
                                        },
                                    ],
                    }}
                    closeModal={() => {
                        toggleCreateModal()
                        if (!companyId) {
                            setSelectedCompany(undefined)
                        }
                    }}
                />
            )}
        </div>
    )
}
