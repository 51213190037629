import { useContext, useState } from 'react'
import { ProcurementContext, ProcurementContextType, formatDate } from '../../helpers'
import CustomIframe from './CustomIframe'
import EmailContainer from './EmailContainer'
import { DoubleArrowDownIcon, DoubleArrowUpIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'

export default function IncomingEmail({ email }: { email: any }) {
    const { procurementReps } = useContext<ProcurementContextType>(ProcurementContext)
    const [expand, setExpand] = useState<boolean>(false)

    return (
        <div className='w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey bg-white dark:bg-darkness shadow-md'>
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                {procurementReps.find((rep) => email.author.includes(rep.email)) ? 'Email' : 'Incoming Email'}
            </p>
            <div onClick={() => setExpand(expand ? false : true)} className='flex justify-between mb-[8px]'>
                <p className='text-[14px]'>
                    <span className='font-bold'>{email.subject}</span>
                </p>
                <p className='font-bold text-[12px]'>{formatDate(new Date(email.delivered_at))}</p>
            </div>
            <div className='rounded-[4px] dark:text-offwhite relative'>
                <div className='flex items-center justify-between'>
                    <div onClick={() => setExpand(expand ? false : true)} className='flex flex-col mb-[16px]'>
                        <p className='text-[14px]'>
                            from <span className='font-bold'>{email.author}</span>
                        </p>
                        <p className='text-[14px]'>
                            to <span className='font-bold'>{email.delivered_to}</span>
                        </p>
                    </div>
                    <Button
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => setExpand(expand ? false : true)}
                        className={cn('flex gap-2 items-center', expand && 'border-accent2')}
                    >
                        <p>{expand ? 'Collapse' : 'Expand'}</p>
                        {expand ? <DoubleArrowUpIcon /> : <DoubleArrowDownIcon />}
                    </Button>
                </div>
                <div
                    onClick={() => setExpand(expand ? false : true)}
                    className={` ${
                        expand
                            ? 'hidden'
                            : 'h-full min-h-[72px] after:w-full after:h-[16px] after:bg-[linear-gradient(to_top,white,transparent)] dark:after:bg-[linear-gradient(to_top,#1D212D,transparent)] after:absolute after:bottom-0 after:left-0'
                    }`}
                >
                    {email.snippet + '...'}
                </div>
                {expand && (
                    <CustomIframe expand={expand} setExpand={setExpand}>
                        <EmailContainer html={email.text_html} mode={localStorage.getItem('theme') || ''} />
                    </CustomIframe>
                )}
                <div
                    onClick={() => setExpand(expand ? false : true)}
                    className='absolute right-2 bottom-2 text-accent2 dark:text-darkaccent2'
                >
                    {' '}
                    {expand ? <DoubleArrowUpIcon /> : <DoubleArrowDownIcon />}
                </div>
            </div>
        </div>
    )
}
