import { RowSelectionState } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomTabs, TabsContent, TabsList, TabsTrigger } from 'custom_components/component_Basics/CustomTabs'
import { useContext, useState } from 'react'
import { CgDanger, CgExport } from 'react-icons/cg'
import { FaBars, FaSyncAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import VendorAccountingInfo from 'vendors/components/VendorAccountingInfo'
import VendorInfoV2 from 'vendors/components/VendorInfoV2'
import { cn } from '../../helpers'
import MoreActions from '../../processingTimes/components/MoreActions'
import PrivateEvent from '../../tasks/components/PrivateEvent'
import { PrivateEventType } from '../../tasks/tasks.types'
import { useDuplicateCompany } from '../api/useQueries'
import { COMPANY_RESOURCE_VIEW_TABS } from '../constants'
import {
    CompanyContext,
    CompanyContextType,
    ProcurementContext,
    ProcurementContextType,
    ProductWebsocketProvider,
} from '../helpers'
import CompanyQuickActions from './CompanyQuickActions'
import ActivityListV3 from './lists/ActivityListV3'
import ContactListV2 from './lists/ContactListV2'
import EmailListV2 from './lists/EmailListV2'
import FileListV2 from './lists/FileListV2'
import ProductListV2 from './lists/ProductListV2'
import SalesInfoV2 from './lists/SalesInfoV2'
import UnfulfilledOrdersList from './lists/UnfulfilledOrdersList'
import CompanyQuickModal from './modals/CompanyQuickModal'

export default function CompanyResourcesV2({
    company,
    companyEventsPrivate,
    setShowEventsPrivate,
    selectedLifecycle,
    setShowDelete,
    handleCloseModal,
}: {
    company: any
    companyEventsPrivate: PrivateEventType[]
    setShowEventsPrivate: Function
    selectedLifecycle: string | undefined
    setShowDelete: any
    handleCloseModal: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedLegacyContact, setSelectedLegacyContact] = useState()
    const { showQuickModal, setShowQuickModal } = useContext<CompanyContextType>(CompanyContext)
    const resourceView = searchParams.get('view') || 'activity'
    const { toggleModal } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector<any, any>((state) => state.user)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id') || '']
    const { write, read } = permissionSet?.procurement
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

    const duplicateCompany = useDuplicateCompany()

    function handleDuplicateCompany() {
        duplicateCompany.mutate(
            { editableCompany: company },
            {
                onSuccess: () => {
                    toggleModal()
                },
            }
        )
    }

    const options = [
        {
            displayText: `Duplicate Company`,
            icon: FaSyncAlt,
            action: handleDuplicateCompany,
        },
        {
            displayText: `Export Product CSV`,
            icon: CgExport,
            action: () => setShowQuickModal('csv_export'),
        },

        {
            displayText: ``,
            icon: undefined,
            action: () => {},
            disabled: true,
        },
        {
            displayText: `Delete Company`,
            icon: CgDanger,
            action: () => setShowDelete(true),
            font: 'text-danger dark:text-darkdanger',
        },
    ]

    const activeTabs = write
        ? COMPANY_RESOURCE_VIEW_TABS
        : COMPANY_RESOURCE_VIEW_TABS.filter((tab: string) => tab !== 'products')
    return (
        <div className={cn('flex w-full h-full', resourceView === 'activity' && 'overflow-hidden')}>
            <div
                className={cn(
                    'w-full h-full flex flex-col min-h-0',
                    resourceView === 'products' && 'overflow-auto h-full'
                )}
            >
                <CustomTabs
                    defaultValue={resourceView || 'activity'}
                    value={resourceView || 'activity'}
                    className='w-full'
                >
                    <div className='flex w-full gap-4'>
                        {resourceView === 'products' && (
                            <div
                                className={cn(
                                    'flex flex-col gap-[8px] overflow-clip pr-[16px] w-1/5 shrink-0',
                                    resourceView === 'products' && 'pr-0'
                                )}
                            >
                                <div className=' flex justify-between items-start'>
                                    <div>
                                        <h2 className='text-darkgrey dark:text-offwhite text-[18px] font-bold uppercase leading-[1]'>
                                            {company.name}
                                        </h2>
                                    </div>
                                    <CompanyQuickActions company={company} resourceView={resourceView} />
                                </div>
                            </div>
                        )}
                        <div className='flex justify-between w-full'>
                            <TabsList className={cn('flex dark:border dark:border-darkgrey')}>
                                {activeTabs.map((option) => {
                                    return (
                                        <TabsTrigger
                                            onClick={() => {
                                                localStorage.setItem('lastViewedView', option || 'activity')
                                                searchParams.set('view', `${option}`)
                                                setSearchParams(searchParams)
                                            }}
                                            className='capitalize w-fit'
                                            value={option}
                                        >
                                            {option}
                                        </TabsTrigger>
                                    )
                                })}
                                {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                    <TabsTrigger
                                        onClick={() => {
                                            localStorage.setItem('lastViewedView', 'sales')
                                            searchParams.set('view', `${'sales'}`)
                                            setSearchParams(searchParams)
                                        }}
                                        className='capitalize w-fit'
                                        value={'sales'}
                                    >
                                        {'sales'}
                                    </TabsTrigger>
                                )}
                            </TabsList>

                            <div className='flex gap-2 items-center h-[36px]'>
                                {user.type === 'Admin' ||
                                    (user.type === 'SuperAdmin' && companyEventsPrivate.length > 0 && (
                                        <div className={cn('self-center')}>
                                            <PrivateEvent
                                                events={companyEventsPrivate}
                                                setViewModal={setShowEventsPrivate}
                                            />
                                        </div>
                                    ))}

                                <MoreActions options={options} displayContent={{ icon: FaBars }} />
                                <Button
                                    variant={'outline'}
                                    size={'sm'}
                                    onClick={handleCloseModal}
                                    className='h-[29.5px]'
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                    <TabsContent value='activity'>
                        <ActivityListV3 company={company} />
                    </TabsContent>

                    <TabsContent value='emails'>
                        <EmailListV2 company={company} />
                    </TabsContent>
                    <TabsContent value='products'>
                        <ProductWebsocketProvider>
                            <ProductListV2 company={company} />
                        </ProductWebsocketProvider>
                    </TabsContent>
                    <TabsContent value='accounting'>
                        <VendorAccountingInfo company={company} />
                    </TabsContent>
                    <TabsContent value='contacts'>
                        <ContactListV2 company={company} setSelectedLegacyContact={setSelectedLegacyContact} />
                    </TabsContent>
                    <TabsContent value='files'>
                        <FileListV2 company={company} />
                    </TabsContent>
                    <TabsContent value='info'>
                        <VendorInfoV2 company={company} />
                    </TabsContent>
                    <TabsContent value='sales'>
                        <SalesInfoV2 company={company} />
                    </TabsContent>
                    <TabsContent value='unfulfilled'>
                        <UnfulfilledOrdersList
                            company={company}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                        />
                    </TabsContent>
                </CustomTabs>
            </div>

            {showQuickModal && (
                <>
                    <CompanyQuickModal
                        showQuickModal={showQuickModal}
                        setShowQuickModal={setShowQuickModal}
                        selectedLifecycle={selectedLifecycle}
                        selectedLegacyContact={selectedLegacyContact}
                    />
                </>
            )}
        </div>
    )
}
