import { ChangeEvent, useState } from 'react'
import { FaPen } from 'react-icons/fa'
import { useUpdateCompany } from '../api/useQueries'
import { REQUIRED_LISTING_FIELDS, SHIPPING_TERMS_OPTIONS } from '../constants'
import { renderIconMessage, truncate } from 'tasksV2/helpers'

export function ProductDetail({
    company,
    detail,
    showEdit,
    setShowEdit,
}: {
    company: any
    detail: any
    showEdit: string
    setShowEdit: any
}) {
    const [editableFields, setEditableFields] = useState<any>({})

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const updateCompany = useUpdateCompany()
    const isEdited = Boolean(Object.keys(editableFields).length)

    function handleUpdate() {
        let requiredFields = REQUIRED_LISTING_FIELDS.concat([])
        const editedCompany = {
            ...company,
            ...editableFields,
        }
        if (editedCompany['shipping_terms'] && editedCompany['shipping_terms'] !== 'free_shipping') {
            requiredFields.push('shipping_details')
        }
        if (editedCompany?.lifecycle_status.includes('Listing') || editedCompany?.lifecycle_status.includes('Listed')) {
            for (const field of requiredFields) {
                if (
                    editedCompany[field as keyof typeof editedCompany] === null ||
                    editedCompany[field as keyof typeof editedCompany] === undefined ||
                    editedCompany[field as keyof typeof editedCompany] === ''
                ) {
                    alert(`Must provide ${field.replaceAll('_', ' ')}!`)
                    return
                }
            }
        }
        updateCompany.mutate(
            { editableFields, id: company.id },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }

    const displayValue =
        detail.field === 'shipping_terms'
            ? SHIPPING_TERMS_OPTIONS.find((option: any) => option.value === company?.shipping_terms)?.label
            : company[detail.field]

    function SaveButtons() {
        return (
            <div className='flex gap-3 justify-end '>
                <button
                    onClick={handleUpdate}
                    className={`${
                        updateCompany.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                >
                    {updateCompany.isPending ? 'SAVING...' : 'SAVE'}
                </button>
                <button
                    onClick={() => {
                        setShowEdit('')
                        setEditableFields({})
                    }}
                    className={`${
                        updateCompany.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                >
                    Cancel
                </button>
            </div>
        )
    }
    return (
        <div
            className={`p-[4px] px-[8px] gap-2 flex rounded-[4px] border border-darkgrey bg-lightgrey/10 font-bold text-[14px]`}
        >
            {showEdit !== detail.field && (
                <>
                    <p>{detail.label} - </p>
                    <div className='relative group hover:z-50'>
                        <div>{displayValue}</div>
                        {displayValue && (
                            <div className='z-10 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] rounded top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-50 shadow-small'>
                                    {renderIconMessage(displayValue)}
                                </div>
                            </div>
                        )}
                    </div>
                    {!showEdit && (
                        <button onClick={() => setShowEdit(detail.field)} className='ml-auto'>
                            <FaPen className='text-blue dark:text-offwhite' />
                        </button>
                    )}
                </>
            )}
            {showEdit === detail.field && detail.field !== 'shipping_terms' && (
                <div className='flex flex-col gap-[4px] w-full'>
                    <div className='flex justify-between'>
                        <p>{detail.label} - </p>
                        <SaveButtons />
                    </div>
                    <textarea
                        id={detail.field}
                        className='w-full font-semibold max-h-[100px] !text-[14px] !leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-1 rounded-[4px]'
                        value={editableFields[detail.field] ?? company[detail.field] ?? ''}
                        name={detail.field}
                        onChange={handleChange}
                    ></textarea>
                </div>
            )}
            {showEdit === detail.field && detail.field === 'shipping_terms' && (
                <div className='flex flex-col gap-[4px] w-full'>
                    <div className='flex justify-between'>
                        <p>{detail.label} - </p>
                        <SaveButtons />
                    </div>
                    <select
                        autoComplete='off'
                        className='w-full font-semibold text-[14px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        id={detail.field}
                        name={detail.field}
                        onChange={handleChange}
                        value={editableFields.shipping_terms ?? company.shipping_terms}
                    >
                        <option className='dark:bg-darkness/90' value=''></option>
                        <option className='dark:bg-darkness/90' value='free_shipping'>
                            Free Shipping
                        </option>
                        <option className='dark:bg-darkness/90' value='we_ship'>
                            We ship using our account
                        </option>
                        <option className='dark:bg-darkness/90' value='they_ship'>
                            They ship using their account
                        </option>
                        <option className='dark:bg-darkness/90' value='both_ship'>
                            {'Ship from either account (see details)'}
                        </option>
                    </select>
                </div>
            )}
        </div>
    )
}
