import { Card, CardContent, CardHeader, CardTitle } from 'custom_components/component_Basics/Card'
import { useCompanySalesQuery } from 'procurement/api/useQueries'
import { CompanyType } from 'procurement/constants'
import { ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { useContext } from 'react'
import { Pie } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

export default function SalesInfoV2({ company }: { company: CompanyType }) {
    const settings = useSelector<any, any>((state: any) => state.settings)

    const companySalesQuery = useCompanySalesQuery(company.shopify_vendor_name)
    const salesInfo = companySalesQuery?.data?.sales[0] || []

    const formatter = new Intl.NumberFormat('en-US')

    const darkGraphOptions = {
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'white',
                },
            },
        },
    }
    const lightGraphOptions = {
        plugins: {
            legend: {
                display: true,
                labels: {
                    color: 'black',
                },
            },
        },
    }
    const chartData = {
        labels: ['Sales', 'Remaining'],
        datasets: [
            {
                data: [salesInfo?.net_sales % 75000, Math.max(75000 - (salesInfo?.net_sales % 75000), 0)],
                hoverOffset: 4,
            },
        ],
    }
    return (
        <div className='grid grid-rows-6 grid-cols-4 gap-2 p-2 h-full '>
            <Card>
                <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
                    <CardTitle className='text-sm font-medium'>Gross Sales</CardTitle>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        className='h-4 w-4 text-muted-foreground'
                    >
                        <path d='M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6' />
                    </svg>
                </CardHeader>
                <CardContent>
                    <div className='text-2xl font-bold'>${formatter.format(salesInfo.gross_sales)}</div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
                    <CardTitle className='text-sm font-medium'>Returns</CardTitle>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        className='h-4 w-4 text-muted-foreground'
                    >
                        <path d='M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6' />
                    </svg>
                </CardHeader>
                <CardContent>
                    <div className='text-2xl font-bold text-danger'>-${formatter.format(salesInfo.refunds)}</div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
                    <CardTitle className='text-sm font-medium'>Discounts</CardTitle>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        className='h-4 w-4 text-muted-foreground'
                    >
                        <path d='M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6' />
                    </svg>
                </CardHeader>
                <CardContent>
                    <div className='text-2xl font-bold text-danger'>-${formatter.format(salesInfo.discounts)}</div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
                    <CardTitle className='text-sm font-medium'>Net Sales</CardTitle>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        className='h-4 w-4 text-muted-foreground'
                    >
                        <path d='M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6' />
                    </svg>
                </CardHeader>
                <CardContent>
                    <div className='text-2xl font-bold'>${formatter.format(salesInfo.net_sales)}</div>
                </CardContent>
            </Card>
            <Card className='row-span-3 col-span-2'>
                <CardHeader className='flex flex-row items-center justify-between space-y-0 pb-2'>
                    <CardTitle className='text-sm font-medium'>Bonus Accumulated</CardTitle>
                </CardHeader>
                <CardContent className='h-full '>
                    <div className='flex h-full'>
                        <div className='flex flex-col w-1/2'>
                            <div className='text-2xl font-bold'>
                                ${formatter.format(Math.floor(salesInfo?.net_sales / 75000) * 500)}
                            </div>
                            <p className='text-xs text-muted-foreground'>
                                ${formatter.format(Math.max(75000 - (salesInfo?.net_sales % 75000)))} to next bonus
                            </p>
                        </div>
                        <div className='flex flex-col gap-2 h-[90%] w-1/2  rounded-md p-1 justify-center items-center '>
                            <div className='h-full'>
                                <Pie
                                    data={chartData}
                                    options={{
                                        maintainAspectRatio: false,
                                        plugins:
                                            settings.theme == 'dark'
                                                ? darkGraphOptions.plugins
                                                : lightGraphOptions.plugins,
                                    }}
                                />
                            </div>
                            <div>
                                <span className='text-end text-[14px]'>
                                    ${formatter.format(salesInfo?.net_sales % 75000)} / ${formatter.format(75000)}
                                </span>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
