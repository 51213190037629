import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { useRef } from 'react'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'
import { cn } from '../../helpers'
import { ActiveFilters, ActiveFilterType } from 'custom_components/component_Basics/ActiveFilters'

type ToolBarProps = {
    children?: any
    className?: string
    showLoading?: boolean
    isLoading?: boolean
    activeFilters?: ActiveFilterType
}

export default function FilterToolBarV2({
    children,
    className,
    showLoading = false,
    isLoading = false,
    activeFilters,
}: ToolBarProps) {
    const searchRef = useRef(null)

    return (
        <div className='flex flex-col items-center w-full'>
            <div className='flex justify-between items-center w-full'>
                <div className='w-1/3 flex gap-2 items-center min-h-[32px]'>
                    <TasksScreenV2SearchContainer ref={searchRef} field={'pModalSearch'} />
                    {showLoading && (
                        <FetchingSpinner className={cn('opacity-0', isLoading && 'opacity-100')} isFetching={true} />
                    )}
                </div>
                {children}
            </div>
            {activeFilters && <ActiveFilters activeFilters={activeFilters} />}
        </div>
    )
}
