import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'
import Email from 'emails/Email'
import { EmailInit } from 'emails/email.types'
import { cn, sortByAlphanumeric } from 'helpers'
import { useGetEmailAttachments } from 'orderHubScreen/api/useQueries'
import { TaskLatestActivity } from 'orders/components/TaskLatestActivity'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TaskPublicTimelineEventV2 from 'tasks/components/TaskPublicTimelineEventV2'
import { TaskTimelineEventV2 } from 'tasks/tasks.types'
import { TaskV2 } from 'tasksV2/constants/tasks'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import CompanyNewNoteField from '../CompanyNewNoteField'
import BasicEvent from '../listItems/BasicEvent'
import CompanyNoteV3 from '../listItems/CompanyNoteV3'
import IncomingEmail from '../listItems/IncomingEmail'

type EventsProps = {
    events: (TaskTimelineEventV2 | EmailInit)[]
    tasks: TaskV2[]
    company: any
    eventsLoaded?: any
}

export function CompanyEvents({ events, tasks, company, eventsLoaded }: EventsProps) {
    const savedActivityFilter: any =
        localStorage?.getItem('procurementEventsFilter') !== null
            ? localStorage?.getItem('procurementEventsFilter')?.split(',')
            : ['edit', 'note', 'task', 'email']
    const [activity, setActivity] = useState<string[]>(savedActivityFilter)
    const [sortedEvents, setSortedEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])
    const location = useLocation()

    ///fix this
    // I dont think this matters for procurement currently
    const origin = location.pathname.toLowerCase().includes('draft-orders') ? 'draft_order' : 'order'
    const emailAttachmentsQuery = useGetEmailAttachments(company?.id?.toString(), origin)
    const { attachments: emailAttachments } = emailAttachmentsQuery?.data || {}
    ///fix this

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const typeChecker: any = {
        edit: {
            check: (e: EmailInit) => isEdit(e),
        },
        duplicate: {
            check: (e: EmailInit) => isDuplicate(e),
        },
        note: {
            check: (e: EmailInit) => isNote(e),
        },
        email: {
            check: (e: EmailInit) => isEmail(e),
        },
        task: {
            check: (e: TaskTimelineEventV2) => isTaskTimelineEvent(e) || isTaskLatestActivity(e),
        },
    }

    const normalize = (e: any, created_at: Date) => {
        return { ...e, normalized_created_at: created_at }
    }

    const isTaskLatestActivity = (e: any) => e?.normalized_event_type === 'Task Events Public' && e.type == 'title'
    const isTaskTimelineEvent = (e: any) => e?.normalized_event_type === 'Task Events Public' && e.type !== 'title'
    const isEmail = (e: any) => e?.normalized_event_type === 'Email'
    const isNote = (e: any) => e?.normalized_event_type === 'note'
    const isEdit = (e: any) => e?.normalized_event_type === 'edit'
    const isDuplicate = (e: any) => e?.normalized_event_type === 'duplicate'
    useEffect(() => {
        const taskEvents = events.filter(
            (e) => isTaskTimelineEvent(e) || isTaskLatestActivity(e)
        ) as TaskTimelineEventV2[]
        const emails = events.filter((e) => isEmail(e)) as EmailInit[]
        const edits = events.filter((e) => isEdit(e)) as any[]
        const notes = events.filter((e) => isNote(e)) as any[]
        const duplicates = events.filter((e) => isDuplicate(e)) as any[]
        const normalizedEvents: any = [
            ...taskEvents.map((e) => normalize(e, new Date(e.created_at))),
            ...emails.map((e) => normalize(e, new Date(e.created_at))),
            ...notes.map((e) => normalize(e, new Date(e.created_at))),
            ...edits.map((e) => normalize(e, new Date(e.created_at))),
            ...duplicates.map((e) => normalize(e, new Date(e.created_at))),
        ]
        normalizedEvents.sort((a: any, b: any) => {
            return sortByAlphanumeric(
                a.normalized_created_at?.getTime() || Infinity,
                b.normalized_created_at?.getTime() || Infinity
            )
        })
        normalizedEvents.forEach((e: any, i: number) => (e.normalized_event_index = i + 1))
        setSortedEvents(normalizedEvents)
    }, [events])

    useEffect(() => {
        if (activity.includes('all')) setActivity(Object.keys(typeChecker))
    }, [activity])
    useEffect(() => {
        let filteredEvents = sortedEvents.filter((e) => activity.some((type: any) => typeChecker[type].check(e)))
        setFilteredEvents(filteredEvents)
    }, [sortedEvents, activity])

    function FilterOptionBubble({ option, filter, setFilter }: any) {
        return (
            <div
                className={`text-sm relative whitespace-nowrap h-fit text-black bg-grey dark:bg-darkgrey dark:text-darktext1 ${
                    filter.includes(option) && '!bg-blue text-offwhite '
                } px-2 py-1 rounded cursor-pointer`}
                onClick={() => {
                    localStorage.setItem('procurementEventsFilter', `${option},duplicate`)
                    setFilter([option, 'duplicate'])
                }}
            >
                <button className='capitalize'>{option}</button>
            </div>
        )
    }

    const timeline_eventsList: any = []
    filteredEvents.map((event) => {
        if (isTaskTimelineEvent(event)) {
            timeline_eventsList.push(event)
        }
    })
    return (
        <div className='grid gap-[8px] dark:text-offwhite'>
            <div className='max-w-[100%] grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] rounded shadow-small'>
                <CompanyNewNoteField company={company} />
                <div className='flex gap-[8px] text-[14px] items-center'>
                    <h3 className='text-[16px] font-semibold'>Activity:</h3>
                    <div className='flex flex-col gap-[4px]'>
                        <div className='flex gap-[4px] flex-wrap items-center'>
                            <button
                                key={`procurement__filter-option${'All'}${-1}`}
                                className='text-fire font-semibold border-[2px] border-fire rounded py-[2px] px-[8px]'
                                onClick={() => {
                                    localStorage.setItem('procurementEventsFilter', Object.keys(typeChecker).join(','))
                                    setActivity(Object.keys(typeChecker))
                                }}
                            >
                                All
                            </button>
                            {Object.keys(typeChecker)
                                .filter((type) => type != 'duplicate')
                                .map((o, i) => (
                                    <FilterOptionBubble
                                        key={`procurement__filter-option${o}${i}`}
                                        option={o}
                                        filter={activity}
                                        setFilter={setActivity}
                                    />
                                ))}
                        </div>
                    </div>
                    <FetchingSpinner isFetching={!company?.id || !eventsLoaded} />
                </div>
                <div
                    className={cn(
                        'grid gap-[8px] border-l-[1px] border-grey dark:border-darkgrey',
                        (!company?.id || !eventsLoaded) && 'border-none'
                    )}
                >
                    {company?.id &&
                        filteredEvents
                            .map((e: any, i) => {
                                return (
                                    <div
                                        key={`order-event__${e.normalized_event_index}-${i}`}
                                        className='flex gap-[0] items-center'
                                    >
                                        <div className='relative left-[-14px] flex items-center justify-center text-[12px] min-w-[28px] min-h-[28px] border-[4px] border-white dark:border-darkaccent bg-white dark:bg-darkaccent basis-[1]'>
                                            <div className='absolute min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent z-index-1'>
                                                <span
                                                    key={e.normalized_event_index}
                                                    className='block text-center font-semibold min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent border-[1px] border-grey dark:border-accent rounded-full px-[2px]'
                                                >
                                                    {e.normalized_event_index}
                                                </span>
                                            </div>
                                            <div className='absolute left-[20px] bg-grey dark:bg-accent h-[1px] w-[12px]' />
                                        </div>

                                        {isNote(e) && <CompanyNoteV3 index={e.normalized_event_index} event={e} />}
                                        {isTaskLatestActivity(e) && (
                                            <TaskLatestActivity index={e.normalized_event_index} event={e} />
                                        )}
                                        {isTaskTimelineEvent(e) && (
                                            <TaskPublicTimelineEventV2
                                                key={e.id}
                                                task_type={
                                                    tasks.find((t) => (t as TaskV2).id === e.task_id)?.department_id ||
                                                    ''
                                                }
                                                tasks={tasks}
                                                event={e}
                                                user={users?.find((u) => u.id === e.user_id)}
                                            />
                                        )}
                                        {isEmail(e) && e.email_type === 'Sent' && (
                                            <Email
                                                email={e}
                                                attachments={emailAttachments}
                                                isPending={emailAttachmentsQuery.isLoading}
                                            />
                                        )}
                                        {isEmail(e) && e.email_type === 'Recieved' && <IncomingEmail email={e} />}
                                        {isEdit(e) && <BasicEvent key={e.id} event={e} />}
                                        {isDuplicate(e) && (
                                            <BasicEvent key={e.id} event={e} className='text-base text-danger' />
                                        )}
                                    </div>
                                )
                            })
                            .reverse()}
                    <div className='h-[40px]'></div>
                    {!company?.id && (
                        <div className='flex gap-[0] items-center bg-lightgrey/50 h-[66vh]'>
                            <SkeletonBasic className='relative mx-auto flex items-center justify-center text-[12px] w-full h-full dark:border-darkaccent dark:bg-darkaccent basis-[1]'></SkeletonBasic>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
