import { closestCenter, DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { CheckIcon } from '@radix-ui/react-icons'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'
import { cn } from 'helpers'
import { getDateToday } from 'procurement/constants'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { allowedToPin, TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import { useGetAllDepartmentConfigs, useGetTasks } from '../api/useQueries'
import TaskListSmall from './lists/TaskListSmall'
import { V2TaskCalendarRange } from './components/V2TaskCalendar'
import { DateRange } from 'react-day-picker'
import {
    MdOutlineSignalCellularAlt,
    MdOutlineSignalCellularAlt1Bar,
    MdOutlineSignalCellularAlt2Bar,
} from 'react-icons/md'
import { ActiveFilters } from 'custom_components/component_Basics/ActiveFilters'

export default function TasksScreenSplitV2() {
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get('search') || ''
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const fromString = searchParams.get('from') || ''
    const toString = searchParams.get('to') || ''
    const [taskDueDateRange, setTaskDueDateRange] = useState<DateRange | undefined>({
        from: fromString ? new Date(fromString) : getDateToday(),
        to: toString ? new Date(toString) : undefined,
    })
    const prioritiesString = searchParams.get('priorities') || ''
    const assignedToString = searchParams.get('assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''
    const [activeId, setActiveId] = useState(null)

    const stringSplitFilterValues = localStorage.getItem('splitFilterValues')
    const [splitFilterValues, setSplitFilterValues] = useState<any>(
        stringSplitFilterValues ? JSON.parse(stringSplitFilterValues) : {}
    )
    const stringSplitTaskSetupOptions = localStorage.getItem('splitTasksSetupOptions')
    const [splitTasksSetupOptions, setSplitTasksSetupOptions] = useState(
        stringSplitTaskSetupOptions ? JSON.parse(stringSplitTaskSetupOptions) : []
    )
    const idPositions = splitTasksSetupOptions?.sort(positionSort).map((table: any) => table.id)
    const [dragging, setDragging] = useState(false)

    function positionSort(a: any, b: any) {
        return a.position - b.position
    }
    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)

    const priorityIcons = [MdOutlineSignalCellularAlt1Bar, MdOutlineSignalCellularAlt2Bar, MdOutlineSignalCellularAlt]
    const priorityOptions = TASK_PRIORITIES?.map((priority: any, index) => {
        return {
            label: priority,
            value: priority,
            icon: priorityIcons[index],
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const selectionOptions = [{ title: 'Closed', id: 'closed' }]
    const userFilterOptions = [
        { title: 'Myself', id: 'showMyself' },
        { title: 'All', id: 'showAll' },
    ]

    const handleTableSetupEdit = (tableSetup: any) => {
        if (Object.keys(tableSetup)?.length) {
            localStorage.setItem('splitTasksSetupOptions', JSON.stringify(tableSetup))
        }
    }

    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            departments?.map((department: any) => {
                searchParams.delete(`Pinned_page`)
                searchParams.delete(`${department.title}_page`)
            })
            searchParams.delete('page')
            setSearchParams(searchParams)
            localStorage.setItem('splitFilterValues', JSON.stringify(newValue))
        }
    }

    const { departments } = departmentConfigQuery?.data || {}

    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => user.type !== 'Archived')
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    const filters = {
        userIds: assignedToValues.length ? assignedToValues : splitFilterValues['showMyself'] ? [user.id] : undefined,
        priorities: priorityValues,
        statuses:
            statusValues.length > 0
                ? statusValues
                : splitFilterValues['closed']
                  ? [...TASK_STATUSES, 'Archived']
                  : [
                        ...TASK_STATUSES.filter(
                            (status) => status != 'Completed' && status != 'Archived' && status != 'Cancelled'
                        ),
                    ],
        search: searchParam,
        dateRange: taskDueDateRange,
        overdue: true,
        limit: 2000,
    }

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'priorities',
            label: 'priorities',
            options: priorityOptions,
            values: priorityValues,
        },
        {
            searchParamField: 'statuses',
            label: 'statuses',
            options: statusOptions,
            values: statusValues,
        },
        {
            searchParamField: 'assigned_to',
            label: 'users',
            options: userOptions,
            values: assignedToValues,
        },
        {
            searchParamField: 'search',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
    ]
    // ACTIVE FILTERS

    const allShown =
        splitTasksSetupOptions?.filter((setup: any) => setup?.hidden == false).length === splitTasksSetupOptions.length
    const allHidden =
        splitTasksSetupOptions?.filter((setup: any) => setup?.hidden == true).length === splitTasksSetupOptions.length

    function handleDragStart(event: any) {
        const { active } = event

        setActiveId(active.id)
    }

    function handleDragEnd(event: any) {
        const { active, over } = event

        if (active.id !== over.id) {
            const oldIndex = idPositions.indexOf(active.id)
            const newIndex = idPositions.indexOf(over.id)

            const newPositions = arrayMove(idPositions, oldIndex, newIndex)

            setSplitTasksSetupOptions((prev: any) => {
                const newValue: any = []
                newPositions.forEach((id: any, index: number) => {
                    newValue.push({ ...splitTasksSetupOptions.find((table: any) => table.id == id), position: index })
                })
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                return newValue
            })
        }
        setDragging(false)
        setActiveId(null)
    }

    useEffect(() => {
        departments?.map((department: any) => {
            searchParams.delete(`Pinned_page`)
            searchParams.delete(`${department.title}_page`)
        })
        searchParams.delete('page')
        setSearchParams(searchParams)
    }, [taskDueDateRange, searchParam])

    useEffect(() => {
        if (!Object.keys(splitFilterValues).length) {
            const defaultFilterValues = {
                completed: false,
                overdue: true,
                showAll: false,
                showMyself: true,
                grouped: false,
            }
            localStorage.setItem('splitFilterValues', JSON.stringify(defaultFilterValues))
            setSplitFilterValues(defaultFilterValues)
        }
        if (!splitTasksSetupOptions.length) {
            if (departments?.length) {
                const defaultTableSetup = []
                if (user.type.includes('Admin') || allowedToPin.includes(user.id)) {
                    defaultTableSetup.push({
                        id: -1,
                        hidden: false,
                        size: 'sm',
                        position: 0,
                    })
                }

                departments?.forEach((department: any, index: number) => {
                    defaultTableSetup.push({
                        id: department.id,
                        hidden: false,
                        size: 'sm',
                        position: index + 1,
                    })
                })
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(defaultTableSetup))
                setSplitTasksSetupOptions(defaultTableSetup)
            }
        }
    }, [departments])
    useEffect(() => {
        if (Object.keys(splitFilterValues).length && departments?.length) {
            const missingDepartments = departments?.filter((department: any) => {
                return !splitTasksSetupOptions
                    .map((option: any) => {
                        return option.id
                    })
                    .join(',')
                    .includes(department.id)
            })
            if (missingDepartments?.length) {
                const newOptions = [...splitTasksSetupOptions]
                missingDepartments.forEach((department: any) => {
                    newOptions.push({
                        id: department.id,
                        hidden: false,
                        size: 'sm',
                        position: departments.length + 2,
                    })
                })
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newOptions))
                setSplitTasksSetupOptions(newOptions)
            }
        }
    }, [splitFilterValues])

    return (
        <>
            <div className='flex justify-between mb-2'>
                <div className='flex gap-4 items-center'>
                    <div className='flex flex-col gap-1'>
                        <div className='flex justify-end gap-4'>
                            <div className='h-fit  p-1  gap-x-2 text-sm'></div>
                            <div
                                onClick={() => {
                                    setSplitTasksSetupOptions((prev: any) => {
                                        const newValue: any = []
                                        prev.forEach((item: any) => {
                                            newValue.push({
                                                ...item,
                                                hidden: false,
                                            })
                                        })
                                        handleTableSetupEdit(newValue)
                                        return newValue
                                    })
                                }}
                                className='flex  gap-1 cursor-pointer items-center'
                            >
                                <div
                                    className={cn(
                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                        allShown ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                                    )}
                                >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                </div>
                                <p>Show All</p>
                            </div>
                            <div
                                onClick={() => {
                                    setSplitTasksSetupOptions((prev: any) => {
                                        const newValue: any = []
                                        prev.forEach((item: any) => {
                                            newValue.push({
                                                ...item,
                                                hidden: true,
                                            })
                                        })
                                        handleTableSetupEdit(newValue)
                                        return newValue
                                    })
                                }}
                                className='flex  gap-1 cursor-pointer items-center'
                            >
                                <div
                                    className={cn(
                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                        allHidden
                                            ? 'bg-primary text-primary-foreground'
                                            : 'opacity-50 [&_svg]:invisible'
                                    )}
                                >
                                    <CheckIcon className={cn('h-4 w-4')} />
                                </div>
                                <p>Hide All</p>
                            </div>
                        </div>
                        <div className='border rounded-md p-1 grid grid-rows-2 grid-cols-4 gap-1'>
                            {departmentConfigQuery.isLoading &&
                                [
                                    'accounting',
                                    'development',
                                    'customer service',
                                    'admin',
                                    'procurement',
                                    'fakeDepartment',
                                    'fakeDept',
                                ]?.map((department: any) => {
                                    return (
                                        <div
                                            key={department + '-ghost'}
                                            onClick={() => {
                                                setSplitTasksSetupOptions((prev: any) => {
                                                    const newValue = [...prev]
                                                    newValue[idPositions.indexOf(department.id)].hidden =
                                                        !newValue[idPositions.indexOf(department.id)].hidden
                                                    handleTableSetupEdit(newValue)
                                                    return newValue
                                                })
                                            }}
                                            className='flex gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>

                                            <SkeletonBasic className='w-fit h-fit '>
                                                <p className='opacity-0'>{department}</p>
                                            </SkeletonBasic>
                                        </div>
                                    )
                                })}
                            {!departmentConfigQuery.isLoading && (
                                <>
                                    {(user.type.includes('Admin') || allowedToPin.includes(user.id)) && (
                                        <div
                                            onClick={() => {
                                                setSplitTasksSetupOptions((prev: any) => {
                                                    const newValue = [...prev]
                                                    newValue[idPositions.indexOf(-1)].hidden =
                                                        !newValue[idPositions.indexOf(-1)].hidden
                                                    handleTableSetupEdit(newValue)
                                                    return newValue
                                                })
                                            }}
                                            className='flex  gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    !splitTasksSetupOptions[idPositions.indexOf(-1)]?.hidden
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            <p>Pinned</p>
                                        </div>
                                    )}
                                    {departments?.length > 0 &&
                                        departments?.map((department: any) => {
                                            return (
                                                <div
                                                    key={department.id + '-selector'}
                                                    onClick={() => {
                                                        setSplitTasksSetupOptions((prev: any) => {
                                                            const newValue = [...prev]
                                                            newValue[idPositions.indexOf(department.id)].hidden =
                                                                !newValue[idPositions.indexOf(department.id)].hidden
                                                            handleTableSetupEdit(newValue)
                                                            return newValue
                                                        })
                                                    }}
                                                    className='flex gap-1  items-center cursor-pointer'
                                                >
                                                    <div
                                                        className={cn(
                                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                            !splitTasksSetupOptions[idPositions.indexOf(department.id)]
                                                                ?.hidden
                                                                ? 'bg-primary text-primary-foreground'
                                                                : 'opacity-50 [&_svg]:invisible'
                                                        )}
                                                    >
                                                        <CheckIcon className={cn('h-4 w-4')} />
                                                    </div>
                                                    <p>{department.title}</p>
                                                </div>
                                            )
                                        })}
                                </>
                            )}
                        </div>
                        <ActiveFilters hideWhenNone={true} activeFilters={activeFilters} />
                    </div>
                </div>
                <div className='flex gap-4 items-center'>
                    <div className='grid grid-cols-[auto_auto_auto_auto] grid-rows-2 gap-x-4 gap-y-1 items-center h-fit'>
                        <div className='grid grid-rows-subgrid row-span-2'>
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={statusOptions}
                                title={'Status'}
                                field={'statuses'}
                                filterValues={statusValues}
                                searchToggle={false}
                                editSearchParams={true}
                                preview={false}
                            />
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={priorityOptions}
                                title={'Priority'}
                                field={'priorities'}
                                filterValues={priorityValues}
                                searchToggle={false}
                                editSearchParams={true}
                                preview={false}
                            />
                        </div>
                        <div className='grid grid-rows-subgrid border h-fit rounded-md p-1 px-2  text-sm'>
                            {selectionOptions?.map((option: any) => {
                                return (
                                    <div
                                        key={option.id}
                                        onClick={() => {
                                            setSplitFilterValues((prev: any) => {
                                                const newValue = {
                                                    ...prev,
                                                }
                                                newValue[option.id] = !newValue[option.id]
                                                handleFilterValuesEdit(newValue)
                                                return newValue
                                            })
                                        }}
                                        className='flex gap-1 cursor-pointer items-center'
                                    >
                                        <div
                                            className={cn(
                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                splitFilterValues[option.id]
                                                    ? 'bg-primary text-primary-foreground'
                                                    : 'opacity-50 [&_svg]:invisible'
                                            )}
                                        >
                                            <CheckIcon className={cn('h-4 w-4')} />
                                        </div>
                                        <p>{option.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='grid grid-rows-subgrid row-span-2'>
                            <div className=' h-fit  p-1 flex justify-end gap-2 text-sm'>
                                {userFilterOptions?.map((option: any) => {
                                    return (
                                        <div
                                            key={option.id}
                                            onClick={() => {
                                                if (splitFilterValues[option.id]) {
                                                    return
                                                }
                                                setSplitFilterValues((prev: any) => {
                                                    const newValue = {
                                                        ...prev,
                                                    }
                                                    if (option.id === 'showAll') {
                                                        newValue['showMyself'] = false
                                                    }
                                                    if (option.id === 'showMyself') {
                                                        newValue['showAll'] = false
                                                    }
                                                    newValue[option.id] = !newValue[option.id]
                                                    handleFilterValuesEdit(newValue)
                                                    return newValue
                                                })
                                            }}
                                            className='flex gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    splitFilterValues[option.id]
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            <p>{option.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <FilterBarSelectorButton
                                variant='outline'
                                align='end'
                                setFilterValues={() => {}}
                                options={userOptions}
                                title={'Assigned To'}
                                field={'assigned_to'}
                                filterValues={assignedToValues}
                                searchToggle={true}
                                editSearchParams={true}
                                preview={false}
                            />
                        </div>
                        <div className='grid grid-rows-subgrid row-span-2 h-full min-w-[200px]'>
                            <div
                                onClick={() => {
                                    searchParams.delete('calendarPreviewUser')
                                    searchParams.delete('from')
                                    searchParams.delete('to')
                                    searchParams.delete('calendarPreviewType')
                                    setSearchParams(searchParams)
                                    return setTaskDueDateRange({ from: getDateToday() })
                                }}
                                className={cn(
                                    'text-text2 dark:text-darktext2 dark:border-darkbg2 border border-bg1 h-fit w-fit rounded-md px-1  text-sm',
                                    (taskDueDateRange?.from?.toDateString() != getDateToday().toDateString() ||
                                        taskDueDateRange.to) &&
                                        'border-lightgrey text-text1 cursor-pointer dark:text-darktext1 dark:border-lightgrey'
                                )}
                            >
                                Reset
                            </div>
                            <V2TaskCalendarRange dateRange={taskDueDateRange} setDate={setTaskDueDateRange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`grid grid-cols-2 grid-template-rows-[repeat(1,360px)] gap-3 w-full transition-all pb-4`}>
                <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                    <SortableContext
                        items={idPositions.map((id: (number | string)[]) => {
                            return { id }
                        })}
                    >
                        {departments?.length > 0 &&
                            splitTasksSetupOptions
                                ?.filter((setup: any) => !setup?.hidden)
                                ?.map((setup: any) => {
                                    const size = setup.size
                                    const foundDepartment =
                                        setup.id === -1
                                            ? user.type.includes('Admin') || allowedToPin.includes(user.id)
                                                ? { id: -1, title: 'Pinned' }
                                                : undefined
                                            : departments?.find((department: any) => department.id == setup.id)

                                    if (foundDepartment) {
                                        return (
                                            <React.Fragment key={foundDepartment.id + '-list'}>
                                                <TaskListSmall
                                                    department={foundDepartment}
                                                    filters={filters}
                                                    tableSetup={splitTasksSetupOptions}
                                                    setTableSetup={setSplitTasksSetupOptions}
                                                    position={setup.position}
                                                    dragging={dragging}
                                                    setDragging={setDragging}
                                                    size={size}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                    return <div className='hidden'></div>
                                })}
                    </SortableContext>
                </DndContext>
            </div>
        </>
    )
}
