import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomerAddressType, CustomerOrderType, CustomerType } from 'customersV2/Customers.types'
import CustomerContactInfo from 'customersV2/components/CustomerContactInfo'
import CustomerHeader from 'customersV2/components/CustomerHeader'
import CustomerAddressesList from 'customersV2/lists/CustomerAddressesList'
import CustomerOrdersList from 'customersV2/lists/CustomerOrdersList'
import { vFetch } from 'helpers'
import { parseIfJson } from 'purchaseOrdersV2/helpers'
import { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { CREATE_TASK_BUTTON_CONTENT } from 'tasksV2/constants/tasks'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'

export default function CustomerModal({ customer_id, toggleModal }: { customer_id: string; toggleModal: () => void }) {
    const user = useSelector((state: any) => state.user)
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateTaskModal = searchParams.get('showCreateTaskModal') === 'true'
    const modalRef = useRef<HTMLDivElement>(null)
    const customerQuery = useQuery({
        queryKey: ['customers', customer_id],
        queryFn: () => vFetch(`/v2/customers?id=${customer_id}`),
    })
    const customerAddressesQuery = useQuery({
        queryKey: ['customers', customer_id, 'addresses'],
        queryFn: () => vFetch(`/v2/customers/addresses?customer_id=${customer_id}`),
    })
    const customerOrdersQuery = useQuery({
        queryKey: ['customers', customer_id, 'orders'],
        queryFn: () => vFetch(`/v2/customers/orders?customer_id=${customer_id}`),
    })
    const tasksQuery = useGetTasks({ resourceTable: 'customer_tasks', resourceIds: [customer_id] }, keepPreviousData)
    const tasks = tasksQuery.data?.tasks || []
    const customerResult = customerQuery?.data?.customers ? customerQuery?.data?.customers[0] || null : null
    const customer: CustomerType | null = customerResult ? (parseIfJson(customerResult) as CustomerType) : null
    const addresses: CustomerAddressType[] = customerAddressesQuery?.data?.addresses || []
    const orders: CustomerOrderType[] = (customerOrdersQuery?.data?.orders || []).map((order: any) =>
        parseIfJson(order)
    )

    const toggleCreateTaskModal = () => {
        if (showCreateTaskModal) {
            searchParams.delete('showCreateTaskModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateTaskModal', 'true')
        setSearchParams(searchParams)
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed w-full h-full top-0 left-0 flex items-center justify-center bg-black/50 z-50'
        >
            <div className='p-6 relative rounded shadow-sm bg-white dark:bg-darkbg1 text-sm w-[95%] h-[95%] flex flex-col gap-2'>
                {customerQuery.isLoading ? (
                    <p>Loading...</p>
                ) : !customer ? (
                    <p>Customer Not Found!</p>
                ) : (
                    <>
                        <button className='absolute top-1 right-1 text-base' onClick={toggleModal}>
                            <FaTimes className='text-red dark:text-lightred' />
                        </button>
                        <CustomerHeader customer={customer} />

                        <div className='flex gap-4 min-h-0 h-2/3 border-t border-lightgrey dark:border-darkgrey pt-2'>
                            <div className='w-1/4 flex flex-col'>
                                <CustomerContactInfo customer={customer} />
                            </div>
                            <div className='w-1/2 flex flex-col'>
                                {customerOrdersQuery.isLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <CustomerOrdersList orders={orders} />
                                )}
                            </div>
                            <div className='w-1/4 flex flex-col'>
                                {customerAddressesQuery.isLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <CustomerAddressesList addresses={addresses} />
                                )}
                            </div>
                        </div>
                        <div className='min-h-0 h-1/3 overflow-auto'>
                            <div className='flex gap-2'>
                                <h3 className='text-base font-bold mb-1'>Tasks</h3>
                                <Button
                                    onClick={toggleCreateTaskModal}
                                    size='sm'
                                    variant='outline'
                                    className='h-fit p-1 leading-1'
                                >
                                    {CREATE_TASK_BUTTON_CONTENT}
                                </Button>
                            </div>
                            {tasks.length === 0 && <p>There are no tasks related to this customer</p>}
                            <div className='flex flex-col p-2'>
                                {tasks
                                    ?.filter((task: any) => task.status != 'Archived')
                                    .map((task: any) => (
                                        <TaskEditCreateModalV2 variant='wide' createEvent={false} taskId={task.id} />
                                    ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
            {showCreateTaskModal && (
                <CreateTaskModal
                    closeModal={toggleCreateTaskModal}
                    prePopulatedValues={{
                        associations: [
                            {
                                resource_name: 'user',
                                readable_name: `${user.firstName} ${user.lastName}`,
                                resource_id: user.id,
                            },
                            {
                                resource_name: 'customer',
                                readable_name: `${customer?.calculated_full_name}`,
                                resource_id: customer?.id,
                            },
                        ],
                    }}
                />
            )}
        </div>
    )
}
