import { CheckIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { SortingState, createColumnHelper } from '@tanstack/react-table'
import { ActiveFilters } from 'custom_components/component_Basics/ActiveFilters'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { procurementQueries } from 'procurement/api/procurementQueryKeys'
import {
    ProcurementContext,
    ProcurementContextType,
    formatDateDayMonthYear,
    formatDateSimple,
    getFilterBarUsersActiveFirst,
} from 'procurement/helpers'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import { FaTasks } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'
import { cn, useDebounce } from '../../../helpers'
import {
    useCompaniesQuery,
    useSearchCompanies,
    useUnfulfilledLineItemsQuery,
    useUpdateCompany,
} from '../../api/useQueries'
import {
    CompanyType,
    LEAD_TYPE_OPTIONS,
    LIFECYCLE_STATUS_OPTIONS,
    defaultCompanyFilterParams,
    defaultCompanyLogo,
} from '../../constants'
import CompanyQuickModal from '../modals/CompanyQuickModal'
import ScoreView from '../ScoreView'
import CompaniesTable from './CompaniesTable'
import { useGetPathname } from 'productSetupV2/api/useQueries'

export default function CompanyListV3({
    showListQuickModal,
    setShowListQuickModal,
}: {
    showListQuickModal: string | undefined
    setShowListQuickModal: Function
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector<any, any>((state) => state.user)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id') || '']
    const { write, read } = permissionSet?.procurement
    const { users, setSelectedCompany, setSelectedCompanyId } = useContext<ProcurementContextType>(ProcurementContext)
    const [cursorList, setCursorList] = useState<any>([0])
    const [selectedLastCursor, setSelectedLastCursor] = useState<any>(undefined)
    const archivedString = searchParams.get('archived') || ''
    const hasUnfulfilledFilter = searchParams.get('unfulfilled') || ''
    const [page, setPage] = useState(1)
    const [selectedLifecycle, setSelectedLifecycle] = useState<any>(undefined)
    const navigate = useNavigate()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [sorting, setSorting] = React.useState<SortingState>([])
    const queryClient = useQueryClient()

    const companyAssignedToString = searchParams.get('company_assigned_to') || ''
    const lifecycleString = searchParams.get('lifecycles') || ''
    const leadTypeString = searchParams.get('lead_types') || ''
    const searchParam = searchParams.get('search') || ''

    const searchRef: any = useRef()

    const archived = decodeURIComponent(archivedString) === 'true' ? true : false

    const stringGroupFilterValues = localStorage.getItem('groupFilterValues')
    const [groupFilterValues, setGroupFilterValues] = useState<any>(
        stringGroupFilterValues ? JSON.parse(stringGroupFilterValues) : {}
    )

    const lifecycleStatusValues = decodeURIComponent(lifecycleString)
        .split(',')
        .filter((v) => v)
    const leadTypeValues = decodeURIComponent(leadTypeString)
        .split(',')
        .filter((v) => v)
    const companyAssignedToValues = decodeURIComponent(companyAssignedToString)
        .split(',')
        .filter((v) => v)

    const userFilterOptions = [
        { title: 'Myself', id: 'showMyself' },
        { title: 'All', id: 'showAll' },
    ]

    const sortOption = sorting.length
        ? { field: sorting[0].id, direction: sorting[0].desc ? 'DESC' : 'ASC' }
        : undefined

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'lifecycles',
            label: 'lifecycle statuses',
            options: LIFECYCLE_STATUS_OPTIONS,
            values: lifecycleStatusValues,
        },
        {
            searchParamField: 'lead_types',
            label: 'lead types',
            options: LEAD_TYPE_OPTIONS,
            values: leadTypeValues,
        },
        {
            searchParamField: 'company_assigned_to',
            label: 'users',
            options: getFilterBarUsersActiveFirst(user, users),
            values: companyAssignedToValues,
        },
        {
            searchParamField: 'search',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
        {
            searchParamField: 'unfulfilled',
            label: 'unfulfilled',
            options: [{ label: hasUnfulfilledFilter, value: hasUnfulfilledFilter, icon: undefined }],
            values: hasUnfulfilledFilter === 'true' ? [hasUnfulfilledFilter] : [],
        },
    ]
    // ACTIVE FILTERS

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsersActiveFirst(user, users),
                title: 'Users',
                field: 'company_assigned_to',
                values: companyAssignedToValues,
                searchToggle: true,
            },
            {
                component: FilterBarSelectorButton,
                options: LEAD_TYPE_OPTIONS,
                title: 'Lead Type',
                field: 'lead_types',
                values: leadTypeValues,
                searchToggle: false,
            },
            {
                component: FilterBarSelectorButton,
                options: LIFECYCLE_STATUS_OPTIONS,
                title: 'Life Cycle Status',
                field: 'lifecycles',
                values: lifecycleStatusValues,
                searchToggle: false,
            },
        ],
        setFunction: () => {},
        resetFunction: () => {},
    }

    useEffect(() => {
        setSelectedLastCursor(undefined)
        setCursorList([0])
        setPage(1)
    }, [companyAssignedToString, lifecycleString, leadTypeString, searchParam, sorting])

    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('groupFilterValues', JSON.stringify(newValue))
        }
    }
    const unfulfilledSortDesc = (a: any, b: any) => {
        return b.unfulfilled_line_items_count - a.unfulfilled_line_items_count
    }

    const unfulfilledLineItemsQuery = useUnfulfilledLineItemsQuery(
        undefined,
        undefined,
        hasUnfulfilledFilter === 'true'
    )
    const { unfulfilledLineItems } = unfulfilledLineItemsQuery?.data || {}
    const unfulfilledVendorNames: string[] = Array.from(
        new Set(unfulfilledLineItems?.filter((v: any) => v.vendor)?.map((lineItem: any) => lineItem.vendor))
    )

    const filters = {
        ...defaultCompanyFilterParams,
        lastCursor: selectedLastCursor || undefined,
        assigned_to: companyAssignedToValues?.length
            ? companyAssignedToValues
            : groupFilterValues['showMyself']
              ? [user.id]
              : [],
        lead_type: leadTypeValues,
        lifecycle_status: lifecycleStatusValues,
        pageParam: page,
        sortOption: sorting.length ? sortOption : undefined,
        archived,
        limit: hasUnfulfilledFilter === 'true' ? 1000 : 100,
        unfulfilled: hasUnfulfilledFilter === 'true' ? true : false,
        unfulfilledVendorNames: hasUnfulfilledFilter === 'true' ? unfulfilledVendorNames : undefined,
    }

    const companiesQuery = useCompaniesQuery({ filters, search: searchParam.replaceAll('"', '') })
    const { isLoading: loading, isFetching: fetching } = companiesQuery
    const { companies: companiesNoSearch, lastCursor } = companiesQuery?.data || {}

    const companiesSearchQuery = useSearchCompanies({ filters, search: searchParam.replaceAll('"', '') })
    const { isLoading: loadingSearch, isFetching: fetchingSearch } = companiesSearchQuery
    const { companies: companiesSearch, lastCursor: lastCursorSearch } = companiesSearchQuery?.data || {}

    const updateCompany = useUpdateCompany()

    const companies = searchParam.replaceAll('"', '').length ? companiesSearch : companiesNoSearch
    companies?.forEach((company: any) => {
        const relatedUnfulfilledLineItems = unfulfilledLineItems
            ? unfulfilledLineItems
                  ?.filter((v: any) => v.vendor)
                  ?.filter(
                      (lineItem: any) => lineItem?.vendor?.toLowerCase() === company?.shopify_vendor_name?.toLowerCase()
                  )
            : []
        company.unfulfilled_line_items_count = relatedUnfulfilledLineItems.length
    })
    const sortedCompanies =
        hasUnfulfilledFilter === 'true'
            ? [companies]
                  .flat()
                  .sort(unfulfilledSortDesc)
                  .filter((v) => v)
            : companies

    const currentCursor = searchParam.length ? lastCursorSearch : lastCursor
    const isFetching = searchParam.length ? fetchingSearch : fetching
    const isLoading = searchParam.length ? loadingSearch : loading

    if (!isFetching && !isLoading) {
        if (currentCursor && !(currentCursor[2] === null)) {
            if (!cursorList.includes(currentCursor)) {
                setCursorList((prev: any[]) => {
                    return [...prev, currentCursor]
                })
            }
        }
    }

    function handlePageIncrease() {
        if (!(currentCursor[2] === null)) {
            setSelectedLastCursor(currentCursor)
            setPage((prev: any) => {
                return prev + 1
            })
        }
    }
    function handlePageDecrease() {
        setSelectedLastCursor(cursorList[page - 2] || undefined)
        setPage((prev: any) => Math.max(prev - 1, 1))
    }

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }
    const data = sortedCompanies?.length > 0 ? sortedCompanies : []
    const dataMemo = React.useMemo(() => data ?? [], [data])
    const columnHelper = createColumnHelper<CompanyType>()

    const columns = [
        {
            id: 'logo',
            header: '',
            cell: (row: any) => {
                const company = row.row.original
                return (
                    <div className=''>
                        <img
                            className='p-[4px] bg-lightgrey dark:bg-darkness w-[48px] h-[48px] object-contain rounded-full'
                            src={company.logo || defaultCompanyLogo}
                        />
                    </div>
                )
            },
            size: 56,
        },
        columnHelper.accessor('name', {
            header: 'Name',
            cell: (info) => {
                const company = info.row.original
                return (
                    <div className='relative w-full'>
                        <button className='font-bold  text-left w-full'>
                            {company.name}
                            <p className='font-normal'>{company.phone}</p>
                        </button>
                    </div>
                )
            },
            size: 350,
        }),
        hasUnfulfilledFilter === 'true'
            ? {
                  header: 'Unfulfilled',
                  cell: (row: any) => {
                      const company = row.row.original
                      return (
                          <div className='flex gap-1 items-center'>
                              {company.unfulfilled_line_items_count > 0 && (
                                  <>
                                      <ExclamationTriangleIcon className='text-danger shrink-0' />
                                      <p>
                                          {company.unfulfilled_line_items_count > 0 &&
                                              company.unfulfilled_line_items_count}
                                      </p>
                                  </>
                              )}
                          </div>
                      )
                  },
                  size: 80,
              }
            : null,

        {
            id: 'listWarning',
            header: '',
            cell: (row: any) => {
                const company = row.row.original
                return (
                    <div
                        className={cn(
                            'group relative z-[5] opacity-0',
                            (company.lifecycle_status === 'Listed' || company.lifecycle_status === 'Listing') &&
                                !company?.shopify_vendor_name &&
                                'opacity-100'
                        )}
                    >
                        <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                        <div className='hidden group-hover:flex border absolute top-[100%] left-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                            Company listed but has no shopify vendor link
                        </div>
                    </div>
                )
            },
            size: 20,
        },
        columnHelper.accessor('overall_score_v2', {
            header: 'Score',
            cell: (row: any) => {
                const company = row.row.original
                return (
                    <React.Fragment key={company.id + 'Score'}>
                        <ScoreView company={company} />
                    </React.Fragment>
                )
            },
            size: 60,
        }),
        columnHelper.accessor('lead_type', {
            header: 'Lead Type',
            enableSorting: false,
            cell: (info) => {
                const company = info.row.original
                return (
                    <div
                        onClick={(e) => {
                            if (write) {
                                return e.stopPropagation()
                            }
                        }}
                        className='w-full h-full flex flex-col gap-[4px]'
                    >
                        <select
                            disabled={updateCompany.isPending}
                            value={company.lead_type}
                            name='lead_type'
                            onChange={({ target }) => {
                                const newValue = target.value
                                if (newValue.includes('Follow Up')) {
                                    setSelectedCompany(company)
                                    setShowListQuickModal('task')
                                }
                                updateCompany.mutateAsync({
                                    id: company.id,
                                    editableFields: { lead_type: target.value },
                                })
                            }}
                            className={cn(
                                'cursor-pointer  w-full h-full block  leading-1 focus:outline-none bg-transparent',
                                !write && 'pointer-events-none'
                            )}
                        >
                            {LEAD_TYPE_OPTIONS.map((leadType) => (
                                <option key={leadType.label} className='dark:bg-darkness/90' value={leadType.value}>
                                    {leadType.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )
            },
            size: 200,
        }),
        columnHelper.accessor('lifecycle_status', {
            header: 'Lifecycle Status',
            enableSorting: false,
            cell: (info) => {
                const company = info.row.original
                return (
                    <div
                        onClick={(e) => {
                            if (write) {
                                return e.stopPropagation()
                            }
                        }}
                        className='w-full h-full  flex flex-col gap-[4px]'
                    >
                        <select
                            disabled={updateCompany.isPending}
                            value={company.lifecycle_status}
                            name='lifecycle_status'
                            onChange={({ target }) => {
                                const newValue = target.value
                                if (newValue === 'Listing' || newValue === 'Listed') {
                                    setSelectedCompany(company)
                                    setSelectedCompanyId(company.id)
                                    setSelectedLifecycle(newValue)
                                    setShowListQuickModal('listing')
                                    return
                                }
                                return updateCompany.mutate({
                                    id: company.id,
                                    editableFields: { lifecycle_status: target.value },
                                })
                            }}
                            className={cn(
                                'cursor-pointer  w-full h-full block  leading-1 focus:outline-none bg-transparent',
                                !write && 'pointer-events-none'
                            )}
                        >
                            {LIFECYCLE_STATUS_OPTIONS.map((stage) => (
                                <option key={stage.label} className='dark:bg-darkness/90' value={stage.value}>
                                    {stage.label}
                                </option>
                            ))}
                        </select>
                    </div>
                )
            },
            size: 240,
        }),
        {
            header: 'Latest Note',
            cell: (row: any) => {
                const company = row.row.original
                return (
                    <div
                        onClick={(e) => {
                            if (write) {
                                setSelectedCompany(company)
                                setShowListQuickModal('note')
                                e.stopPropagation()
                                return
                            }
                        }}
                        key={`${company.id}` + 'note'}
                        className='relative group w-full'
                    >
                        <textarea
                            className={` cursor-pointer w-full !h-full p-0 text-[14px] leading-1 bg-transparent focus:outline-none dark:text-white  border-0 rounded-[4px]`}
                            value={truncate(company.latest_note || '', 100)}
                            readOnly={true}
                        ></textarea>
                        {company.latest_note?.length > 55 && (
                            <div
                                key={company.id + 'note child'}
                                className='z-10 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'
                            >
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small'>
                                    {renderIconMessage(company.latest_note || '')}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: hasUnfulfilledFilter ? 340 : 400,
        },
        write
            ? {
                  header: 'Next Task',
                  cell: (row: any) => {
                      const company = row.row.original
                      return (
                          <div onClick={(e) => e.stopPropagation()} className=' text-sm '>
                              {company.task_id && (
                                  <button
                                      onClick={() => {
                                          navigate(`/tasks/${company.task_id}`)
                                      }}
                                      className='flex flex-col text-left'
                                  >
                                      <span>Task: {company.task_id}</span>
                                      <span>{formatDateSimple(new Date(company.task_due_at))}</span>
                                  </button>
                              )}
                          </div>
                      )
                  },
              }
            : null,
        columnHelper.accessor('created_at', {
            header: 'Created',
            enableSorting: false,
            cell: (info) => {
                return <div className=' '>{formatDateDayMonthYear(new Date(info.getValue()))}</div>
            },
            size: 100,
        }),
        write
            ? {
                  id: 'moreActions',
                  header: '',
                  cell: (row: any) => {
                      const company = row.row.original
                      return (
                          <div
                              onClick={(e) => e.stopPropagation()}
                              className='flex gap-[8px] w-full justify-end items-center'
                          >
                              <button
                                  onClick={() => {
                                      setSelectedCompany(company)
                                      return showListQuickModal === 'email'
                                          ? setShowListQuickModal(undefined)
                                          : setShowListQuickModal('email')
                                  }}
                                  className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                              >
                                  <MdEmail className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                                  <p className='font-medium leading-[1] uppercase text-[12px]'>Email</p>
                              </button>
                              <button
                                  onClick={() => {
                                      setShowListQuickModal(undefined)
                                      setSelectedCompany(company)
                                      toggleCreateModal()
                                  }}
                                  className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                              >
                                  <FaTasks className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                                  <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                              </button>
                          </div>
                      )
                  },
              }
            : null,
    ].filter((v) => v)

    const pathname = useGetPathname()
    const address = pathname.includes('procurement') ? 'procurement' : 'vendors'
    const handleRowClick = (company: any) => {
        setShowListQuickModal(undefined)
        queryClient.setQueryData(procurementQueries.companies.detail(company.id).queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, company: company }
            }
        })
        localStorage.setItem('lastViewedView', 'activity')
        navigate(`/${address}/${company.id}` + '?' + searchParams.toString() + (!write ? '&view=contacts' : ''))
    }
    return (
        <>
            <div className='flex justify-between items-center w-full mb-2'>
                <div className='flex flex-col gap-2 w-full'>
                    <div className='w-full flex gap-2 items-center justify-between'>
                        <div className='w-1/3 flex gap-2 items-center'>
                            <TasksScreenV2SearchContainer ref={searchRef} isLoading={isLoading} />
                            <FetchingSpinner
                                className={cn('opacity-0', (isLoading || isFetching) && 'opacity-100')}
                                isFetching={true}
                            />
                        </div>
                        <div className='flex gap-2'>
                            <div className='flex gap-2 justify-end items-center'>
                                <div className='grid grid-cols-[auto_auto_auto_auto_auto] gap-x-2 items-center h-fit'>
                                    <div className='grid grid-rows-subgrid row-span-2'></div>
                                    {companyFilterBarObject.params.length &&
                                        companyFilterBarObject.params.map((param: any) => {
                                            return (
                                                <param.component
                                                    key={param.title + param.field}
                                                    searchToggle={param.searchToggle}
                                                    title={param.title}
                                                    field={param.field}
                                                    options={param.options}
                                                    filterValues={param.values}
                                                    editSearchParams={true}
                                                    setFilterValues={() => {}}
                                                />
                                            )
                                        })}
                                    <Button
                                        disabled={companiesQuery.isLoading}
                                        onClick={() => {
                                            searchParams.set(
                                                'unfulfilled',
                                                hasUnfulfilledFilter === 'true' ? 'false' : 'true'
                                            )
                                            setSearchParams(searchParams)
                                        }}
                                        className={cn(
                                            hasUnfulfilledFilter === 'true' && 'border-accent2',
                                            'max-h-[29.5px]'
                                        )}
                                        variant={'outline'}
                                        size={'sm'}
                                    >
                                        Unfulfilled Items
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ACTIVE FILTERS */}
                    <div className='flex gap-2 items-center'>
                        <div className='border rounded h-fit dark:border-darkgrey  p-[2px] px-1 flex justify-end gap-2 text-sm'>
                            {userFilterOptions?.map((option: any) => {
                                return (
                                    <div
                                        onClick={() => {
                                            if (groupFilterValues[option.id]) {
                                                return
                                            }
                                            setGroupFilterValues((prev: any) => {
                                                const newValue = {
                                                    ...prev,
                                                }
                                                if (option.id === 'showAll') {
                                                    newValue['showMyself'] = false
                                                }
                                                if (option.id === 'showMyself') {
                                                    newValue['showAll'] = false
                                                }
                                                newValue[option.id] = !newValue[option.id]
                                                handleFilterValuesEdit(newValue)
                                                return newValue
                                            })
                                        }}
                                        className='flex gap-1 cursor-pointer items-center'
                                    >
                                        <div
                                            className={cn(
                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                groupFilterValues[option.id]
                                                    ? 'bg-primary text-primary-foreground'
                                                    : 'opacity-50 [&_svg]:invisible'
                                            )}
                                        >
                                            <CheckIcon className={cn('h-4 w-4')} />
                                        </div>
                                        <p>{option.title}</p>
                                    </div>
                                )
                            })}
                        </div>
                        <ActiveFilters activeFilters={activeFilters} />
                    </div>
                    {/* ACTIVE FILTERS */}
                </div>
            </div>

            <div className='text-sm h-[calc(100vh-215px)] min-h-0'>
                <CompaniesTable
                    columns={columns}
                    tableData={dataMemo}
                    showHeaders={true}
                    size={'auto'}
                    handleRowClick={handleRowClick}
                    sorting={sorting}
                    setSorting={setSorting}
                />

                <div
                    className={cn(
                        ' text-[14px] flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-2 py-[2px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[8px] justify-between items-center mt-[16px] rounded z-50'
                    )}
                >
                    <>
                        <div
                            className={cn('min-w-[24px] min-h-[24px]', page === 1 && 'opacity-50 pointer-events-none')}
                        >
                            <div
                                className='grid border-[1px] border-darkgrey dark:border-accent w-[24px] h-[24px] rounded items-center justify-center cursor-pointer'
                                onClick={handlePageDecrease}
                            >
                                <BiLeftArrow className='fill-darkgrey dark:fill-accent' />
                            </div>
                        </div>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <div
                            className={cn(
                                'min-w-[24px] min-h-[24px]',
                                isLoading || isFetching || (companies?.length < 25 && 'opacity-50 pointer-events-none')
                            )}
                        >
                            <div
                                className='grid border-[1px] border-darkgrey dark:border-accent w-[24px] h-[24px] rounded items-center justify-center cursor-pointer'
                                onClick={handlePageIncrease}
                            >
                                <BiRightArrow className='fill-darkgrey dark:fill-accent' />
                            </div>
                        </div>
                    </>
                </div>

                {showListQuickModal && (
                    <CompanyQuickModal
                        selectedLifecycle={selectedLifecycle}
                        showQuickModal={showListQuickModal}
                        setShowQuickModal={setShowListQuickModal}
                    />
                )}
            </div>
        </>
    )
}
