import { Link } from 'react-router-dom'
import { useGetCoreWebVitals } from './api/useQueries'
import { createColumnHelper } from '@tanstack/react-table'
import CoreWebVitalsTable from './lists/CoreWebVitalsTable'

export type CoreWebVitalsRow = {
    page: string
    desktop_cls_rating: string | null
    desktop_ttfb_rating: string | null
    desktop_fcp_rating: string | null
    desktop_inp_rating: string | null
    desktop_lcp_rating: string | null
    mobile_cls_rating: string | null
    mobile_ttfb_rating: string | null
    mobile_fcp_rating: string | null
    mobile_inp_rating: string | null
    mobile_lcp_rating: string | null
}

export default function CoreWebVitalsScreen() {
    const coreWebVitalsQuery = useGetCoreWebVitals({ limit: '999999999' })
    const coreWebVitals: any[] = coreWebVitalsQuery.data?.coreWebVitals || []
    const desktopFailing = []
    const mobileFailing = []
    const passing = []
    for (const page of coreWebVitals) {
        if (
            (page.desktop_cls_rating && page.desktop_cls_rating !== 'FAST') ||
            (page.desktop_inp_rating && page.desktop_inp_rating !== 'FAST') ||
            (page.desktop_lcp_rating && page.desktop_lcp_rating !== 'FAST')
        ) {
            desktopFailing.push(page)
        }
        if (
            (page.mobile_cls_rating && page.mobile_cls_rating !== 'FAST') ||
            (page.mobile_inp_rating && page.mobile_inp_rating !== 'FAST') ||
            (page.mobile_lcp_rating && page.mobile_lcp_rating !== 'FAST')
        ) {
            mobileFailing.push(page)
        }
        if (
            (!page.desktop_cls_rating || page.desktop_cls_rating === 'FAST') &&
            (!page.desktop_inp_rating || page.desktop_inp_rating === 'FAST') &&
            (!page.desktop_lcp_rating || page.desktop_lcp_rating === 'FAST') &&
            (!page.mobile_cls_rating || page.mobile_cls_rating === 'FAST') &&
            (!page.mobile_inp_rating || page.mobile_inp_rating === 'FAST') &&
            (!page.mobile_lcp_rating || page.mobile_lcp_rating === 'FAST')
        ) {
            passing.push(page)
        }
    }

    const columnHelper = createColumnHelper<CoreWebVitalsRow>()

    const desktopColumns = [
        columnHelper.accessor('page', {
            header: 'Page',
            cell: (info) => {
                return (
                    <Link
                        to={`https://pagespeed.web.dev/analysis?url=${encodeURIComponent(info.getValue())}`}
                        target='_blank'
                        className='break-all'
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 400,
        }),
        columnHelper.accessor('desktop_cls_rating', {
            header: 'CLS',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('desktop_inp_rating', {
            header: 'INP',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('desktop_lcp_rating', {
            header: 'LCP',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('desktop_fcp_rating', {
            header: 'FCP',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('desktop_ttfb_rating', {
            header: 'TTFB',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
    ]

    const mobileColumns = [
        columnHelper.accessor('page', {
            header: 'Page',
            cell: (info) => {
                return (
                    <Link
                        to={`https://pagespeed.web.dev/analysis?url=${encodeURIComponent(info.getValue())}`}
                        target='_blank'
                        className='break-all'
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 400,
        }),
        columnHelper.accessor('mobile_cls_rating', {
            header: 'CLS',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('mobile_inp_rating', {
            header: 'INP',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('mobile_lcp_rating', {
            header: 'LCP',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('mobile_fcp_rating', {
            header: 'FCP',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
        columnHelper.accessor('mobile_ttfb_rating', {
            header: 'TTFB',
            cell: (info) => {
                return <CWVRating rating={info.getValue()} />
            },
        }),
    ]

    const passingColumns = [
        columnHelper.accessor('page', {
            header: 'Page',
            cell: (info) => {
                return (
                    <Link
                        to={`https://pagespeed.web.dev/analysis?url=${encodeURIComponent(info.getValue())}`}
                        target='_blank'
                        className='break-all'
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 400,
        }),
        columnHelper.accessor('mobile_cls_rating', {
            header: 'CLS',
            cell: (info) => {
                const { desktop_cls_rating, mobile_cls_rating } = info?.row?.original || {}
                return (
                    <div>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Desktop</span>
                            <CWVRating rating={desktop_cls_rating} />
                        </p>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Mobile</span>
                            <CWVRating rating={mobile_cls_rating} />
                        </p>
                    </div>
                )
            },
        }),
        columnHelper.accessor('mobile_inp_rating', {
            header: 'INP',
            cell: (info) => {
                const { desktop_inp_rating, mobile_inp_rating } = info?.row?.original || {}
                return (
                    <div>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Desktop</span>
                            <CWVRating rating={desktop_inp_rating} />
                        </p>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Mobile</span>
                            <CWVRating rating={mobile_inp_rating} />
                        </p>
                    </div>
                )
            },
        }),
        columnHelper.accessor('mobile_lcp_rating', {
            header: 'LCP',
            cell: (info) => {
                const { desktop_lcp_rating, mobile_lcp_rating } = info?.row?.original || {}
                return (
                    <div>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Desktop</span>
                            <CWVRating rating={desktop_lcp_rating} />
                        </p>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Mobile</span>
                            <CWVRating rating={mobile_lcp_rating} />
                        </p>
                    </div>
                )
            },
        }),
        columnHelper.accessor('mobile_fcp_rating', {
            header: 'FCP',
            cell: (info) => {
                const { desktop_fcp_rating, mobile_fcp_rating } = info?.row?.original || {}
                return (
                    <div>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Desktop</span>
                            <CWVRating rating={desktop_fcp_rating} />
                        </p>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Mobile</span>
                            <CWVRating rating={mobile_fcp_rating} />
                        </p>
                    </div>
                )
            },
        }),
        columnHelper.accessor('mobile_ttfb_rating', {
            header: 'TTFB',
            cell: (info) => {
                const { desktop_ttfb_rating, mobile_ttfb_rating } = info?.row?.original || {}
                return (
                    <div>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Desktop</span>
                            <CWVRating rating={desktop_ttfb_rating} />
                        </p>
                        <p className='grid grid-cols-2 gap-2 w-full'>
                            <span>Mobile</span>
                            <CWVRating rating={mobile_ttfb_rating} />
                        </p>
                    </div>
                )
            },
        }),
    ]

    return (
        <div className='text-sm'>
            <div className='flex justify-between mb-4'>
                <h1 className='text-2xl font-semibold'>Core Web Vitals</h1>
            </div>
            <div className='border rounded border-lightgrey pt-2 mb-2'>
                <h2 className='font-bold mb-2 ml-2'>Desktop Failing</h2>
                <div className='h-[300px] overflow-auto'>
                    <CoreWebVitalsTable
                        handleRowClick={() => {}}
                        columns={desktopColumns}
                        tableData={desktopFailing}
                        showHeaders={true}
                        size={'auto'}
                    />
                </div>
            </div>
            <div className='border rounded border-lightgrey pt-2 mb-2'>
                <h2 className='font-bold mb-2 ml-2'>Mobile Failing</h2>
                <div className='h-[300px] overflow-auto'>
                    <CoreWebVitalsTable
                        handleRowClick={() => {}}
                        columns={mobileColumns}
                        tableData={mobileFailing}
                        showHeaders={true}
                        size={'auto'}
                    />
                </div>
            </div>
            <div className='border rounded border-lightgrey pt-2 mb-2'>
                <h2 className='font-bold mb-2 ml-2'>Passing</h2>
                <div className='h-[500px] overflow-auto'>
                    <CoreWebVitalsTable
                        handleRowClick={() => {}}
                        columns={passingColumns}
                        tableData={passing}
                        showHeaders={true}
                        size={'auto'}
                    />
                </div>
            </div>
        </div>
    )
}

function CWVRating({ rating }: { rating?: string | null }) {
    if (!rating) {
        return <span>N/A</span>
    }
    if (rating === 'FAST') {
        return <span className='font-bold text-lime'>Pass</span>
    }
    if (rating === 'AVERAGE') {
        return <span className='font-bold text-fire'>Improve</span>
    }
    return <span className='font-bold text-red'>Poor</span>
}
