import { createColumnHelper } from '@tanstack/react-table'
import ProductsTable from 'productSetupV2/ProductsTable'
import React from 'react'

export type VariantInfo = {
    id: string | number
    company_id?: number | string
    vendor?: string
    title: string
    status?: string
    sku?: string
    product_type?: string
    has_identifying_info?: boolean
    featured_image?: string
}
export type VariantInfo2 = {
    admin_graphql_api_id: string
    custom_ignore_auto_updates: number | undefined
    fulfillment_service: string
    id: number | undefined
    inventory_item_id: number | undefined
    tracks_inventory: 1 | 0
    inventory_policy: string
    inventory_quantity: number | undefined
    product_id: number | undefined
    sku: string
    store_id: string
    title: string
}
export default function LeadTimeVariantSelector({
    setSelectedVariant,
    variants,
}: {
    setSelectedVariant: any
    variants: VariantInfo2[]
}) {
    const products = variants

    const tableData = products?.length > 0 ? products : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<VariantInfo2>()

    const productsColumns = [
        columnHelper.accessor('title', {
            minSize: 600,
            header: 'Title',
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedVariant(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer '
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),

        columnHelper.accessor('sku', {
            header: 'Sku',
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedVariant(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer'
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),

        columnHelper.accessor('id', {
            header: 'Id',
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedVariant(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer'
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
    ]

    const columnCount = productsColumns?.length.toString() || 10

    return (
        <>
            <div>
                <>
                    <div className='w-full'>
                        <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness max-h-[300px] overflow-scroll'>
                            <ProductsTable
                                columns={productsColumns}
                                tableData={tableDataMemo}
                                columnCount={columnCount}
                            />
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}
