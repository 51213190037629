import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'
import * as auditService from '../../audit/api/service'
import { CompanyFilters, CompanyTaskFilters } from '../Procurements.types'
import { UserInit } from '../../users/users.types'

// queries/companies.ts

export const companies = createQueryKeys('companies', {
    productTypes: () => ({
        queryKey: ['types'],
        queryFn: () => service.getProductTypes(),
    }),
    shopifyVendors: () => ({
        queryKey: ['vendors'],
        queryFn: () => service.getShopifyVendors(),
    }),
    companyNames: () => ({
        queryKey: ['vendors'],
        queryFn: () => service.getCompanyNames(),
    }),
    companySales: (vendorName) => ({
        queryKey: [vendorName],
        queryFn: () => service.getCompanySales(vendorName),
    }),
    companySalesList: (selectedStartDate: Date | undefined) => ({
        queryKey: [selectedStartDate],
        queryFn: () => service.getCompanySalesList(selectedStartDate),
    }),
    groupNames: () => ({
        queryKey: ['groupNames'],
        queryFn: () => service.getShopifyGroupNames(),
    }),
    groupOptions: (customGroup: string) => ({
        queryKey: [customGroup],
        queryFn: () => service.getShopifyGroupOptions(customGroup),
    }),
    contactTypes: () => ({
        queryKey: ['contactTypes'],
        queryFn: () => service.getContactTypes(),
    }),
    productAuditHistory: (table, record_id, filters) => ({
        queryKey: [table, record_id, filters],
        queryFn: () => {
            return auditService.getAuditHistory(table, record_id, filters)
        },
    }),
    shopifySkus: () => ({
        queryKey: ['shopify_skus'],
        queryFn: () => service.getShopifySkus(),
    }),
    unfulfilledLineItems: (vendor: string | undefined) => ({
        queryKey: [vendor],
        queryFn: () => service.getUnfulfilledLineItems(vendor),
    }),
    product: (id) => ({
        queryKey: [id],
        queryFn: () => service.getProduct(id),
    }),
    options: (productId) => ({
        queryKey: [productId],
        queryFn: () => service.getOptions(productId),
        contextQueries: {
            optionValues: (productId) => ({
                queryKey: [productId],
                queryFn: () => service.getOptionValues(productId),
            }),
        },
    }),
    detail: (companyId: number | undefined) => ({
        queryKey: [{ companyId }],
        queryFn: () => service.getCompany({ companyId }),
        contextQueries: {
            events: (users: UserInit[], search?: string) => ({
                queryKey: [search],
                queryFn: () => service.getEvents({ companyId, users, search }),
            }),
            incomingEmails: ({
                companyEmail,
                contactList,
                search,
            }: {
                companyEmail: string
                contactList: any[]
                search?: string
            }) => ({
                queryKey: [search],
                queryFn: () => service.getIncomingEmails({ companyEmail, contactList, search }),
            }),
            tasks: (filters: CompanyTaskFilters) => ({
                queryKey: [{ filters }],
                queryFn: () => service.getTasks({ filters }),
            }),
            products: (filters?: any) => ({
                queryKey: [{ filters }],
                queryFn: () => service.getProducts(filters),
                contextQueries: {
                    alerts: () => ({
                        queryKey: ['alerts'],
                        queryFn: () => service.getAlerts({ companyId }),
                    }),
                },
            }),
            allProducts: (filters?: any) => ({
                queryKey: [{ filters }],
                queryFn: () => service.getAllProducts(filters),
            }),

            contacts: () => ({
                queryKey: ['contacts'],
                queryFn: () => service.getContacts({ companyId }),
            }),
            vendorContacts: () => ({
                queryKey: ['vendorContacts'],
                queryFn: () => service.getVendorContacts({ companyId }),
            }),
            vendorInfo: () => ({
                queryKey: ['vendorInfo'],
                queryFn: () => service.getVendorInfo({ companyId }),
            }),
            infoBlocks: () => ({
                queryKey: ['infoBlocks'],
                queryFn: () => service.getInfoBlocks({ companyId }),
            }),
            files: (filters, search) => ({
                queryKey: [filters, search],
                queryFn: () => service.getFiles({ companyId, filters, search }),
            }),
            emails: (search?: string) => ({
                queryKey: [search],
                queryFn: () => service.getEmails({ companyId, search }),
            }),
            scores: () => ({
                queryKey: ['scores'],
                queryFn: () => service.getScores({ companyId }),
            }),
        },
    }),
    scoreSetup: () => ({
        queryKey: ['groups'],
        queryFn: () => service.getScoreSetupGroups(),
        contextQueries: {
            options: () => ({
                queryKey: ['options'],
                queryFn: () => service.getScoreSetupOptions(),
            }),
        },
    }),
    list: (filters: CompanyFilters) => ({
        queryKey: [{ filters }],
        queryFn: () => service.getCompanies({ filters }),
        contextQueries: {
            search: (search: string) => ({
                queryKey: [search],
                queryFn: ({ signal }) => service.getSearchCompanies({ filters, search, signal }),
            }),
        },
    }),
    hasUnfulfilledList: (filters: CompanyFilters) => ({
        queryKey: [{ filters }],
        queryFn: () => service.getCompanies({ filters }),
    }),
    parent: (parentCompany: string) => ({
        queryKey: [{ parentCompany }],
        queryFn: () => service.getParentCompany(parentCompany),
        contextQueries: {
            events: (parentCompany, companyNameMap: any) => ({
                queryKey: ['events'],
                queryFn: () => service.getParentCompanyEvents(parentCompany, companyNameMap),
            }),
            incomingEmails: ({ contactList, companyNameMap }: { contactList: any[]; companyNameMap: any }) => ({
                queryKey: [contactList],
                queryFn: () => service.getIncomingParentCompanyEmails(contactList, companyNameMap),
            }),
            tasks: (parentCompany: string, companyNameMap: any) => ({
                queryKey: ['tasks'],
                queryFn: () => service.getParentCompanyTasks(parentCompany, companyNameMap),
            }),
            emails: (search: string, companyNameMap: any) => ({
                queryKey: ['emails'],
                queryFn: () => service.getParentCompanyEmails(parentCompany, companyNameMap, search),
            }),
            contacts: (companyNameMap: any) => ({
                queryKey: ['contacts'],
                queryFn: () => service.getParentCompanyContacts(parentCompany, companyNameMap),
            }),
            files: (companyNameMap: any) => ({
                queryKey: ['files'],
                queryFn: () => service.getParentCompanyFiles(parentCompany, companyNameMap),
            }),
        },
    }),
})
export const tasks = createQueryKeys('tasks', {
    detail: (taskId: string) => [taskId],
    list: (filters: CompanyTaskFilters) => ({
        queryKey: [{ filters }],
        queryFn: () => service.getTasks({ filters }),
        contextQueries: {
            search: (search: string | undefined) => ({
                queryKey: [search],
                queryFn: () =>
                    service.getSearchTasks({
                        filters,
                        search,
                    }),
            }),
        },
    }),
    calendar: (filters: CompanyTaskFilters) => ({
        queryKey: [{ filters }],
        queryFn: () => service.getTasksCalendar({ filters }),
    }),
})

export const procurementQueries = mergeQueryKeys(companies, tasks)
