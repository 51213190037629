import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { PurchaseOrderType } from '../PurchaseOrder.types'
import CustomItemModal from './CustomItemModal'
import { cn } from 'helpers'

export default function PurchaseOrderLineItems({
    order,
    orderIndex,
    setOrders,
    products,
}: {
    order: PurchaseOrderType
    orderIndex: number
    setOrders: Dispatch<SetStateAction<PurchaseOrderType[]>>
    products: any[]
}) {
    const dropdown = useRef<HTMLDivElement>(null)
    const [showCustomItemModal, setShowCustomItemModal] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [search, setSearch] = useState('')
    const [filteredProducts, setFilteredProducts] = useState(products)

    const handleAddLineItem = (product: any) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].line_items.push({
                id: product.id,
                sku: product.sku,
                name: product.name,
                price: product.item_cost,
                title: product.title,
                vendor: product.vendor,
                quantity: 1,
                item_cost: product.item_cost,
                product_id: product.product_id,
                variant_id: product.variant_id,
                supplier_sku: product.custom_supplier_sku,
                supplier_model_number: product.supplier_model_number,
                upc: product.upc,
                product_title: product.product_title,
                variant_title: product.variant_title,
                fulfillment_status: null,
                fulfillable_quantity: 1,
            })
            return newState
        })
    }

    const handleChangeLineItem = (target: HTMLInputElement, index: number) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].line_items[index] = {
                ...newState[orderIndex].line_items[index],
                [target.name]: target.value,
            }
            return newState
        })
    }

    const handleRemoveLineItem = (index: number) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].line_items.splice(index, 1)
            return newState
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!dropdown?.current?.contains(e.target as HTMLElement)) {
                setShowDropdown(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        const filterProducts = (product: any) => {
            if (product.title && product.title.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            if (product.sku && product.sku.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        }
        setFilteredProducts(products.filter(filterProducts).slice(0, 100))
    }, [search])

    return (
        <>
            <div className='flex flex-col gap-[4px] p-[16px] dark:bg-darkaccent shadow-small rounded-[4px] w-full'>
                <div className='flex justify-between'>
                    <label className='font-bold text-[12px] dark:text-offwhite leading-[1] uppercase'>Products</label>
                    <button
                        className='dark:text-white font-bold text-[14px] leading-[1]'
                        onClick={() => setShowCustomItemModal(true)}
                    >
                        + Add Custom Item
                    </button>
                </div>
                <div className='mb-[8px] relative' ref={dropdown}>
                    <input
                        onFocus={() => setShowDropdown(true)}
                        className={`${
                            showDropdown ? 'rounded-t-[4px]' : 'rounded-[4px]'
                        } bg-darkgrey dark:bg-darkness text-white dark:text-offwhite placeholder-current font-[600] focus:outline-none p-[4px] w-full`}
                        type='text'
                        placeholder='Search'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                    {showDropdown && (
                        <div className='absolute top-[100%] w-full left-0 bg-darkgrey dark:bg-darkness shadow-small rounded-b-[4px] p-[8px] max-h-[150px] overflow-auto flex flex-col gap-[8px]'>
                            {filteredProducts.length ? (
                                <>
                                    {filteredProducts.map((product: any, index: number) => {
                                        return (
                                            <div
                                                key={product.title + index}
                                                className='text-white dark:text-offwhite font-bold flex gap-[6px] items-center cursor-pointer w-full'
                                                onClick={() => handleAddLineItem(product)}
                                            >
                                                <div
                                                    className={`w-[20px] h-[20px] rounded-[4px] border border-white dark:border-offwhite shrink-0 ${
                                                        order.line_items.find((item: any) => product.sku === item.sku)
                                                            ? 'bg-white dark:bg-offwhite'
                                                            : ''
                                                    }`}
                                                >
                                                    &nbsp;
                                                </div>
                                                <span>{product.title || product.product_title}</span>
                                            </div>
                                        )
                                    })}
                                </>
                            ) : (
                                <div className='dark:text-offwhite font-bold flex gap-[6px] items-center cursor-pointer w-full'>
                                    <span>No products matched this search.</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {order.line_items?.map((item: any, itemIndex: any) => (
                    <div
                        key={`selected-${item.title}-${itemIndex}`}
                        className='dark:text-offwhite flex gap-[8px] items-center p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px]'
                    >
                        <img
                            className='h-[64px] w-[64px] object-contain shrink-0 rounded-[4px] dark:bg-offwhite'
                            src={window.IMAGE_MAP[`gid://shopify/Product/${item.product_id}`]}
                            width='64'
                            height='64'
                        />
                        <div className='flex flex-col gap-[4px] '>
                            <p className='font-bold leading-[1]'>{item.title}</p>
                            <p className='font-bold text-grey text-[14px] leading-[1]'>SKU: {item.sku}</p>
                            {item.tracks_inventory === 1 && (
                                <p
                                    className={cn(
                                        `font-bold text-grey text-[14px] leading-[1]`,
                                        item.inventory_quantity <= 0 && 'text-red'
                                    )}
                                >
                                    Quantity On Hand: {item.inventory_quantity}
                                </p>
                            )}
                        </div>
                        <div className='flex items-center justify-end gap-[8px] w-[50%] shrink-0 ml-auto'>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Supplier SKU
                                </label>
                                <input
                                    value={item.supplier_sku}
                                    name='supplier_sku'
                                    onChange={({ target }) => handleChangeLineItem(target, itemIndex)}
                                    className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-[40px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    QTY
                                </label>
                                <input
                                    value={item.quantity}
                                    name='quantity'
                                    onChange={({ target }) => handleChangeLineItem(target, itemIndex)}
                                    className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='number'
                                />
                            </div>
                            {order.show_cost === 1 && (
                                <>
                                    <div className='flex flex-col gap-[4px] w-[100px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Price
                                        </label>
                                        <input
                                            value={parseFloat(item.item_cost)}
                                            onBlur={({ target }) =>
                                                (target.value = parseFloat(target.value).toFixed(2))
                                            }
                                            name='item_cost'
                                            onChange={({ target }) => handleChangeLineItem(target, itemIndex)}
                                            className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='number'
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-[100px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Total
                                        </label>
                                        <div className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'>
                                            {(parseFloat(item.item_cost || 0) * parseFloat(item.quantity || 0)).toFixed(
                                                2
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div
                            onClick={() => handleRemoveLineItem(itemIndex)}
                            className='text-[32px] font-bold dark:text-offwhite cursor-pointer leading-[0.5] self-start'
                        >
                            &times;
                        </div>
                    </div>
                ))}
            </div>
            {showCustomItemModal && (
                <CustomItemModal
                    handleAddLineItem={handleAddLineItem}
                    setShowCustomItemModal={setShowCustomItemModal}
                />
            )}
        </>
    )
}
