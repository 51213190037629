import { CompanyType } from 'procurement/constants'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FetchingSpinner } from '../../custom_components/FetchingSpinner'
import { cn } from '../../helpers'
import { useAddAssignment, useRemoveAssignment } from '../api/useQueries'
import { ProcurementContext, ProcurementContextType } from '../helpers'

export default function RepSelector({
    company,
    showLabel = true,
    className,
    inputClassName,
}: {
    company: CompanyType
    showLabel?: boolean
    className?: string
    inputClassName?: string
}) {
    const user = useSelector<any, any>((state) => state.user)
    const { procurementReps } = useContext<ProcurementContextType>(ProcurementContext)
    const repsRef = useRef<HTMLDivElement>(null)
    const [showReps, setShowReps] = useState(false)

    const currentAssignments = company.assigned_to?.split(/[ ,]+/) || []

    const addAssignment = useAddAssignment()
    const removeAssignment = useRemoveAssignment()

    const isPending = addAssignment.isPending || removeAssignment.isPending

    const handleChangeEvent = (rep: any) => {
        const assignmentSet = new Set(currentAssignments)
        if (assignmentSet.has(rep.user_id)) {
            assignmentSet.delete(rep.user_id)
            removeAssignment.mutate(
                { companyId: company.id, user_id: rep.id },
                {
                    onSuccess: () => {},
                }
            )
        } else {
            assignmentSet.add(rep.user_id)
            addAssignment.mutate(
                { companyId: company.id, user_id: rep.id },
                {
                    onSuccess: () => {},
                }
            )
        }
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!repsRef.current?.contains(e.target as Element)) {
                setShowReps(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div ref={repsRef} className={cn(className, 'flex flex-col gap-[4px] w-full relative')}>
            {showLabel && (
                <label className='text-[12px] uppercase font-bold leading-[1] dark:text-offwhite'>Assigned To</label>
            )}

            <input
                value={company.assigned_to || []}
                name='assigned_to'
                onClick={user.type === 'Admin' || user.type === 'SuperAdmin' ? () => setShowReps(true) : () => {}}
                readOnly
                className={
                    inputClassName ||
                    'w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkbg2 p-[4px] rounded-[4px]'
                }
            />

            {showReps && (
                <div className='absolute border rounded-[4px] top-[100%] z-20 left-0 flex flex-col bg-bg1 shadow-small dark:bg-darkbg2 w-full max-h-fit divide-y-2 divide-lightgrey dark:divide-darkgrey'>
                    {procurementReps.map((rep: any) => {
                        return (
                            <div key={rep.user_id} className='flex justify-between items-center '>
                                <button
                                    onClick={() => handleChangeEvent(rep)}
                                    disabled={isPending}
                                    className={cn(
                                        isPending && 'opacity-50',
                                        'flex items-center gap-[4px] text-left font-bold p-[8px] '
                                    )}
                                >
                                    <span
                                        className={`inline-block w-[16px] h-[16px] shrink-0 rounded-[4px] border border-darkgrey dark:border-offwhite ${
                                            currentAssignments.includes(rep.user_id)
                                                ? 'bg-darkgrey dark:bg-offwhite'
                                                : ''
                                        }`}
                                    >
                                        &nbsp;
                                    </span>
                                    {rep.first_name} {rep.last_name}
                                </button>
                                {addAssignment.variables?.user_id == rep.id && addAssignment.isPending && (
                                    <div className='self-center mr-4'>
                                        <FetchingSpinner isFetching={isPending} />
                                    </div>
                                )}
                                {removeAssignment.variables?.user_id == rep.id && removeAssignment.isPending && (
                                    <div className='self-center mr-4'>
                                        <FetchingSpinner isFetching={isPending} />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
