import { Button } from './component_Basics/Button'

export default function ConfirmDeleteModal({
    details,
    handleConfirm,
    handleCancel,
}: {
    details: string
    handleConfirm: any
    handleCancel: any
}) {
    return (
        <div id='modal-outer' className={`fixed left-0 top-0 h-[100vh] w-[100vw] bg-black/20 z-[1000]`}>
            <div className={`relative p-[32px] flex flex-col w-full h-full items-center justify-center  `}>
                <>
                    <div className='absolute'>
                        <div className='relative'>
                            <button
                                onClick={() => handleCancel()}
                                className='absolute top-[0px] right-[8px] z-index-4 text-red dark:text-lightred font-bold text-[24px] leading-[1]'
                            >
                                &times;
                            </button>
                            <div className='flex flex-col gap-[16px] p-[16px] bg-white dark:bg-darkaccent shadow-small rounded dark:border dark:border-darkgrey'>
                                <p className='mt-2'>{details}</p>

                                <div className='flex flex-col gap-2 items-center'>
                                    <p>Are you sure?</p>
                                    <div className='flex justify-between gap-4'>
                                        <Button
                                            variant={'destructive'}
                                            size={'sm'}
                                            className={' border border-lightgrey hover:bg-lightgrey'}
                                            onClick={() => handleConfirm()}
                                        >
                                            Delete
                                        </Button>
                                        <Button
                                            variant={'outline'}
                                            size={'sm'}
                                            className={''}
                                            onClick={() => handleCancel()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}
