import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

// Procurement Companies Private Events

export const procurementPrivate = createQueryKeys('privateEvents', {
    list: ({ privateEventUrl, element, id }) => ({
        queryKey: [privateEventUrl, element, id],
        queryFn: ({ signal }) => service.getPrivateEvents({ privateEventUrl, element, id, signal }),
    }),
    listV2: ({ privateEventUrl, ids }) => ({
        queryKey: [privateEventUrl, ids],
        queryFn: ({ signal }) => service.getPrivateEventsV2({ privateEventUrl, ids, signal }),
    }),
})

export const procurementPrivateEventQueries = mergeQueryKeys(procurementPrivate)
