import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { GiEasterEgg } from 'react-icons/gi'
import { useEffect, useState } from 'react'
import { setSettings } from '../redux/settings'
import { activateSeth } from '../helpers'
import { FaGraduationCap } from 'react-icons/fa'
import { MdDoubleArrow } from 'react-icons/md'

const ROUTES = [
    { label: 'Home', address: 'home' },
    {
        label: 'Orders',
        address: 'orders',
        permission_key: 'orders',
        submenu: [
            { label: 'Draft Orders', address: 'draft-orders', permission_key: 'draft_orders' },
            { label: 'Purchase Orders', address: 'purchase-orders', permission_key: 'purchase_orders' },
            { label: 'Daily Freight', address: 'daily-freight', permission_key: 'daily_freight' },
            { label: 'Suppliers', address: 'suppliers', permission_key: 'suppliers' },
            { label: 'Returns', address: 'returns', permission_key: 'returns' },
        ],
    },
    {
        label: 'Products',
        address: 'products',
        permission_key: 'products',
        submenu: [
            { label: 'Groups', address: 'product-groups', permission_key: 'groups' },
            { label: 'Price Cuts', address: 'price-cuts', permission_key: 'price_cuts' },
            { label: 'Cost And Price', address: 'cost-and-price', permission_key: 'cost_and_price' },
            { label: 'Processing Times', address: 'processing-times', permission_key: 'processing_times' },
            { label: 'Lead Times', address: 'inventory-lead-times?status=Pending', permission_key: 'lead_times' },
            { label: 'Top Movers', address: 'top-movers', permission_key: 'top_movers' },
            { label: 'Price Scrape', address: 'price-scrape', permission_key: 'price_scrape' },
            { label: 'Inventory Emails', address: 'inventory-emails', permission_key: 'inventory_emails' },
        ],
    },
    {
        label: 'Tasks',
        address: 'tasks',
        permission_key: 'tasks',
        submenu: [
            { label: 'Recurring Tasks', address: 'recurring-tasks', permission_key: 'recurring_tasks' },
            { label: 'Projects', address: 'projects', permission_key: 'projects' },
            { label: 'Roadmaps', address: 'roadmaps', permission_key: 'roadmaps' },
            { label: 'Departments', address: 'departments', permission_key: 'departments' },
        ],
    },
    {
        label: 'Customers',
        address: 'customers',
        permission_key: 'customers',
        submenu: [{ label: 'Tax Exemptions', address: 'tax-exemptions', permission_key: 'tax_exemptions' }],
    },
    {
        label: 'Vendors',
        address: 'vendors',
        permission_key: 'vendors',
    },
    {
        label: 'Procurement',
        address: 'procurement',
        permission_key: 'procurement',
        submenu: [
            { label: 'Email Templates', address: 'email-templates', permission_key: 'email_templates' },
            { label: 'Score Settings', address: 'score-settings', permission_key: 'score_settings' },
        ],
    },
    {
        label: 'Marketing',
        address: 'marketing',
        permission_key: 'marketing',
        submenu: [{ label: 'Email', address: 'email', permission_key: 'email' }],
    },
    { label: 'EDI', address: 'edi', permission_key: 'edi' },
    {
        label: 'Sales',
        address: 'sales',
        permission_key: 'sales',
        submenu: [{ label: 'Goals', address: 'goals', permission_key: 'goals' }],
    },
    {
        label: 'Analytics',
        address: 'analytics',
        permission_key: 'analytics',
        submenu: [
            { label: 'Sessions', address: 'sessions', permission_key: 'sessions' },
            { label: 'Core Web Vitals', address: 'core-web-vitals', permission_key: 'core_web_vitals' },
        ],
    },
    {
        label: 'Admin',
        address: 'admin',
        permission_key: 'admin',
        submenu: [
            { label: 'Users', address: 'users', permission_key: 'users' },
            { label: 'Roles', address: 'roles', permission_key: 'roles' },
            { label: 'Logins', address: 'logins', permission_key: 'logins' },
            { label: 'Refresh', address: 'refresh', permission_key: 'refresh' },
            { label: 'Redirects', address: 'redirects', permission_key: 'redirects' },
            { label: 'Email Templates', address: 'email-templates', permission_key: 'email_templates' },
            { label: 'Control Tower', address: 'control-tower', permission_key: 'control_tower' },
            { label: 'Cache Information', address: 'cache-information', permission_key: 'cache_information' },
        ],
    },
    {
        label: 'Development',
        address: 'development',
        permission_key: 'development',
        submenu: [
            { label: 'Tickets', address: 'tickets', permission_key: 'tickets' },
            { label: 'Changelogs', address: 'changelogs', permission_key: 'changelogs' },
            { label: 'Migrations', address: 'migrations', permission_key: 'migrations' },
            { label: 'Process List', address: 'process-list', permission_key: 'process_list' },
            { label: 'Component List', address: 'component-list', permission_key: 'component_list' },
            { label: 'Table Sync', address: 'table-sync', permission_key: 'table_sync' },
            { label: 'Inventory Sync Shops', address: 'inventory-sync-shops', permission_key: 'inventory_sync_shops' },
        ],
    },
    { label: 'Store Manager', address: 'store-manager', permission_key: 'store_manager' },
]

export default function Sidebar() {
    const [_searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user)
    const settings = useSelector((state) => state.settings)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id')]
    const [needsRefresh, setNeedsRefresh] = useState(false)
    useEffect(() => {
        if (needsRefresh) {
            setNeedsRefresh(false)
            localStorage.setItem('theme', settings.theme)
            if (settings.theme !== 'dark' && document.querySelector('html').classList.contains('dark')) {
                document.querySelector('html').classList.remove('dark')
            } else {
                document.querySelector('html').classList.add('dark')
            }
        }
    }, [needsRefresh])
    useEffect(() => {
        document.documentElement.style.setProperty(`--left-width`, settings.hidebar ? '16px' : '216px')
    }, [settings])
    const handleDarkChange = () => {
        dispatch(
            setSettings({
                theme: settings.theme === 'dark' ? '' : 'dark',
            })
        )
        setNeedsRefresh(true)
    }

    if (!permissionSet) {
        return <></>
    }
    return (
        <div
            className={`fixed w-leftWidth top-[50px] z-[30] ${
                settings.hidebar ? 'left-[-200px]' : 'left-0'
            } py-[16px] pl-[16px] pr-[16px] bg-bg1 text-text1 dark:text-darktext1 dark:bg-darkbg1 h-[calc(100vh-50px)] flex flex-col`}
        >
            <button
                className='absolute top-[16px] right-[-10px] p-[2px] rounded-full border-2 border-accent1 dark:border-darkaccent1 bg-bg1 dark:bg-darkbg1 text-accent1 dark:text-darkaccent1'
                onClick={() => dispatch(setSettings({ hidebar: !settings.hidebar }))}
            >
                <MdDoubleArrow
                    className={`w-[16px] h-[16px] transition-all duration-300 ${settings.hidebar ? '' : 'rotate-180'}`}
                />
            </button>
            <div className='overflow-auto capitalize font-semibold text-sm mb-[16px]'>
                {ROUTES.map((item) => {
                    return (
                        <>
                            {(!item.permission_key || permissionSet[item.permission_key]?.read) && (
                                <div key={item.label}>
                                    <div
                                        className={`mb-[4px]  w-full px-2 rounded-md cursor-pointer ${
                                            window.location.href.includes(`${window.location.host}/${item.address}`)
                                                ? 'font-bold text-[17px] bg-lightgrey dark:bg-darkbg2'
                                                : ''
                                        }`}
                                        onClick={() => navigate(`/${item.address}`)}
                                    >
                                        {item.label}
                                    </div>
                                    {window.location.href.includes(`${window.location.host}/${item.address}`) &&
                                        item.submenu &&
                                        item.submenu.map((subitem) => {
                                            return (
                                                <>
                                                    {(!subitem.permission_key ||
                                                        (item.permission_key &&
                                                            subitem.permission_key &&
                                                            permissionSet[item.permission_key] &&
                                                            permissionSet[item.permission_key][subitem.permission_key]
                                                                ?.read)) && (
                                                        <div
                                                            key={subitem.label}
                                                            className={`ml-2 mb-[4px] text-[14px] max-w-[90%] w-full px-2 rounded-md cursor-pointer ${
                                                                window.location.href.includes(
                                                                    `${window.location.host}/${item.address}/${subitem.address}`
                                                                )
                                                                    ? 'font-bold bg-lightgrey dark:bg-darkbg2'
                                                                    : ''
                                                            }`}
                                                            onClick={() =>
                                                                navigate(`/${item.address}/${subitem.address}`)
                                                            }
                                                        >
                                                            {subitem.label}
                                                        </div>
                                                    )}
                                                </>
                                            )
                                        })}
                                </div>
                            )}
                        </>
                    )
                })}
            </div>
            <div className='flex gap-[8px] mt-auto items-center'>
                <div className='cursor-pointer' onClick={handleDarkChange}>
                    {settings.theme === 'dark' ? (
                        <BsSunFill size={'25px'} className='fill-darkaccent1' />
                    ) : (
                        <BsFillMoonFill size={'25px'} className='fill-accent1' />
                    )}
                </div>
                <button
                    onClick={() =>
                        setSearchParams((prev) => {
                            prev.set('knowledgeScreen', 'Open')
                            return prev
                        })
                    }
                >
                    <FaGraduationCap className='w-8 h-8 text-blue dark:text-accent' />
                </button>
                <div
                    id='easter-egg'
                    className='cursor-pointer opacity-0 hover:opacity-100 transition-all'
                    onClick={() => activateSeth()}
                >
                    {settings.theme === 'dark' ? (
                        <GiEasterEgg size={'30px'} className='fill-darkaccent1' />
                    ) : (
                        <GiEasterEgg size={'30px'} className='fill-accent1' />
                    )}
                </div>
            </div>
        </div>
    )
}
