import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { useUpdateCompany } from 'procurement/api/useQueries'
import Select from 'procurement/components/Select'
import Textarea from 'procurement/components/Textarea'
import { CompanyType } from 'procurement/constants'
import { useState } from 'react'

export default function VendorAccountingInfo({ company }: { company: CompanyType }) {
    const companyUpdateMutation = useUpdateCompany()

    const [editableFields, setEditableFields] = useState<any>({})
    const selectedPaymentType = editableFields.accounting_payment_type ?? company.accounting_payment_type
    const additionDropdownType =
        selectedPaymentType === 'ach' ? 'terms' : selectedPaymentType === 'credit_card' ? 'cards' : ''

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditableFields((prev: any) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleSave = () => {
        const parsedFields = structuredClone(editableFields)
        if (selectedPaymentType === 'ach') {
            parsedFields.accounting_payment_card = null
        } else if (selectedPaymentType === 'credit_card') {
            parsedFields.accounting_payment_terms = null
        } else {
            parsedFields.accounting_payment_terms = null
            parsedFields.accounting_payment_card = null
            parsedFields.accounting_payment_type = null
        }
        companyUpdateMutation.mutate({ id: company.id, editableFields: parsedFields })
    }

    const edited = Object.keys(editableFields).length > 0

    return (
        <div className='mt-4 flex flex-col gap-2'>
            <div className='flex gap-2'>
                <Select
                    label='Payment Type'
                    id='accounting_payment_type'
                    name='accounting_payment_type'
                    value={editableFields.accounting_payment_type ?? company.accounting_payment_type}
                    onChange={handleChange}
                >
                    <option value=''>Select a type</option>
                    <option value='ach'>ACH</option>
                    <option value='credit_card'>Credit Card</option>
                </Select>
                {additionDropdownType === 'terms' && (
                    <Select
                        label='Payment Terms'
                        id='accounting_payment_terms'
                        name='accounting_payment_terms'
                        value={editableFields.accounting_payment_terms ?? company.accounting_payment_terms}
                        onChange={handleChange}
                    >
                        <option value=''>Select terms</option>
                        <option value='due_on_receipt'>Due On Receipt</option>
                        <option value='net_10'>Net 10 (2% discount)</option>
                        <option value='net_30'>Net 30</option>
                    </Select>
                )}
                {additionDropdownType === 'cards' && (
                    <Select
                        label='Payment Card'
                        id='accounting_payment_card'
                        name='accounting_payment_card'
                        value={editableFields.accounting_payment_card ?? company.accounting_payment_card}
                        onChange={handleChange}
                    >
                        <option value=''>Select Card</option>
                        <option value='chase'>Chase</option>
                        <option value='capital_one'>Capital One</option>
                    </Select>
                )}
            </div>
            <Textarea
                label='Notes'
                id='accounting_payment_notes'
                name='accounting_payment_notes'
                value={editableFields.accounting_payment_notes ?? company.accounting_payment_notes}
                onChange={handleChange}
            />
            {edited && (
                <div className='flex gap-2 items-center justify-center'>
                    {companyUpdateMutation.isPending && <FetchingSpinner isFetching={true} />}
                    <Button
                        className='w-fit'
                        disabled={companyUpdateMutation.isPending}
                        onClick={handleSave}
                        variant={'outline'}
                    >
                        Save Changes
                    </Button>
                </div>
            )}
        </div>
    )
}
