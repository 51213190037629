import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { cn, sendToastWarning } from '../../../helpers'
import LabelWithInfo from '../../../productSetupV2/components/LabelWithInfo'
import { useCompanyDetailQuery, useUpdateCompany } from '../../api/useQueries'
import { REQUIRED_LISTING_FIELDS } from '../../constants'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import Select from '../Select'
import Textarea from '../Textarea'

export default function ListingModal({
    setShowQuickModal,
    selectedLifecycle,
}: {
    setShowQuickModal: any
    selectedLifecycle: string | undefined
}) {
    const user = useSelector<any, any>((state) => state.user)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id') || '']
    const { write, read } = permissionSet?.procurement
    const { selectedCompanyId, selectedCompany, setSelectedCompany } =
        useContext<ProcurementContextType>(ProcurementContext)
    const modalRef = useRef(null)

    const companyQuery = useCompanyDetailQuery(selectedCompanyId, selectedCompany)
    const { company } = companyQuery?.data || {}

    const [editableFields, setEditableFields] = useState<any>({})

    const updateCompany = useUpdateCompany()
    const { isPending } = updateCompany

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const showCanadaShipsFrom =
        editableFields.sells_to_canada === 'Yes' ||
        (!editableFields.sells_to_canada && company.sells_to_canada === 'Yes')

    const sellsToCanadaSelectValue = ['Yes', 'No'].includes(
        editableFields.sells_to_canada ?? company.sells_to_canada ?? ''
    )
        ? editableFields.sells_to_canada ?? company.sells_to_canada
        : 'custom:'
    const acceptLogoSelectValue = ['Yes', 'No'].includes(editableFields.accept_logo ?? company.accept_logo ?? '')
        ? editableFields.accept_logo ?? company.accept_logo
        : 'custom:'
    const hasEdiSelectValue = ['Yes', 'No'].includes(editableFields.has_edi ?? company.has_edi ?? '')
        ? editableFields.has_edi ?? company.has_edi
        : 'custom:'
    const hasApiSelectValue = ['Yes', 'No'].includes(editableFields.has_api ?? company.has_api ?? '')
        ? editableFields.has_api ?? company.has_api
        : 'custom:'

    const handleCustomChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: 'custom:' + target.value,
        }))
    }

    const handleUpdate = () => {
        let requiredFields = REQUIRED_LISTING_FIELDS.concat([])

        const editedCompany = {
            ...company,
            ...editableFields,
        }
        if (editedCompany['shipping_terms'] && editedCompany['shipping_terms'] !== 'free_shipping') {
            requiredFields.push('shipping_details')
        }
        for (const field of requiredFields) {
            if (
                editedCompany[field as keyof typeof editedCompany] === null ||
                editedCompany[field as keyof typeof editedCompany] === undefined ||
                editedCompany[field as keyof typeof editedCompany] === ''
            ) {
                return sendToastWarning({ message: `Must provide ${field.replaceAll('_', ' ')}!` })
            }
        }
        updateCompany.mutate(
            {
                id: company.id,
                editableFields: { ...editableFields, lifecycle_status: selectedLifecycle },
            },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
        setShowQuickModal(undefined)
        setSelectedCompany(undefined)
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowQuickModal(undefined)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className={'fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'}
        >
            <div
                className={
                    'p-[32px] bg-white dark:bg-darkaccent min-w-[400px] rounded-[4px] shadow-small relative overflow-auto max-h-[90%] '
                }
            >
                <button
                    onClick={() => {
                        setShowQuickModal(undefined)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div>
                    <h2 className='text-darkgrey dark:text-offwhite text-[24px] font-bold uppercase leading-[1] mb-[16px]'>
                        {selectedCompany.name}
                    </h2>
                </div>
                <div className='relative z-0 flex flex-col gap-[16px] w-1/2 whitespace-nowrap'>
                    <h2 className='text-darkgrey dark:text-fire text-[18px] font-bold uppercase leading-[1] m-0 mb-[8px]'>
                        Confirm Required Fields
                    </h2>
                </div>
                <div className={cn('flex flex-col gap-2', isPending && 'opacity-50')}>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            htmlFor='has_edi'
                            info='EDI (Electronic Data Interchange) is a B2B connection that syncs inventory, prices, and more.'
                            label='Has EDI'
                        />
                        <Select
                            className={cn(
                                'w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                                !write && 'pointer-events-none'
                            )}
                            id='has_edi'
                            name='has_edi'
                            onChange={handleChange}
                            value={hasEdiSelectValue}
                        >
                            <option value='No'>No</option>
                            <option value='Yes'>Yes</option>
                            <option value='custom:'>Other</option>
                        </Select>
                        {hasEdiSelectValue === 'custom:' && (
                            <Textarea
                                className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='has_edi'
                                name='has_edi'
                                onChange={handleCustomChange}
                                value={(editableFields.has_edi ?? company.has_edi ?? '').replace('custom:', '')}
                            />
                        )}
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            htmlFor='has_api'
                            info='API (Application Programming Interface) is a system that allows communication with their system. This requires custom development but can be used to automated purchase orders, inventory, pricing.'
                            label='Has API'
                        />
                        <Select
                            className={cn(
                                'w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                                !write && 'pointer-events-none'
                            )}
                            id='has_api'
                            name='has_api'
                            onChange={handleChange}
                            value={hasApiSelectValue}
                        >
                            <option value='No'>No</option>
                            <option value='Yes'>Yes</option>
                            <option value='custom:'>Other</option>
                        </Select>
                        {hasApiSelectValue === 'custom:' && (
                            <Textarea
                                className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='has_api'
                                name='has_api'
                                onChange={handleCustomChange}
                                value={(editableFields.has_api ?? company.has_api ?? '').replace('custom:', '')}
                                readOnly={!write}
                            />
                        )}
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            htmlFor='sells_to_canada'
                            info='Whether or not the company sells products in Canada.'
                            label='Sells To Canada'
                        />
                        <Select
                            className={cn(
                                'w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                                !write && 'pointer-events-none'
                            )}
                            id='sells_to_canada'
                            name='sells_to_canada'
                            onChange={handleChange}
                            value={sellsToCanadaSelectValue}
                        >
                            <option value='No'>No</option>
                            <option value='Yes'>Yes</option>
                            <option value='custom:'>Other</option>
                        </Select>
                        {sellsToCanadaSelectValue === 'custom:' && (
                            <Textarea
                                className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='sells_to_canada'
                                name='sells_to_canada'
                                onChange={handleCustomChange}
                                value={(editableFields.sells_to_canada ?? company.sells_to_canada ?? '').replace(
                                    'custom:',
                                    ''
                                )}
                                readOnly={!write}
                            />
                        )}
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Shipping Terms
                        </label>
                        <select
                            autoComplete='off'
                            className={cn(
                                'w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                                !write && 'pointer-events-none'
                            )}
                            id='shipping_terms'
                            name='shipping_terms'
                            onChange={handleChange}
                            value={editableFields.shipping_terms ?? company.shipping_terms ?? ''}
                        >
                            <option className='dark:bg-darkness/90' value=''></option>
                            <option className='dark:bg-darkness/90' value='free_shipping'>
                                Free Shipping
                            </option>
                            <option className='dark:bg-darkness/90' value='we_ship'>
                                We ship using our account
                            </option>
                            <option className='dark:bg-darkness/90' value='they_ship'>
                                They ship using their account
                            </option>
                            <option className='dark:bg-darkness/90' value='both_ship'>
                                {'Ship from either account (see details)'}
                            </option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label
                            htmlFor='shipping_details'
                            className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                        >
                            Shipping Details
                        </label>
                        <textarea
                            id='shipping_details'
                            className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            value={editableFields.shipping_details ?? company.shipping_details}
                            name='shipping_details'
                            onChange={handleChange}
                            readOnly={!write}
                        ></textarea>
                    </div>
                </div>
                <div className='flex gap-[16px] justify-center items-center mt-[16px]'>
                    <button
                        disabled={isPending}
                        onClick={handleUpdate}
                        className='block py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                    >
                        {isPending ? 'Updating Status..' : 'Confirm Fields'}
                    </button>
                </div>
            </div>
        </div>
    )
}
