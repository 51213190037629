import Category from '../listItems/Category'
import { CategoryType, ProcurementContext, ProcurementContextType } from '../../helpers'
import { useContext, useEffect } from 'react'

export default function CategoryList({ categories }: { categories: CategoryType[] }) {
    const { getCategories } = useContext<ProcurementContextType>(ProcurementContext)
    useEffect(() => {
        getCategories()
    }, [])
    return (
        <div className='p-2'>
            {categories.map((category) => {
                return <Category key={category.id} category={category} />
            })}
        </div>
    )
}
