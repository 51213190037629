import { cn } from '../../../helpers'
import { CompanyProvider } from '../../helpers'
import ListingModal from './ListingModal'
import CreateContactV2 from './quickActions/CreateContactV2'
import CreateNoteV2 from './quickActions/CreateNoteV2'
import ExportCsv from './quickActions/ExportCsv'
import ImportContactV2 from './quickActions/ImportContactV2'
import SendEmailV2 from './quickActions/SendEmailV2'
import UploadFileV2 from './quickActions/UploadFileV2'

export default function CompanyQuickModal({
    showQuickModal,
    setShowQuickModal,
    selectedLifecycle,
    selectedLegacyContact,
}: {
    showQuickModal: any
    setShowQuickModal: any
    selectedLifecycle?: string | undefined
    selectedLegacyContact?: any
}) {
    return (
        <>
            <div
                className={cn(
                    showQuickModal === 'task' || showQuickModal === 'import' ? 'w-[725px]' : 'w-[500px]',
                    showQuickModal === 'note' ? 'overflow-visible' : 'overflow-hidden',
                    'fixed h-fit bottom-0 left-[50%] translate-x-[-50%] z-[50] bg-white shadow-small rounded-t-[4px]  dark:bg-darkaccent flex flex-col border border-darkgrey dark:border-grey border-b-0'
                )}
            >
                <button
                    onClick={() => {
                        setShowQuickModal(undefined)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                {showQuickModal === 'note' && <CreateNoteV2 setShowQuickModal={setShowQuickModal} />}
                {showQuickModal === 'email' && <SendEmailV2 setShowQuickModal={setShowQuickModal} />}
                {showQuickModal === 'import' && selectedLegacyContact && (
                    <ImportContactV2
                        selectedLegacyContact={selectedLegacyContact}
                        setShowQuickModal={setShowQuickModal}
                    />
                )}
                {showQuickModal === 'contact' && <CreateContactV2 setShowQuickModal={setShowQuickModal} />}
                {showQuickModal === 'file' && <UploadFileV2 setShowQuickModal={setShowQuickModal} />}
                {showQuickModal === 'csv_export' && <ExportCsv setShowQuickModal={setShowQuickModal} />}
            </div>
            {showQuickModal === 'listing' && (
                <CompanyProvider>
                    <ListingModal selectedLifecycle={selectedLifecycle} setShowQuickModal={setShowQuickModal} />
                </CompanyProvider>
            )}
        </>
    )
}
