import { useCreateNote } from 'procurement/api/useQueries'
import { CompanyType } from 'procurement/constants'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cn, sendToast } from '../../helpers'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

type NewNoteFieldProps = {
    company: CompanyType
}

export default function CompanyNewNoteField({ company }: NewNoteFieldProps) {
    const user = useSelector<any, any>((state) => state.user)
    const [newNote, setNewNote] = useState<string>('')

    const createNote = useCreateNote()

    useEffect(() => {
        const newNoteTextArea = document.getElementById('new_note') as HTMLElement
        const minHeight = parseInt(
            window.getComputedStyle(newNoteTextArea, null).getPropertyValue('min-height').replace('px', '')
        )

        if (newNoteTextArea.scrollHeight > minHeight) {
            newNoteTextArea.style.height = '0'
            newNoteTextArea.style.height = `${newNoteTextArea.scrollHeight + 2}px`
        }
    }, [newNote])

    function handleSubmit() {
        if (!newNote.trim()) return sendToast({ message: 'Please enter a note before clicking submit.' })

        if (company.id) {
            createNote.mutate(
                { user, note: newNote.trim(), companyId: company.id, createTask: false },
                {
                    onSuccess: () => {},
                    onSettled: () => {},
                }
            )
        }
    }

    return (
        <div className='w-[100%] dark:bg-darkaccent relative bg-white rounded'>
            <textarea
                id='new_note'
                className='order-hub-screen__notes__textarea block min-h-[39px] h-[39px] w-[100%] p-[8px] max-w-none border-[1px] border-[#cdcdcd] rounded dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue shadow-md'
                name='new_note'
                value={newNote}
                placeholder='New note...'
                onChange={({ target }: any) => setNewNote(target.value)}
            />
            <div className='flex items-center gap-2 justify-end'>
                <FetchingSpinner isFetching={true} className={cn('opacity-0', createNote.isPending && 'opacity-100')} />
                {newNote && (
                    <button
                        disabled={createNote.isPending}
                        className={cn(
                            'block relative top-0 py-[4px] px-[8px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer shadow-small',
                            createNote.isPending && 'opacity-70'
                        )}
                        onClick={handleSubmit}
                    >
                        Submit Note
                    </button>
                )}
            </div>
        </div>
    )
}
