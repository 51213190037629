import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { productSetup } from 'productSetupV2/api/productSetupQueryKeys'
import { useDeleteProductImage, useGetProductImages, useUploadProductImage } from 'productSetupV2/api/useQueries'
import { useEffect, useRef, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { useSelector } from 'react-redux'
import { sendToast, sendToastWarning, vFetch } from '../../helpers'
import { ShopifyProduct } from '../ProductSetup.types'

export default function Images({
    product,
    handleRefreshProduct,
}: {
    product: ShopifyProduct
    handleRefreshProduct: any
}) {
    const fileInputRef = useRef<any>(null)
    const user = useSelector((state: any) => state.user)
    const [files, setFiles] = useState<FileList | null>(null)
    const [expectedImagesLength, setExpectedImagesLength] = useState(undefined)
    const [draggedItem, setDraggedItem] = useState(-1)
    const [draggedOverItem, setDraggedOverItem] = useState(-1)

    const badCharacters = [
        '&',
        '@',
        ':',
        ';',
        ',',
        '$',
        '=',
        '+',
        '?',
        '\\',
        '`',
        '>',
        '<',
        '{',
        '}',
        '[',
        ']',
        '#',
        '%',
        '"',
        "'",
        '~',
        '|',
    ]

    const productImagesQuery = useGetProductImages(product.id)
    const { images } = productImagesQuery?.data || {}
    const queryClient = useQueryClient()

    useEffect(() => {
        if (expectedImagesLength && images?.length == expectedImagesLength) {
            setExpectedImagesLength(undefined)
        }
    }, [images?.length])

    const deleteImage = useDeleteProductImage()
    const uploadImage = useUploadProductImage()

    const updatePosition = useMutation({
        mutationFn: async ({ image, position }: { image: any; position: number }) => {
            return await vFetch('/v2/products/images', {
                method: 'PUT',
                body: JSON.stringify({ ...image, position, product_id: product.id }),
            })
        },
        onSuccess: (_data, variables) => {
            setDraggedItem(-1)
            setDraggedOverItem(-1)
            handleRefreshProduct(product.id)
            queryClient.invalidateQueries({ queryKey: productSetup.images(product.id).queryKey })
        },
    })
    const handleUploadImages = async () => {
        if (files) {
            const data = new FormData()
            for (const file of Array.from(files)) {
                if (!file.name[0].match(/^[A-Za-z]+$/) && !file.name[0].match(/^[0-9]+$/)) {
                    return sendToastWarning({ message: 'The first character of file name cannot be a symbol' })
                }

                if (file.name.split(' ').length > 1) {
                    return sendToastWarning({ message: 'File name cannot contain spaces' })
                }
                if (
                    badCharacters.some((v: string) => {
                        if (file.name.includes(v)) {
                            sendToastWarning({ message: `File name cannot contain '${v}'` })
                            return 1
                        }
                        return 0
                    })
                ) {
                    return
                }
                if (file.name.split('.').length > 2) {
                    return sendToastWarning({ message: 'File name cannot contain multiple periods' })
                }
                if (!['png', 'jpeg', 'gif', 'webp'].includes(file.type.split('/')[1])) {
                    return sendToastWarning({ message: 'Incorrect file type. Please select PNG, JPEG, GIF or WEBP' })
                }
                data.append('images', file, file.name)
            }

            uploadImage.mutate(
                { data, user, product_id: product.id },
                {
                    onSuccess(data, variables, context) {
                        if (fileInputRef.current) {
                            fileInputRef.current.value = null
                        }
                        setExpectedImagesLength(expectedImagesLength ? expectedImagesLength + 1 : images.length + 1)
                        setFiles(null)
                        if (data.shopify_media.length === images.length) {
                            sendToast({ message: 'Potential issue uploading image on Shopify' })
                        }
                        setTimeout(() => {
                            queryClient.invalidateQueries({
                                queryKey: productSetup.images(variables.product_id).queryKey,
                            })
                        }, 3000)
                        setTimeout(() => {
                            queryClient.invalidateQueries({
                                queryKey: productSetup.images(variables.product_id).queryKey,
                            })
                        }, 10000)
                    },
                }
            )
        }
    }
    const handleDeleteImage = (image: any) => {
        deleteImage.mutate(
            { image, product_id: product.id },
            {
                onSuccess: () => {
                    handleRefreshProduct(product.id)
                },
            }
        )
    }
    const sortedImages = images?.toSorted((a: any, b: any) => a.position - b.position)
    const handleDrop = () => {
        if (draggedItem !== draggedOverItem) {
            updatePosition.mutate({ image: sortedImages[draggedItem], position: draggedOverItem + 1 })
        }
    }

    const isPending = deleteImage.isPending || updatePosition.isPending
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
            <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                Images
            </p>
            <div className={`flex gap-4 flex-wrap ${isPending ? 'opacity-50 pointerevents-none' : ''}`}>
                {sortedImages?.map((image: any, index: number) => (
                    <div
                        onDragStart={(e) => setDraggedItem(index)}
                        onDragEnter={(e) => setDraggedOverItem(index)}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        draggable
                        key={image.id || image.media_image_gid}
                        className={`relative border border-lightgrey dark:border-darkgrey rounded-md p-2 group ${
                            index === draggedOverItem && index !== draggedItem
                                ? 'shadow-[0px_0px_10px_rgb(255,165,0)]'
                                : ''
                        } ${index === draggedItem ? 'opacity-50' : ''}`}
                    >
                        {image.position === 1 && (
                            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                                Featured
                            </p>
                        )}
                        <div className='absolute opacity-0 group-hover:opacity-100 transition-all pointer-events-none top-0 left-0 w-full h-full bg-black/25'></div>
                        <div className='opacity-0 group-hover:opacity-100 transition-all absolute top-0 left-0 rounded-br-md cursor-grab bg-lightgrey dark:bg-darkgrey'>
                            <RxDragHandleDots2 className='text-xl' />
                        </div>
                        {image.id && (
                            <img
                                className='w-[200px] h-[200px] object-contain'
                                src={image.src}
                                width='200'
                                height='200'
                                alt='Product image'
                            />
                        )}
                        <button
                            className='opacity-0 group-hover:opacity-100 transition-all text-red dark:text-lightred absolute bottom-2 right-2'
                            onClick={() => handleDeleteImage(image)}
                        >
                            <FaTrash className='w-4 h-4' />
                        </button>
                    </div>
                ))}
                {expectedImagesLength && images.length != expectedImagesLength && (
                    <div className={`relative border border-lightgrey dark:border-darkgrey rounded-md p-2 group`}>
                        <div className='absolute opacity-0 group-hover:opacity-100 transition-all pointer-events-none top-0 left-0 w-full h-full bg-black/25'></div>

                        <div className='w-[200px] h-[200px] object-contain'>
                            <div className='flex gap-2 items-center'>
                                <FetchingSpinner isFetching={true} />
                                <p>Uploading...</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <h2 className='font-medium mt-4 mb-1'>Upload New Images</h2>
            <div className='flex gap-4'>
                <div className='p-2 rounded-md border border-fire w-fit'>
                    <input
                        ref={fileInputRef}
                        type='file'
                        accept='image/*'
                        multiple
                        onChange={({ target }) => setFiles(target.files)}
                    />
                </div>
                {files?.length && (
                    <div className='flex gap-2 items-center'>
                        <Button
                            variant={'outline'}
                            size={'sm'}
                            disabled={uploadImage.isPending}
                            onClick={handleUploadImages}
                        >
                            Upload
                        </Button>
                        <FetchingSpinner isFetching={uploadImage.isPending} />
                    </div>
                )}
            </div>
        </div>
    )
}
