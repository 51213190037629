import { MdLocationPin } from 'react-icons/md'
import { FulfillmentLineItemInit, FulfillmentOrderInit, OrderInit } from '../../orders.types'
import { locationMap } from '../../../helpers'
import BottomItemInfo from './bottomItemInfo'

type UnfulfilledItemsProps = {
    order: OrderInit
    fulfillmentOrders: FulfillmentOrderInit[]
}

export default function UnfulfilledItems({ order, fulfillmentOrders }: UnfulfilledItemsProps) {
    const activeFulfillmentOrders = fulfillmentOrders.filter((f) => f.status === 'open' || f.status === 'in_progress')
    return (
        <>
            {activeFulfillmentOrders.map((f: FulfillmentOrderInit) => {
                const unfulfilledItems = f.line_items.filter((li) => li.fulfillable_quantity !== 0)
                return (
                    <div className='grid gap-[8px] bg-bg1 dark:bg-darkbg1 p-[16px] shadow-small rounded'>
                        <div className='flex justify-between'>
                            <div className='flex items-center gap-[8px] text-[14px]'>
                                <svg
                                    className='fill-[rgb(205,105,0)] dark:fill-fire stroke-[rgba(255,205,165,1)] dark:stroke-[rgba(255,185,150,0.7)] border-[3px] border-[rgba(255,205,165,1)] dark:border-[rgba(255,185,150,0.7)] rounded-full w-[26px] h-[auto]'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 17 17'
                                    role='img'
                                    focusable='false'
                                    aria-hidden='true'
                                >
                                    <circle cx='8.5' cy='8.5' r='9' strokeWidth='3px' fill='none' />
                                    <path
                                        stroke='none'
                                        d='M10.09 16.41a.8.8 0 0 1-.18-1.58 6.46 6.46 0 0 0 2-.81L12 14a.79.79 0 0 1 1.07.35.8.8 0 0 1-.3 1.05 7.89 7.89 0 0 1-2.46 1 .55.55 0 0 1-.22.01zm-3.2 0h-.18a7.89 7.89 0 0 1-2.47-1A.8.8 0 0 1 5.09 14a6.49 6.49 0 0 0 2 .82.8.8 0 0 1 .6 1 .81.81 0 0 1-.78.62zm7.7-3.18a.8.8 0 0 1-.8-.8.79.79 0 0 1 .12-.42 6.27 6.27 0 0 0 .83-2 .8.8 0 0 1 1.56.36 7.89 7.89 0 0 1-1 2.47.77.77 0 0 1-.71.39zm-12.19 0a.78.78 0 0 1-.67-.37 8.14 8.14 0 0 1-1-2.46.8.8 0 0 1 1.53-.4 6.19 6.19 0 0 0 .82 2 .8.8 0 0 1-.68 1.23zm13.12-5.4a.81.81 0 0 1-.78-.63 6.46 6.46 0 0 0-.81-2 .81.81 0 0 1 .24-1.11.79.79 0 0 1 1.1.24 8 8 0 0 1 1 2.47.8.8 0 0 1-.6 1h-.18zm-14 0a.58.58 0 0 1-.19 0 .79.79 0 0 1-.6-1 8.22 8.22 0 0 1 1-2.47.82.82 0 0 1 1.11-.26.8.8 0 0 1 .25 1.11 6.49 6.49 0 0 0-.82 2 .78.78 0 0 1-.77.62zM12.33 3.3a.83.83 0 0 1-.43-.13 6.49 6.49 0 0 0-2-.82.79.79 0 0 1-.63-.93.8.8 0 0 1 .94-.64 8.15 8.15 0 0 1 2.48 1A.8.8 0 0 1 13 2.92a.78.78 0 0 1-.68.37zm-7.65 0A.82.82 0 0 1 4 2.93a.8.8 0 0 1 .22-1.1l.1-.06a7.93 7.93 0 0 1 2.39-1 .8.8 0 0 1 1 .61.79.79 0 0 1-.6 1 6.43 6.43 0 0 0-2 .82.82.82 0 0 1-.43.12z'
                                    />
                                </svg>
                                <h2 className='font-bold text-[20px] whitespace-nowrap'>
                                    {f.status === 'open' || f.status === 'in_progress'
                                        ? 'Unfulfilled'
                                        : f.line_items.every((item) => item.quantity === 0)
                                          ? 'Removed'
                                          : 'Please tell the developers you saw this message here'}{' '}
                                    ({f.line_items.filter((item) => item.fulfillable_quantity > 0).length})
                                </h2>
                            </div>
                            <button className='relative w-[35px] h-[35px] text-grey hover:text-darkgrey dark:hover:text-offwhite'></button>
                        </div>
                        <div className='grid grid-rows-[40px] items-center gap-[16px]'>
                            <div className='grid grid-cols-[25px_1fr] gap-[10px] items-center text-[14px]'>
                                <MdLocationPin className='h-[25px] w-[auto]' />
                                <div>
                                    <h3 className='text-[12px]'>Location</h3>
                                    <span>{locationMap[f.assigned_location_id]}</span>
                                </div>
                            </div>
                        </div>

                        <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                            {unfulfilledItems.map((item: FulfillmentLineItemInit) => {
                                const oItemIndex: number = order.line_items.findIndex(
                                    (orderItem: any) => orderItem.id === item.line_item_id
                                )
                                if (oItemIndex < 0) return

                                return (
                                    <BottomItemInfo
                                        key={item.line_item_id}
                                        item={order.line_items[oItemIndex]}
                                        order={order}
                                    />
                                )
                            })}
                        </div>
                        <div className='flex p-[16px] justify-end gap-[8px] pb-[0] w-[calc(100%+32px)] relative left-[-16px] border-t-[1px] dark:border-darkgrey'>
                            <button
                                className='hover:bg-[lightgrey] dark:bg-darkaccent dark:hover:bg-darkness rounded py-[8px] px-[16px] items-center justify-center text-[14px] font-semibold dark:text-white !border-[1px] border-darkgrey'
                                onClick={() => {
                                    window.open(
                                        `https://factorypure.myshopify.com/admin/orders/${f.order_id}/fulfillment_orders/${f.id}/fulfill?locationId=${f.assigned_location_id}`,
                                        '_blank'
                                    )
                                }}
                            >
                                Fulfill Items
                            </button>
                            <button
                                className='bg-accent2 hover:bg-[#125443] dark:bg-darkaccent2 dark:hover:bg-[darkorange] rounded py-[8px] px-[16px] items-center justify-center text-[14px] text-black font-semibold !border-[1px] border-darkgrey'
                                onClick={() => {
                                    // Make this do something other than link to shopify
                                    window.open(
                                        `https://factorypure.myshopify.com/admin/orders/${f.order_id}`,
                                        '_blank'
                                    )
                                }}
                            >
                                Create Shipping Label
                            </button>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
