// Please update the backend when adding new permissions

export const DEFAULT_PERMISSION_SET = {
    orders: {
        read: true,
        draft_orders: {
            read: true,
        },
        purchase_orders: {
            read: false,
        },
        daily_freight: {
            read: false,
        },
        suppliers: {
            read: false,
        },
        returns: {
            read: true,
        },
    },
    products: {
        read: true,
        groups: {
            read: true,
        },
        price_cuts: {
            read: false,
        },
        cost_and_price: {
            read: false,
        },
        processing_times: {
            read: false,
        },
        lead_times: {
            read: false,
        },
        top_movers: {
            read: true,
        },
        price_scrape: {
            read: true,
        },
        inventory_emails: {
            read: false,
        },
    },
    tasks: {
        read: true,
        recurring_tasks: {
            read: true,
        },
        projects: {
            read: false,
        },
        roadmaps: {
            read: false,
        },
        departments: {
            read: false,
        },
    },
    customers: {
        read: true,
        tax_exemptions: {
            read: false,
            write: false,
        },
    },
    vendors: {
        read: true,
    },
    procurement: {
        read: false,
        write: false,
        email_templates: {
            read: false,
        },
        score_settings: {
            read: false,
        },
    },
    marketing: {
        read: false,
        email: {
            read: false,
        },
    },
    edi: {
        read: false,
    },
    sales: {
        read: true,
        goals: {
            read: false,
        },
    },
    admin: {
        read: false,
        users: {
            read: false,
        },
        roles: {
            read: false,
        },
        logins: {
            read: false,
        },
        refresh: {
            read: false,
        },
        redirect: {
            read: false,
        },
        email_templates: {
            read: false,
        },
        control_tower: {
            read: false,
        },
        cache_information: {
            read: false,
        },
    },
    analytics: {
        read: false,
        sessions: {
            read: false,
        },
        core_web_vitals: {
            read: false,
        },
    },
    development: {
        read: true,
        changelogs: {
            read: true,
        },
        migrations: {
            read: false,
        },
        process_list: {
            read: false,
        },
        tickets: {
            read: true,
        },
        component_list: {
            read: false,
        },
        table_sync: {
            read: false,
        },
        inventory_sync_shops: {
            read: false,
        },
    },
    store_manager: {
        read: false,
    },
}
