import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import Textarea from 'procurement/components/Textarea'
import { useCreateSupplier } from 'purchaseOrdersV2/api/useQueries'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

export default function CreateSupplierModal({ closeModal }: { closeModal: () => void }) {
    const [supplier, setSupplier] = useState<any>({})
    const createSupplierMutation = useCreateSupplier()
    const modalRef = useRef<HTMLDivElement>(null)
    const selectedPOType = supplier.po_type

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setSupplier((prev: any) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleCreate = () => {
        createSupplierMutation.mutate(supplier, { onSuccess: () => closeModal() })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (modalRef.current === event.target) {
                closeModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative p-6 max-w-[1000px] w-full max-h-[90%] bg-bg1 dark:bg-darkbg1 rounded shadow-md'>
                <button
                    onClick={() => closeModal()}
                    className='text-red dark:text-lightred font-bold text-lg absolute top-1 right-1'
                >
                    <FaTimes />
                </button>
                <h2 className='font-bold text-lg mb-2'>Create Supplier</h2>
                <div className='grid grid-cols-[1fr_400px_1fr] gap-2'>
                    <div className='flex flex-col gap-2'>
                        <Input
                            label='Name'
                            type='text'
                            id='name'
                            name='name'
                            value={supplier.name}
                            onChange={handleChange}
                        />
                        <Input
                            label='Contact Name'
                            type='text'
                            id='contact_name'
                            name='contact_name'
                            value={supplier.contact_name}
                            onChange={handleChange}
                        />
                        <Select
                            label='Status'
                            id='test_mode'
                            name='test_mode'
                            value={supplier.test_mode}
                            onChange={handleChange}
                        >
                            <option value=''>Select a status</option>
                            <option value='0'>Live</option>
                            <option value='1'>Testing</option>
                        </Select>
                        <Select
                            label='Country'
                            id='country'
                            name='country'
                            value={supplier.country}
                            onChange={handleChange}
                        >
                            <option value=''>Select a country</option>
                            <option value='US'>United States</option>
                            <option value='CA'>Canada</option>
                        </Select>
                        <Select
                            label='Currency'
                            id='currency'
                            name='currency'
                            value={supplier.currency}
                            onChange={handleChange}
                        >
                            <option value=''>Select a currency</option>
                            <option value='USD'>USD</option>
                            <option value='CAD'>CAD</option>
                        </Select>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <Select
                            label='Purchase Order Type'
                            id='po_type'
                            name='po_type'
                            value={supplier.po_type}
                            onChange={handleChange}
                        >
                            <option value=''>Select a Purchase Order type</option>
                            <option value='email'>Email</option>
                            <option value='edi'>EDI</option>
                        </Select>
                        {selectedPOType === 'email' && (
                            <>
                                <Select
                                    label='Show Cost On Purchase Order'
                                    id='show_cost'
                                    name='show_cost'
                                    value={supplier.show_cost}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select an option</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </Select>
                                <Textarea
                                    label='Email Addresses (Comma Separated)'
                                    id='email_addresses'
                                    name='email_addresses'
                                    value={supplier.email_addresses}
                                    onChange={handleChange}
                                />
                                <Select
                                    label='Send Automatic Follow Ups'
                                    id='send_automatic_follow_ups'
                                    name='send_automatic_follow_ups'
                                    value={supplier.send_automatic_follow_ups}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select an option</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </Select>
                                {supplier.send_automatic_follow_ups === '1' && (
                                    <div className='flex gap-2'>
                                        <Input
                                            type='number'
                                            label='1st Follow Up Delay (Hrs)'
                                            id='first_follow_up_delay'
                                            name='first_follow_up_delay'
                                            value={supplier.first_follow_up_delay}
                                            onChange={handleChange}
                                        />
                                        <Input
                                            type='number'
                                            label='2nd Follow Up Delay (Hrs)'
                                            id='second_follow_up_delay'
                                            name='second_follow_up_delay'
                                            value={supplier.second_follow_up_delay}
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                        {selectedPOType === 'edi' && (
                            <div className='flex gap-2'>
                                <div className='flex flex-col gap-2'>
                                    <Input
                                        label='Test EDI ISA Qualifier'
                                        type='text'
                                        id='edi_test_isa_qualifier'
                                        name='edi_test_isa_qualifier'
                                        value={supplier.edi_test_isa_qualifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Test EDI ISA Identifier'
                                        type='text'
                                        id='edi_test_isa_identifier'
                                        name='edi_test_isa_identifier'
                                        value={supplier.edi_test_isa_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Test EDI GS Identifier'
                                        type='text'
                                        id='edi_test_gs_identifier'
                                        name='edi_test_gs_identifier'
                                        value={supplier.edi_test_gs_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Test EDI AS2 Station'
                                        type='text'
                                        id='edi_test_as2_station'
                                        name='edi_test_as2_station'
                                        value={supplier.edi_test_as2_station}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <Input
                                        label='Production EDI ISA Qualifier'
                                        type='text'
                                        id='edi_isa_qualifier'
                                        name='edi_isa_qualifier'
                                        value={supplier.edi_isa_qualifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Production EDI ISA Identifier'
                                        type='text'
                                        id='edi_isa_identifier'
                                        name='edi_isa_identifier'
                                        value={supplier.edi_isa_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Production EDI GS Identifier'
                                        type='text'
                                        id='edi_gs_identifier'
                                        name='edi_gs_identifier'
                                        value={supplier.edi_gs_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Production EDI AS2 Station'
                                        type='text'
                                        id='edi_as2_station'
                                        name='edi_as2_station'
                                        value={supplier.edi_as2_station}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <Textarea
                            label='Purchase Order Default Note'
                            id='po_note'
                            name='po_note'
                            value={supplier.po_note}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <Button onClick={handleCreate} className='block mt-4 mx-auto' variant={'outline'}>
                    Create Supplier
                </Button>
            </div>
        </div>
    )
}
