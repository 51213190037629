import { createColumnHelper } from '@tanstack/react-table'
import ShopTable from './ShopTable'
import { useGetInventorySyncShops } from 'inventorySyncShops/api/useQueries'
import { useSearchParams } from 'react-router-dom'

export type InventorySyncShopRow = {
    id: number
    shop: string
    client_id: string
    has_access_token: 1 | 0
    created_at: string
    updated_at: string
    store_id: number
}

export default function ShopList() {
    const [searchParams, setSearchParams] = useSearchParams()

    const toggleEditModal = (shopId?: string) => {
        if (!shopId) {
            searchParams.delete('inventory_sync_shop')
            return setSearchParams(searchParams)
        }
        searchParams.set('inventory_sync_shop', shopId)
        return setSearchParams(searchParams)
    }
    const inventorySyncShopsQuery = useGetInventorySyncShops({})
    const inventorySyncShops = inventorySyncShopsQuery.data?.inventorySyncShops || []

    const columnHelper = createColumnHelper<InventorySyncShopRow>()

    const inventorySyncShopsColumns = [
        columnHelper.accessor('shop', {
            header: 'Shop',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            size: 300,
        }),
        columnHelper.accessor('client_id', {
            header: 'App Client ID',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            size: 300,
        }),
        columnHelper.accessor('has_access_token', {
            header: 'Has Access Token',
            cell: (info) => {
                return <p>{info.getValue() === 1 ? 'Yes' : 'No'}</p>
            },
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            size: 200,
        }),
        columnHelper.accessor('updated_at', {
            header: 'Last Updated',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            size: 200,
        }),
    ]

    return (
        <div className='h-full overflow-auto'>
            <ShopTable
                handleRowClick={toggleEditModal}
                columns={inventorySyncShopsColumns}
                tableData={inventorySyncShops}
                showHeaders={true}
                size={'auto'}
            />
        </div>
    )
}
