import { useQueryClient } from '@tanstack/react-query'
import queryKeys from 'tasksV2/api/queryKeys'
import { UserInit } from 'users/users.types'

export function formatReadableExemption(exemption: string) {
    try {
        const stringArr = exemption.split('_')
        const [country, province] = stringArr
        if (province.length > 2) {
            return `${country} ${stringArr
                .slice(1)
                .map((word) => word[0] + word.slice(1).toLowerCase())
                .join(' ')}`
        }
        return `${country} ${province} ${stringArr
            .slice(2)
            .map((word) => word[0] + word.slice(1).toLowerCase())
            .join(' ')}`
    } catch {
        return exemption
    }
}

/**
 * Gets the dashboard link for the provided task association
 *
 * If you update this, please update the backend version as well :sunglasses:
 *
 * @param association
 * @param user
 * @returns
 */
export const getAssociationLink = (
    association: { resource_name: string; resource_id: number; readable_name: string },
    user: UserInit,
    queryClient: any,
    searchParams?: any
) => {
    let link = `/${association.resource_name}/${association.resource_id}`
    switch (association.resource_name) {
        case 'order':
            link = `/orders/${association.resource_id}`
            break
        case 'order_line_item':
            link = queryClient.getQueryData(
                queryKeys.associations.orderLineItems(association.resource_id.toString(), undefined).queryKey
            )?.lineItems[0]?.order_id
                ? `/orders/${queryClient.getQueryData(
                      queryKeys.associations.orderLineItems(association.resource_id.toString(), undefined).queryKey
                  )?.lineItems[0]?.order_id}`
                : `/order_line_item/${association.resource_id}`
            break
        case 'draft_order':
            link = `/orders/draft-orders/${association.resource_id}`
            break
        case 'draft_order_line_item':
            link = queryClient.getQueryData(
                queryKeys.associations.draftOrderLineItems(association.resource_id.toString(), undefined).queryKey
            )?.lineItems[0]?.draft_order_id
                ? `/orders/draft-orders/${queryClient.getQueryData(
                      queryKeys.associations.draftOrderLineItems(association.resource_id.toString(), undefined).queryKey
                  )?.lineItems[0]?.draft_order_id}`
                : `/draft_order_line_item/${association.resource_id}`
            break
        case 'project':
            link = `/tasks/projects/${association.resource_id}`
            break
        case 'customer':
            link = `/customers?customer_id=${association.resource_id}`
            break
        case 'customer_tax_exemption':
            link = `/customers/tax-exemptions/${association.resource_id}`
            break
        case 'roadmap':
            link = `/tasks/roadmaps/${association.resource_id}`
            break
        case 'company':
            link = `/${
                user?.roles?.includes('procurement') || user?.type === 'Admin' || user?.type === 'SuperAdmin'
                    ? 'procurement'
                    : 'vendors'
            }/${association.resource_id}?view=activity&${searchParams ? searchParams.toString() : ''}`
            break
    }
    return link
}
