import { CheckCircledIcon, StopwatchIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FilterBarSelectorButton } from '../../../custom_components/component_Basics/FilterBarSelectorButton'
import { useCompanyContactsQuery, useCompanyEmailsQuery, useCompanyIncomingEmailsQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType, createdAtSort } from '../../helpers'
import FilterToolBarV2 from '../FilterToolBarV2'
import CompanyEmail from '../listItems/CompanyEmail'
import IncomingEmail from '../listItems/IncomingEmail'

export default function EmailListV2({ company }: any) {
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const { setSelectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const searchParam = searchParams.get('pModalSearch') || ''

    const [filterValues, setFilterValues] = useState<{ types: string[] }>({
        types: [],
    })

    const companyContactsQuery = useCompanyContactsQuery({ companyId: company.id })
    const { contacts } = companyContactsQuery.data || {}

    const companyEmailsQuery = useCompanyEmailsQuery({ companyId: company.id, search: searchParam })

    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    const companyIncomingEmailsQuery = useCompanyIncomingEmailsQuery({
        companyId: company.id,
        companyEmail: company.email,
        contactList: contacts || [],
        search: searchParam,
    })

    const { emails: incomingEmails }: { emails: any[] } = companyIncomingEmailsQuery?.data || {}
    let allEmails: any[] = []
    if (emails) {
        allEmails.push(...emails.map((email) => ({ ...email, type: 'sent-email' }) as any as EventType))
    }
    if (incomingEmails) {
        allEmails.push(...incomingEmails.map((email) => ({ ...email, type: 'incoming-email' }) as any as EventType))
    }

    const filteredEmails = allEmails.sort(createdAtSort)

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'pModalSearch',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
    ]
    // ACTIVE FILTERS
    return (
        <div className='p-0 h-[80vh]'>
            <FilterToolBarV2
                activeFilters={activeFilters}
                showLoading={true}
                isLoading={companyIncomingEmailsQuery.isLoading || companyEmailsQuery.isLoading}
            >
                <div className='flex flex-col gap-2 items-center'>
                    <Button
                        variant={'outline'}
                        size={'sm'}
                        onClick={() => {
                            setSelectedCompany(company)
                            return setShowQuickModal('email')
                        }}
                    >
                        + New Email
                    </Button>
                </div>
            </FilterToolBarV2>
            <div className='flex flex-col gap-[8px] min-h-[800px] max-h-full overflow-x-scroll'>
                {filteredEmails?.map((email) => {
                    if (email.type == 'incoming-email') {
                        return <IncomingEmail key={email.thread_id} email={email} />
                    }
                    return <CompanyEmail key={email.id} email={email} />
                })}
            </div>
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
