import { Button } from 'custom_components/component_Basics/Button'
import { useGetInventorySyncShops, useUpdateInventorySyncShop } from 'inventorySyncShops/api/useQueries'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

export default function EditInventorySyncShopModal({
    selectedInventorySyncShopId,
    toggleModal,
}: {
    selectedInventorySyncShopId: string
    toggleModal: () => void
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const updateInventorySyncShopMutation = useUpdateInventorySyncShop()
    const inventorySyncShopsQuery = useGetInventorySyncShops({})
    const inventorySyncShop =
        (inventorySyncShopsQuery.data?.inventorySyncShops || []).find(
            (shop: any) => shop.id == selectedInventorySyncShopId
        ) || {}

    const [editedFields, setEditedFields] = useState<any>({})

    const handleSave = () => {
        updateInventorySyncShopMutation.mutate(
            { id: selectedInventorySyncShopId, ...editedFields },
            {
                onSuccess: () => toggleModal(),
            }
        )
    }
    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                toggleModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative max-w-[90%] max-h-[90%] p-6 rounded bg-bg1 dark:bg-darkbg1 shadow-md'>
                <button
                    className='text-red dark:text-lightred absolute top-1 right-1 z-10'
                    onClick={() => toggleModal()}
                >
                    <FaTimes />
                </button>
                <h2 className='font-bold text-base mb-4'>Create New Inventory Sync Shop</h2>
                <Input
                    outerClassName='mb-2'
                    id='shop'
                    name='shop'
                    label='Shop (myshopify domain)'
                    type='text'
                    value={editedFields.shop ?? inventorySyncShop.shop}
                    onChange={({ target }) => setEditedFields({ ...editedFields, [target.name]: target.value })}
                />
                <Input
                    outerClassName='mb-2'
                    id='client_id'
                    name='client_id'
                    label='Client ID (From partner dashboard)'
                    type='text'
                    value={editedFields.client_id ?? inventorySyncShop.client_id}
                    onChange={({ target }) => setEditedFields({ ...editedFields, [target.name]: target.value })}
                />
                <Input
                    outerClassName='mb-4'
                    id='client_secret'
                    name='client_secret'
                    label='Client Secret (From partner dashboard)'
                    type='text'
                    value={editedFields.client_secret ?? inventorySyncShop.client_secret}
                    onChange={({ target }) => setEditedFields({ ...editedFields, [target.name]: target.value })}
                />
                <Button
                    className='block mx-auto'
                    disabled={updateInventorySyncShopMutation.isPending}
                    variant='outline'
                    size='sm'
                    onClick={handleSave}
                >
                    Save Updates
                </Button>
            </div>
        </div>
    )
}
