import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const inventorySyncShops = createQueryKeys('inventorySyncShops', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getInventorySyncShops(filters),
    }),
})

export default mergeQueryKeys(inventorySyncShops)
