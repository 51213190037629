import { useEffect, useRef, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import Modal from '../../custom_components/Modal'
import {
    createOrderNotesArray,
    formatDateToLocale,
    formatISOToCSTTimestamp,
    sendToast,
    taskIdRegex,
    timestampRegex,
    vFetch,
} from '../../helpers'
import { UserInit } from '../../users/users.types'
import { OrderInit, OrderNoteInit } from '../orders.types'
import { useAllUsersQuery } from 'users/api/useQueries'
import { useQueryClient } from '@tanstack/react-query'
import queryKeys from 'orderHubScreen/api/queryKeys'
import draftOrderQueryKeys from 'orderHubScreenDraft/api/draftOrderQueryKeys'

type OrderNoteProps = {
    index: number
    note: OrderNoteInit
    state: OrderInit
    type?: 'orders' | 'draftOrders'
}

export const OrderNote = ({ index, note, state, type = 'orders' }: OrderNoteProps) => {
    let unparsedNote = note.note
    const noteRef = useRef<HTMLDivElement>(null)
    const [showAreYouSureDelete, setShowAreYouSureDelete] = useState(false)
    const [replacementNote, setReplacementNote] = useState(note.note)
    const [editNote, setEditNote] = useState(false)
    const queryClient = useQueryClient()

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const foundUser = users?.find((u) =>
        (note.author === 'TB' ? u.user_id === 'orders' : u.user_id?.toLowerCase() === note.author?.toLowerCase()) ||
        note.author?.length === 2
            ? u.user_id?.toLowerCase().slice(0, 2) === note.author?.toLowerCase().slice(0, 2)
            : false
    )
    const authorName = foundUser ? `${foundUser.first_name} ${foundUser.last_name}` : `Unknown Author: ${note.author}`

    async function handleEdit() {
        const replacementCopy = replacementNote.trim()
        if (replacementCopy === '') {
            setReplacementNote(unparsedNote)
            return sendToast({ message: `Please enter updated note content, or delete note.` })
        }
        const timestampIncluded = replacementCopy.match(timestampRegex)
        if (timestampIncluded && timestampIncluded.length > 1)
            return sendToast({ message: "Please don't include multiple timestamps in your note." })
        const notesWithoutEndTimestamp = replacementCopy
            .trim()
            .slice(0, replacementCopy.length - (timestampIncluded?.slice(-1)[0].length || 0))
            .trim()
        const timestampIsAtEnd =
            timestampIncluded &&
            (notesWithoutEndTimestamp.match(timestampRegex) || []).length !== timestampIncluded.length
        if (timestampIncluded && !timestampIsAtEnd)
            return sendToast({
                message: "Please don't enter a new timestamp in the middle of the note you are editing.",
            })

        let oldNotes = state.note?.slice()
        const taskIds =
            oldNotes
                ?.match(taskIdRegex)
                ?.map((id) => id.trim())
                ?.join('\n') || ''
        const denormalized_note: any = {}

        Object.keys(note).forEach((key: string) =>
            !key.startsWith('normalized_') ? (denormalized_note[key] = (note as any)[key]) : ''
        )
        let notes = createOrderNotesArray(state.note || '', users)?.map((n) =>
            JSON.stringify({ ...n, normalized_event_type: undefined }) === JSON.stringify(denormalized_note)
                ? { ...note, note: replacementNote.replaceAll(timestampRegex, '').trim() }
                : n
        )

        const rebuiltNotes = [
            taskIds,
            notes
                .map((n) => {
                    const nFrom =
                        n.from.name === 'Shopify' || n.from.name === 'Order Note'
                            ? ''
                            : n.from.name
                                  .split(' ')
                                  .map((w) => w[0].toUpperCase())
                                  .join('')
                    return [n.note, formatISOToCSTTimestamp(n.created_at), n.author, nFrom].join(' ')
                })
                .join('\n\n'),
        ]
            .filter((v) => !!v)
            .join('\n\n')

        setEditNote(false)
        vFetch(`/v1/${type}/${state.id}`, {
            method: 'POST',
            body: JSON.stringify({ note: rebuiltNotes }),
            cb: (res: any) => {
                if (res.success) {
                    setEditNote(false)
                    queryClient.invalidateQueries({ queryKey: queryKeys.orders._def })
                    queryClient.invalidateQueries({ queryKey: draftOrderQueryKeys.draftOrders._def })
                }
            },
        })
        queryClient.invalidateQueries({ queryKey: queryKeys.orders._def })
        queryClient.invalidateQueries({ queryKey: draftOrderQueryKeys.draftOrders._def })
    }
    async function handleDelete() {
        let oldNotes = state.note?.slice()

        const taskTypes: any = {}
        oldNotes
            ?.match(taskIdRegex)
            ?.map((v) => v.split('\n'))
            .flat()
            .filter((v) => v)
            .forEach((id) => {
                const [abbreviatedType, task_id] = id.trim().split(':')
                if (Number(task_id) > Number(taskTypes[abbreviatedType] || -1)) taskTypes[abbreviatedType] = task_id
            })
        const taskIds = Object.keys(taskTypes)
            .map((id) => `${id}:${taskTypes[id]}`)
            .join('\n')
        const denormalized_note: any = {}

        Object.keys(note).forEach((key: string) =>
            !key.startsWith('normalized_') ? (denormalized_note[key] = (note as any)[key]) : ''
        )
        let notes = createOrderNotesArray(state.note || '', users)?.filter(
            (n) => JSON.stringify({ ...n, normalized_event_type: undefined }) !== JSON.stringify(denormalized_note)
        )

        const rebuiltNotes = [
            taskIds,
            notes
                .map((n) => {
                    const nFrom =
                        n.from.name === 'Shopify' || n.from.name === 'Order Note'
                            ? ''
                            : n.from.name
                                  .split(' ')
                                  .map((w) => w[0].toUpperCase())
                                  .join('')
                    return [n.note, formatISOToCSTTimestamp(n.created_at), n.author, nFrom].join(' ')
                })
                .join('\n\n'),
        ]
            .filter((v) => !!v)
            .join('\n\n')

        vFetch(`/v1/${type}/${state.id}`, {
            method: 'POST',
            body: JSON.stringify({ note: rebuiltNotes }),
            cb: (res: any) => {
                if (res.success) {
                    setShowAreYouSureDelete(false)
                    queryClient.invalidateQueries({ queryKey: queryKeys.orders._def })
                    queryClient.invalidateQueries({ queryKey: draftOrderQueryKeys.draftOrders._def })
                }
            },
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!noteRef.current?.contains(e.target as HTMLElement)) {
                setEditNote(false)
            }
        }
        window.addEventListener('mousedown', listenToWindow)
        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    const buttonStyle =
        'w-[80px] p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small'

    function From() {
        return (
            <div>
                <h4 className={`text-[18px] font-bold ${note.from.color}`}>{note.from.name}</h4>
                <p className='text-[14px] text-darkgrey dark:text-grey'>{authorName}</p>
            </div>
        )
    }
    function Timestamp() {
        return (
            <div className='text-[13px] text-end mt-[4px]'>
                <p>
                    {[note.created_at.getMonth() + 1, note.created_at.getDate(), note.created_at.getFullYear()].join(
                        '/'
                    )}
                </p>
                <p className='text-darkgrey dark:text-grey'>
                    {formatDateToLocale(note.created_at).split(' ').slice(-2).join(' ')}
                </p>
            </div>
        )
    }
    const preStyle = 'whitespace-pre-wrap font-normal font-bai'

    return (
        <div className={`text-black dark:text-offwhite flex gap-[8px] items-center grow orderNote-${index}`}>
            {showAreYouSureDelete && (
                <Modal setShow={() => setShowAreYouSureDelete(false)}>
                    <div className='max-w-[500px] flex flex-col gap-[16px] p-[16px] bg-white dark:bg-darkaccent rounded shadow-small'>
                        <p className='text-[20px] font-bold'>Are you sure you want to DELETE this note?</p>
                        <div className='w-[100%] p-[8px] bg-white dark:bg-darkness dark:text-offwhite shadow-md rounded'>
                            <div className='flex justify-between mb-[8px]'>
                                <From />
                                <div className='flex gap-[8px] duration-200'>
                                    {note.created_at instanceof Date && <Timestamp />}
                                </div>
                            </div>
                            <pre style={{ wordBreak: 'break-word' }} className={preStyle}>
                                {note.note}
                            </pre>
                        </div>
                        <div className='flex gap-[16px]'>
                            <p>Order {state?.name}</p>
                            <p>Event: {index}</p>
                        </div>
                        <div className='flex justify-between'>
                            <button
                                className={buttonStyle + ' !bg-red !text-white  hover:!bg-red/80'}
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                            <button className={buttonStyle} onClick={() => setShowAreYouSureDelete(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            <div
                className='w-[100%] p-[8px] border-[1px] dark:border-darkgrey bg-white dark:bg-darkness dark:text-offwhite shadow-md rounded'
                ref={noteRef}
            >
                <div className='flex justify-between mb-[8px]'>
                    <From />
                    <div className='flex gap-[8px] duration-200'>
                        <span
                            className='dark:text-accent hover:underline cursor-pointer'
                            onClick={() => {
                                setEditNote(true)
                            }}
                        >
                            Edit
                        </span>
                        <HiOutlineTrash
                            className='stroke-red dark:stroke-accent mt-[3px] duration-200 h-[16px] w-[16px] hover:scale-125 cursor-pointer'
                            onClick={() => setShowAreYouSureDelete(true)}
                        />
                        {typeof note.created_at === 'object' && <Timestamp />}
                    </div>
                </div>
                {editNote ? (
                    <div className='flex flex-wrap justify-end'>
                        <textarea
                            className='min-h-[175px] task-screen__notes__textarea block w-[100%] p-[8px] m-0 max-w-none border-[1px] border-[#cdcdcd] rounded-br-none mt-[8px] dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue'
                            value={replacementNote}
                            onChange={({ target }) => {
                                setReplacementNote(target.value)
                            }}
                        />
                        <button
                            className='block relative mr-0 ml-[auto] top-0 py-[8px] px-[16px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer'
                            onClick={() => handleEdit()}
                        >
                            Save Edit
                        </button>
                    </div>
                ) : (
                    <pre style={{ wordBreak: 'break-word' }} className={preStyle}>
                        {note.note}
                    </pre>
                )}
            </div>
        </div>
    )
}
