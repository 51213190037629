import ConfirmDeleteModal from 'custom_components/ConfirmDeleteModal'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import { cn, formatDateToLocale } from '../../../helpers'
import { useDeleteNote, useUpdateNote } from '../../api/useQueries'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

export default function CompanyNoteV3({ event, index }: { event: any; index: number }) {
    const noteRef = useRef<HTMLDivElement>(null)
    const adminRef = useRef<HTMLDivElement>(null)
    const user = useSelector<any, any>((state) => state.user)
    const [editableNote, setEditableNote] = useState<string | undefined>(undefined)
    const [editNote, setEditNote] = useState(false)
    const [showAdminActions, setShowAdminActions] = useState(false)
    const [showAreYouSureDelete, setShowAreYouSureDelete] = useState(false)

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const foundUser = users?.find((u) => u.id === event.user_id)
    const authorName = foundUser ? `${foundUser.first_name} ${foundUser.last_name}` : `Unknown Author: ${event.author}`

    const updateNote = useUpdateNote()
    const deleteNote = useDeleteNote()

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableNote(target.value)
    }
    const handleDelete = async () => {
        deleteNote.mutate({ note: event })
    }

    const handleUpdate = async () => {
        updateNote.mutate(
            { note: { ...event, value: editableNote }, original: event.value },
            {
                onSuccess: () => {
                    setEditableNote(undefined)
                    setEditNote(false)
                },
            }
        )
    }

    const noteActions: any = {
        Delete: () => setShowAreYouSureDelete(true),
        Edit: () => {
            setShowAdminActions(false)
            return setEditNote(true)
        },
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!adminRef.current?.contains(e.target as HTMLElement)) {
                setShowAdminActions(false)
            }
        }
        window.addEventListener('mousedown', listenToWindow)
        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    function Timestamp() {
        const created_at = new Date(event.created_at)
        return (
            <div className='text-[13px] text-end mt-[4px]'>
                <p>{[created_at.getMonth() + 1, created_at.getDate(), created_at.getFullYear()].join('/')}</p>
                <p className='text-darkgrey dark:text-grey'>
                    {formatDateToLocale(created_at).split(' ').slice(-2).join(' ')}
                </p>
            </div>
        )
    }

    return (
        <div key={event.id} className={`relative text-black dark:text-offwhite flex gap-2 items-center w-full `}>
            {showAreYouSureDelete && (
                <ConfirmDeleteModal
                    details={`Delete note ${event.value}`}
                    handleCancel={() => setShowAreYouSureDelete(false)}
                    handleConfirm={handleDelete}
                />
            )}
            <div
                className='relative w-full p-2 border-[1px] dark:border-darkgrey bg-white dark:bg-darkness dark:text-offwhite shadow-md rounded'
                ref={noteRef}
            >
                <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                    Note
                </p>
                {user.type.match(/superadmin/i) && (
                    <div className='absolute top-1 right-0'>
                        <button
                            className={`js-newTask-actions__${event.id} flex justify-center items-center relative w-[35px] h-[15px] text-grey hover:text-darkgrey dark:hover:text-offwhite`}
                            onClick={() => setShowAdminActions(!showAdminActions)}
                        >
                            <span className='js-order__dot-button text-[20px] font-black tracking-wider leading-[0px] bottom-[4px] relative'>
                                ...
                            </span>
                        </button>
                        {showAdminActions && (
                            <div
                                ref={adminRef}
                                className='absolute top-full rounded right-0 flex flex-col p-[8px] bg-white dark:bg-darkaccent shadow-small gap-[8px] border-[1px] border-darkgrey'
                            >
                                <div className='flex flex-col rounded'>
                                    {Object.keys(noteActions).map((action, index) => (
                                        <div
                                            key={index}
                                            className='hover:bg-black/20 px-[8px] py-[2px] cursor-pointer whitespace-nowrap rounded'
                                            onClick={() => noteActions[action]()}
                                        >
                                            {action}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className='max-w-full flex justify-between mb-1 mt-2'>
                    {editNote ? (
                        <div className='flex flex-wrap justify-end w-full'>
                            <textarea
                                className='min-h-[175px] w-full p-2 m-0 max-w-full border-[1px] rounded focus:outline-none  rounded-br-none mt-2 dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue'
                                value={editableNote ?? event.value}
                                onChange={handleChange}
                            />
                            <div className={cn('flex gap-4 items-center', updateNote.isPending && 'opacity-70')}>
                                <Button
                                    variant={'outline'}
                                    size={'sm'}
                                    onClick={() => {
                                        setEditNote(false)
                                        setEditableNote(undefined)
                                    }}
                                >
                                    Cancel Edit
                                </Button>
                                <FetchingSpinner
                                    className={cn('opacity-0', updateNote.isPending && 'opacity-100')}
                                    isFetching={updateNote.isPending}
                                />
                                <button
                                    disabled={updateNote.isPending}
                                    className='block relative mr-0 text-sm ml-[auto] top-0 py-[8px] px-[16px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer'
                                    onClick={handleUpdate}
                                >
                                    Save Edit
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <p className='max-w-[560px] break-words '>{event.value}</p>
                            <div className='flex gap-[8px]'>{<Timestamp />}</div>
                        </>
                    )}
                </div>
                <div>
                    <p className='text-[14px] text-darkgrey dark:text-grey'>{authorName}</p>
                </div>
            </div>
        </div>
    )
}
