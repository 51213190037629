import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { MdOutlineAttachment } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TaskListItemSkeleton from '../procurement/components/listItems/TaskListItemSkeleton'
import { CalendarProcurementTasks } from './CalendarProcurementTasks'
import { FetchingSpinner } from './FetchingSpinner'
import { NoResults } from './NoResults'
import { ToggleSwitch } from './ToggleSwitch'
import { ActiveFilters } from './component_Basics/ActiveFilters'
import AreYouSure from './component_Basics/AreYouSure'
import { Button } from './component_Basics/Button'
import { FilterBarSelectorButton } from './component_Basics/FilterBarSelectorButton'
import { SkeletonBasic } from './component_Basics/SkeletonBasic'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'

export default function ComponentList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    const namesString = searchParams.get('names') || ''
    const colorsString = searchParams.get('colors') || ''
    const taskSearchParam = searchParams.get('taskSearch') || ''

    const searchRef: any = useRef()

    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false)

    const exampleNames = ['Olivia', 'Geoff', 'Alex', 'Adrian']
    const exampleColors = ['Green', 'Red', 'Blue', 'Yellow']
    const nameOptions = exampleNames?.map((name: string) => {
        return {
            value: name, // value pushed into array or searchParams
            label: name, // string shown to client
            icon: undefined,
        }
    })
    const colorOptions = exampleColors?.map((color: string) => {
        return {
            value: color,
            label: color,
            icon: undefined,
        }
    })

    // turn searchParam string into array of values
    const nameValues = decodeURIComponent(namesString)
        .split(',')
        .filter((v) => v)
    const colorValues = decodeURIComponent(colorsString)
        .split(',')
        .filter((v) => v)

    // ACTIVE FILTERS
    const activeFiltersExample = [
        {
            searchParamField: 'names',
            label: 'Users',
            options: nameOptions,
            values: nameValues,
        },
        {
            searchParamField: 'colors',
            label: 'colors',
            options: colorOptions,
            values: colorValues,
        },
        {
            searchParamField: 'taskSearch',
            label: 'search',
            options: [{ label: taskSearchParam, value: taskSearchParam, icon: undefined }],
            values: taskSearchParam ? [taskSearchParam] : [],
        },
    ]
    // ACTIVE FILTERS

    useEffect(() => {
        if (!user.access.includes('calls') && !user.access.includes('*')) {
            navigate('/home')
        }
    }, [])

    return (
        <>
            {user && (
                <h1>
                    <div className='grid grid-cols-7 gap-6 bg-bg1 dark:bg-darkbg1 p-2 rounded-md'>
                        <span>size = lg</span>
                        <Button size={'lg'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'lg'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'lg'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'lg'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'lg'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'lg'} variant={'link'}>
                            link
                        </Button>{' '}
                        <span>size = default</span>
                        <Button size={'default'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'default'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'default'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'default'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'default'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'default'} variant={'link'}>
                            link
                        </Button>{' '}
                        <span>size = sm</span>
                        <Button size={'sm'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'sm'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'sm'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'sm'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'sm'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'sm'} variant={'link'}>
                            link
                        </Button>
                        <span>size = icon</span>
                        <Button size={'icon'} variant={'default'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'destructive'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'outline'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'secondary'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'ghost'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'link'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                    </div>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>ToggleSwitch</p>
                            <ToggleSwitch />
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Skeleton</p>
                            <SkeletonBasic className='h-[100px] w-[275px]' />
                            <TaskListItemSkeleton />
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Day Picker</p>
                            <p className='text-sm'>Will accept custom day cells </p>
                            <CalendarProcurementTasks date={undefined} setDate={undefined} />
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Background fetch spinner</p>
                            <FetchingSpinner isFetching={true} />
                        </div>

                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Tool Tips</p>
                            {/* copy chunk below, change text, remove OutlineAttachment line and replace it with what you want to hover */}
                            <div className='group flex relative'>
                                <button className='flex'>
                                    <MdOutlineAttachment size={'25px'} className={`m-auto rotate-[-90deg]`} />
                                    <main className='absolute bottom-[-6px] left-6 hidden group-hover:flex'>
                                        <section className='container max-w-2xl p-2'>
                                            <div className='flex items-center'>
                                                <div className='whitespace-nowrap bg-gray-700 dark:bg-accent text-white dark:text-darkaccent p-1 px-2 rounded flex-1 text-xs'>
                                                    Add Attachment
                                                </div>
                                            </div>
                                        </section>
                                    </main>
                                </button>
                            </div>
                        </div>
                        {/* <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Quilljs Text Editor</p>
                        </div> */}
                        <div className='relative flex gap-2 border-b pb-3 justify-between cursor-default'>
                            <p>"Are you sure" modal</p>
                            <button
                                className='text-lg text-red dark:text-lightred'
                                onClick={() => setShowAreYouSureModal(true)}
                            >
                                <FaTimes />
                            </button>
                            {showAreYouSureModal && (
                                <AreYouSure
                                    onConfirm={() => setShowAreYouSureModal(false)}
                                    onDeny={() => setShowAreYouSureModal(false)}
                                />
                            )}
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Filter Buttons / Search Bar / Active Filters</p>

                            <div className='w-[600px] flex flex-col gap-4'>
                                <TasksScreenV2SearchContainer ref={searchRef} field={'taskSearch'} />
                                <div className='flex gap-2'>
                                    <FilterBarSelectorButton
                                        key={'colors'}
                                        searchToggle={true}
                                        filterValues={colorValues}
                                        setFilterValues={() => {}}
                                        title='Colors'
                                        field='colors'
                                        options={colorOptions}
                                        variant='outline'
                                        editSearchParams={true}
                                        align='start'
                                    />
                                    <FilterBarSelectorButton
                                        key={'names'}
                                        searchToggle={false}
                                        filterValues={nameValues}
                                        setFilterValues={() => {}}
                                        title='Names'
                                        field='names'
                                        options={nameOptions}
                                        variant='outline'
                                        editSearchParams={true}
                                        align='start'
                                    />
                                </div>
                            </div>
                            <ActiveFilters activeFilters={activeFiltersExample} />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>No results banner</p>
                            <NoResults margin={'ml-8'} content={'Sorry, Fathers Day!'} />
                        </div>
                    </div>
                </h1>
            )}
        </>
    )
}
