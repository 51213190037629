import { vFetch } from '../../helpers'

// queries/companies.ts

export const createPrivateEvent = async ({
    privateEventUrl,
    id,
    ids,
    user_id,
}: {
    privateEventUrl: string
    id?: number | string
    ids?: number[] | string[]
    user_id: number
}) => {
    vFetch(`${privateEventUrl}`, {
        reThrowErr: true,
        skipToast: true,
        method: 'POST',
        body: JSON.stringify({
            id,
            ids,
            user_id,
            event_type: 'viewed',
        }),
    })
}

export const getPrivateEvents = async ({
    privateEventUrl,
    element,
    id,
    ids,
    signal,
}: {
    privateEventUrl: string
    element: string
    id?: number
    ids?: number[]
    signal: AbortSignal
}) => {
    return await vFetch(`${privateEventUrl}?${element}=${id}`, {
        signal,
        reThrowErr: true,
    })
}
export const getPrivateEventsV2 = async ({
    privateEventUrl,
    ids,
    signal,
}: {
    privateEventUrl: string
    ids: number[]
    signal: AbortSignal
}) => {
    return await vFetch(`${privateEventUrl}/v2?${ids?.length ? `ids=${ids}` : ''}`, {
        signal,
        reThrowErr: true,
    })
}
