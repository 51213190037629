import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn } from 'helpers'
import { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { useDeleteOption, useUpdateOption } from 'tasksV2/api/useQueries'

export default function TaskOptionRow({
    optionName,
    option,
}: {
    optionName: 'estimates' | 'types' | 'statuses'
    option: any
}) {
    const updateOptionMutation = useUpdateOption(optionName)
    const deleteOptionMutation = useDeleteOption(optionName)
    const [newOptionValue, setNewOptionValue] = useState<null | string>(null)
    const [showDelete, setShowDelete] = useState(false)

    const handleSave = () => {
        if (newOptionValue) {
            updateOptionMutation.mutate({
                id: option.id,
                value: newOptionValue,
            })
        }
    }

    const handleDelete = () => {
        deleteOptionMutation.mutate(
            { id: option.id },
            {
                onSuccess: () => {
                    setShowDelete(false)
                },
            }
        )
    }

    return (
        <div className='flex w-full gap-2 pr-2 border-b border-lightgrey dark:border-darkgrey'>
            <input
                className='w-full p-2 focus:outline-none dark:bg-darkbg2 dark:border-darkgrey rounded'
                placeholder='New Option'
                value={newOptionValue ?? option.value}
                onChange={({ target }) => setNewOptionValue(target.value)}
            />
            {newOptionValue && newOptionValue !== option.value ? (
                <div
                    className={cn(
                        'flex gap-2 items-center',
                        updateOptionMutation.isPending && 'opacity-70 pointer-events-none'
                    )}
                >
                    <FetchingSpinner isFetching={updateOptionMutation.isPending} />
                    <Button className={cn('min-w-[73px]')} size={'sm'} variant={'outline'} onClick={handleSave}>
                        {updateOptionMutation.isPending ? 'Saving..' : 'Save'}
                    </Button>
                    <Button className='' size={'sm'} variant={'outline'} onClick={() => setNewOptionValue(null)}>
                        Cancel
                    </Button>
                </div>
            ) : showDelete ? (
                <div
                    className={cn(
                        'flex gap-2 items-center',
                        deleteOptionMutation.isPending && 'opacity-70 pointer-events-none'
                    )}
                >
                    <FetchingSpinner isFetching={deleteOptionMutation.isPending} />
                    <Button className='text-danger' size={'sm'} variant={'outline'} onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button className='' size={'sm'} variant={'outline'} onClick={() => setShowDelete(false)}>
                        Cancel
                    </Button>
                </div>
            ) : (
                <button onClick={() => setShowDelete(true)}>
                    <FaTrash className='text-red dark:text-lightred' />
                </button>
            )}
        </div>
    )
}
