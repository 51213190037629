import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useContext } from 'react'
import { CgExport } from 'react-icons/cg'
import { FaFile, FaTasks } from 'react-icons/fa'
import { FaPerson, FaRegNoteSticky } from 'react-icons/fa6'
import { MdEmail } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { FetchingSpinner } from '../../custom_components/FetchingSpinner'
import { cn } from '../../helpers'
import { useUpdateCompany } from '../api/useQueries'
import { LEAD_TYPE_OPTIONS, LIFECYCLE_STATUS_OPTIONS } from '../constants'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../helpers'
import RepSelector from './RepSelector'
import { useSelector } from 'react-redux'

export default function CompanyQuickActions({ company, isEdited, setSelectedLifecycle, resourceView }: any) {
    const user = useSelector<any, any>((state) => state.user)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id') || '']
    const { write, read } = permissionSet?.procurement
    const { toggleModal, selectedCompany, setSelectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const { showQuickModal, setShowQuickModal } = useContext<any>(CompanyContext)
    const [searchParams, setSearchParams] = useSearchParams()

    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const updateCompany = useUpdateCompany()
    const { isPending } = updateCompany

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }
    const handleQuickActionClick = (action: string) => {
        if (showQuickModal === action) {
            setShowQuickModal(undefined)
            setSelectedCompany(undefined)
            return
        }
        setSelectedCompany(company)
        setShowQuickModal(action)
    }
    return (
        <>
            <div
                className={cn(
                    'sticky top-0 bg-white dark:bg-darkaccent z-40 flex items-center gap-[10px] pb-[16px] border-b border-lightgrey dark:border-darkgrey ',
                    resourceView === 'products' && 'pb-0 border-none mt-[1px] mb-[-1px] mr-2 '
                )}
            >
                {resourceView !== 'products' && (
                    <>
                        <button
                            onClick={() => handleQuickActionClick('note')}
                            className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        >
                            <FaRegNoteSticky className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite overflow-visible' />
                            <p className='font-medium leading-[1] uppercase text-[12px]'>Note</p>
                        </button>
                        {write && (
                            <button
                                onClick={() => handleQuickActionClick('email')}
                                className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                            >
                                <MdEmail className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                                <p className='font-medium leading-[1] uppercase text-[12px]'>Email</p>
                            </button>
                        )}
                    </>
                )}
                <button
                    onClick={() => {
                        toggleCreateModal()
                    }}
                    className={cn(
                        'w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all overflow-visible',
                        resourceView === 'products' && 'gap-[2px]'
                    )}
                >
                    <FaTasks
                        className={cn(
                            'text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite overflow-visible',
                            resourceView === 'products' && 'w-[24px] h-[24px] p-[4px] '
                        )}
                    />
                    <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                </button>
                {resourceView !== 'products' && (
                    <>
                        <button
                            onClick={() => handleQuickActionClick('contact')}
                            className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        >
                            <FaPerson className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                            <p className='font-medium leading-[1] uppercase text-[12px]'>Contact</p>
                        </button>
                        <button
                            onClick={() => handleQuickActionClick('file')}
                            className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        >
                            <FaFile className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                            <p className='font-medium leading-[1] uppercase text-[12px]'>File</p>
                        </button>
                    </>
                )}
                {write && (
                    <button
                        onClick={() => handleQuickActionClick('csv_export')}
                        className={cn(
                            'w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all overflow-visible',
                            resourceView === 'products' && 'gap-[2px]'
                        )}
                    >
                        <CgExport
                            className={cn(
                                'text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite overflow-visible',
                                resourceView === 'products' && 'w-[24px] h-[24px] p-[3px] '
                            )}
                        />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Export</p>
                    </button>
                )}
            </div>
            {resourceView !== 'products' && (
                <>
                    {company.parent_company && (
                        <div className='pb-[8px] border-b border-lightgrey dark:border-darkgrey'>
                            <button
                                className='font-bold uppercase text-[14px] leading-[1] py-[4px] px-[8px] w-full rounded-[4px] border border-blue text-blue dark:border-fire dark:text-fire'
                                onClick={() => toggleModal('parent', company)}
                            >
                                View Parent Company
                            </button>
                        </div>
                    )}
                    <div className='flex justify-between'>
                        <p className='leading-[1] text-[14px] mb-[4px]'>Procurement status</p>
                        {updateCompany.isPending && <FetchingSpinner isFetching={updateCompany.isPending} />}
                    </div>
                    <div
                        className={cn(
                            updateCompany.isPending && 'opacity-25',
                            'group relative flex gap-[8px] w-full mb-[4px]'
                        )}
                    >
                        {isEdited && (
                            <div className='hidden group-hover:flex gap-2 border absolute top-[100%] left-0 w-fit bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                <ExclamationTriangleIcon className='self-center mt-[2px] text-danger' /> Save Pending
                                Changes
                            </div>
                        )}
                        <div className='flex flex-col gap-[4px] w-1/2'>
                            <label
                                className={cn(
                                    isEdited && 'opacity-75',
                                    'text-[12px] uppercase font-bold leading-[1] dark:text-offwhite'
                                )}
                            >
                                Lead Type
                            </label>
                            <select
                                value={company?.lead_type}
                                disabled={isEdited || isPending}
                                name='lead_type'
                                onChange={({ target }) => {
                                    const newValue = target.value
                                    if (newValue.includes('Follow Up')) {
                                        toggleCreateModal()
                                    }
                                    updateCompany.mutateAsync({
                                        id: company.id,
                                        editableFields: { lead_type: target.value },
                                    })
                                }}
                                className={cn(
                                    'w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                                    !write && 'pointer-events-none'
                                )}
                            >
                                {LEAD_TYPE_OPTIONS.map((leadType) => (
                                    <option key={leadType.label} className='dark:bg-darkness/90' value={leadType.value}>
                                        {leadType.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='flex flex-col gap-[4px] w-1/2'>
                            <label
                                className={cn(
                                    isEdited && 'opacity-75',
                                    'text-[12px] uppercase font-bold leading-[1] dark:text-offwhite'
                                )}
                            >
                                Lifecycle Stage
                            </label>
                            <select
                                value={company?.lifecycle_status}
                                disabled={isEdited || isPending}
                                name='lifecycle_status'
                                onChange={({ target }) => {
                                    const newValue = target.value
                                    if (newValue === 'Listing' || newValue === 'Listed') {
                                        setSelectedLifecycle(newValue)
                                        setSelectedCompany(company)
                                        setShowQuickModal('listing')
                                        return
                                    }
                                    return updateCompany.mutate({
                                        id: company.id,
                                        editableFields: { lifecycle_status: target.value },
                                    })
                                }}
                                className={cn(
                                    'w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                                    !write && 'pointer-events-none'
                                )}
                            >
                                {LIFECYCLE_STATUS_OPTIONS.map((stage) => (
                                    <option key={stage.label} className='dark:bg-darkness/90' value={stage.value}>
                                        {stage.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <p className='font-bold'>Products Listed: {company.product_count}</p>
                    </div>
                    <div className={cn(!write && 'pointer-events-none')}>
                        <RepSelector company={company} />
                    </div>
                </>
            )}
        </>
    )
}
