import { vFetch } from 'helpers'

// departments
export const getDevelopmentDepartment = async () =>
    vFetch('/v2/tasks/departments/development', {
        cb: (res: any) => {
            res.department.types = res.department.types ? JSON.parse(res.department.types) : res.department.types
            res.department.estimates = res.department.estimates
                ? JSON.parse(res.department.estimates)
                : res.department.estimates
            return res
        },
    })
export const getDepartments = async (filters: any) => {
    const params = Object.entries(filters)
        .map(([key, value]) => (value ? `${key}=${value}` : null))
        .filter((v) => v)
        .join('&')
    return await vFetch(`/v2/tasks/departments${params ? `?${params}` : ''}`, {
        reThrowErr: true,
    })
}

export const createDepartment = async (department: any) => {
    return await vFetch('/v2/tasks/departments', {
        method: 'POST',
        body: JSON.stringify(department),
        reThrowErr: true,
    })
}

export const updateDepartment = async (department: any) => {
    return await vFetch('/v2/tasks/departments', {
        method: 'PUT',
        body: JSON.stringify(department),
        reThrowErr: true,
    })
}

export const deleteDepartment = async (departmentId: number) => {
    return await vFetch('/v2/tasks/departments', {
        method: 'DELETE',
        body: JSON.stringify({ id: departmentId }),
        reThrowErr: true,
    })
}

// department configs
export const getDepartmentConfig = async (departmentId: string) => {
    return await vFetch(`/v2/tasks/departments/config?department_id=${departmentId}`, {
        reThrowErr: true,
    })
}

export const getAllDepartmentConfigs = async (userId: string) => {
    return await vFetch(`/v2/tasks/departments/config/all?user_id=${userId}`, {
        cb: (res: any) => {
            res.departments.forEach((department: any) => {
                department.types = department.types ? JSON.parse(department.types) : department.types
                department.statuses = department.statuses
                    ? JSON.parse(department.statuses).toSorted((a: any, b: any) => (a.position > b.position ? 1 : -1))
                    : department.statuses.toSorted((a: any, b: any) => (a.position > b.position ? 1 : -1))
                department.estimates = department.estimates ? JSON.parse(department.estimates) : department.estimates
                return department
            })
            return res
        },
        reThrowErr: true,
    })
}

// department_types / department_estimate
export const createDepartmentOption = async ({
    type,
    task_type_id,
    task_estimate_id,
    task_status_id,
    position,
    department_id,
}: {
    type: 'estimates' | 'types' | 'statuses'
    task_type_id?: number
    task_estimate_id?: number
    task_status_id?: number
    position: number
    department_id: string
}) => {
    return vFetch(`/v2/tasks/departments/${type}`, {
        method: 'POST',
        body: JSON.stringify({
            task_type_id,
            task_estimate_id,
            task_status_id,
            position,
            department_id,
        }),
        reThrowErr: true,
    })
}

export const updateDepartmentOption = async ({
    id,
    type,
    position,
}: {
    id: number
    type: 'estimates' | 'types' | 'statuses'
    position?: number
}) => {
    return vFetch(`/v2/tasks/departments/${type}`, {
        method: 'PUT',
        body: JSON.stringify({
            id,
            position,
        }),
        reThrowErr: true,
    })
}

export const deleteDepartmentOption = async ({
    id,
    type,
}: {
    id: number
    type: 'estimates' | 'types' | 'statuses'
}) => {
    return vFetch(`/v2/tasks/departments/${type}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id,
        }),
        reThrowErr: true,
    })
}

// task_types / task_estimates

export const createTaskOption = async ({
    type,
    value,
}: {
    type: 'estimates' | 'types' | 'statuses'
    value: string
}) => {
    return vFetch(`/v2/tasks/${type}`, {
        method: 'POST',
        body: JSON.stringify({
            value,
        }),
        reThrowErr: true,
    })
}

export const getTaskOptions = async (type: 'estimates' | 'types' | 'statuses') => {
    return vFetch(`/v2/tasks/${type}`, {
        reThrowErr: true,
    })
}

export const updateTaskOption = async ({
    id,
    type,
    value,
}: {
    id: number
    type: 'estimates' | 'types' | 'statuses'
    value: string
}) => {
    return vFetch(`/v2/tasks/${type}`, {
        method: 'PUT',
        body: JSON.stringify({
            id,
            value,
        }),
        reThrowErr: true,
    })
}

export const deleteTaskOption = async ({ id, type }: { id: number; type: 'estimates' | 'types' | 'statuses' }) => {
    return vFetch(`/v2/tasks/${type}`, {
        method: 'DELETE',
        body: JSON.stringify({
            id,
        }),
        reThrowErr: true,
    })
}

export const getRecurringTasks = async (filters: {
    user_id: number
    department_id: number
    statuses: string[]
    departmentsIds: string[]
}) => {
    const params = Object.entries(filters)
        .map(([key, value]) => (value ? `${key}=${value}` : null))
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/tasks/recurring${params ? `?${params}` : ''}`, {
        cb: (res: any) => {
            res.recurringTasks.forEach((task: any) => {
                task.task_data = task.task_data ? JSON.parse(task.task_data) : task.task_data
                return task
            })
            return res
        },
        reThrowErr: true,
    })
}

export const updateRecurringTask = async (recurringTask: any) => {
    return vFetch(`/v2/tasks/recurring`, {
        method: 'PUT',
        body: JSON.stringify(recurringTask),
        reThrowErr: true,
    })
}

export const getTasks = async ({
    user_id,
    department_id,
    userIds,
    departmentIds,
    typeIds,
    priorities,
    statuses,
    estimateIds,
    search,
    date,
    dateRange,
    overdue,
    pinned,
    resourceTable,
    resourceIds,
    page,
    limit,
}: any) => {
    const queryParams = [
        user_id ? `user_id=${user_id}` : null,
        department_id ? `department_id=${department_id}` : null,
        userIds?.length ? `userIds=${userIds.join(',')}` : null,
        departmentIds?.length ? `departmentIds=${departmentIds.join(',')}` : null,
        typeIds?.length ? `typeIds=${typeIds.join(',')}` : null,
        priorities?.length ? `priorities=${priorities.join(',')}` : null,
        statuses?.length ? `statuses=${statuses.join(',')}` : null,
        estimateIds?.length ? `estimateIds=${estimateIds.join(',')}` : null,
        search ? `search=${encodeURIComponent(search.replaceAll(',', ''))}` : null,
        date ? `date=${encodeURIComponent(date)}` : null,
        dateRange && dateRange.from ? `from=${encodeURIComponent(dateRange.from)}` : null,
        dateRange && dateRange.to ? `to=${encodeURIComponent(dateRange.to)}` : null,
        overdue ? `overdue=${overdue}` : null,
        pinned ? `pinned=${pinned}` : null,
        resourceTable ? `resourceTable=${resourceTable}` : null,
        resourceIds ? `resourceIds=${resourceIds.join(',')}` : null,
        page ? `page=${page}` : null,
        limit ? `limit=${limit}` : null,
    ]
        .filter((v) => v)
        .join('&')

    return await vFetch(`/v2/tasks${queryParams.length ? `?${queryParams}` : ''}`, {
        cb: (res: any) => {
            if (res.success) {
                res.tasks.forEach((task: any) => {
                    task.associations = JSON.parse(task?.associations || '[]')
                })
            }
            return res
        },
        reThrowErr: true,
    })
}

export const getTask = async (task_id: string) => {
    return vFetch(`/v2/tasks?id=${task_id}`, {
        cb: (res: any) => {
            if (res.tasks && res.tasks[0]) {
                res.task = res.tasks[0]
                res.task.associations = JSON.parse(res.task?.associations || '[]')
            }
            return res
        },
        reThrowErr: true,
    })
}

export const createTask = async (task: any) => {
    return vFetch('/v2/tasks', {
        method: 'POST',
        body: JSON.stringify(task),
        reThrowErr: true,
    })
}

export const updateTask = async (task: any) => {
    return vFetch('/v2/tasks', {
        method: 'PUT',
        body: JSON.stringify(task),
        reThrowErr: true,
    })
}

export const deleteTask = async (task_id: number) => {
    return vFetch('/v2/tasks', {
        method: 'DELETE',
        body: JSON.stringify({ id: task_id }),
        reThrowErr: true,
    })
}

export const getTaskEvents = async (taskIds: string[] | undefined) => {
    return vFetch(`/v2/tasks/events?taskIds=${taskIds ? taskIds.join(',') : ''}`, {
        cb: (res: any) => {
            if (res.success) {
                res.events.forEach((event: any) => {
                    event.old_value = JSON.parse(event.old_value)
                    event.new_value = JSON.parse(event.new_value)
                    if (taskIds && taskIds.length > 1) {
                        event.message = event?.message?.replace('this task', `task #${event.task_id}`)
                    }
                    if (event.type !== 'private') {
                        event.normalized_event_type = 'Task Events Public'
                    }
                })
                return res
            }
        },
        reThrowErr: true,
    })
}

export const searchOrders = async (search: string) => {
    return vFetch(`/v1/orders?fields=name,id,customer${search ? `&search=${search}&customer_search=${search}` : ''}`, {
        reThrowErr: true,
    })
}

export const searchOrderLineItems = async (search: string) => {
    return vFetch(
        `/v1/orders?fields=name,id,line_items${search ? `&search=${search}&customer_search=${search}` : ''}`,
        {
            reThrowErr: true,
            cb: (res: any) => ({
                line_items:
                    res?.orders
                        ?.map((o: any) => JSON.parse(o.line_items).map((li: any) => ({ ...li, order_name: o.name })))
                        .flat() || [],
            }),
        }
    )
}
export const orderLineItems = async (orderLineItemId: string | undefined, orderIds: string[] | undefined) => {
    const queryParams = [
        orderLineItemId ? `orderLineItemId=${orderLineItemId}` : '',
        orderIds?.length ? `orderIds=${orderIds}` : '',
    ]
        .filter((v: any) => v)
        .join('&')
    return vFetch(`/v1/orders/lineItems${queryParams.length ? `?${queryParams}` : ''}`, {
        reThrowErr: true,
    })
}

export const searchDraftOrderLineItems = async (search: string) => {
    return vFetch(
        `/v1/draftOrders?fields=name,id,line_items${search ? `&search=${search}&customer_search=${search}` : ''}`,
        {
            reThrowErr: true,
            cb: (res: any) => ({
                line_items:
                    res?.draft_orders
                        ?.map((o: any) =>
                            JSON.parse(o.line_items).map((li: any) => ({ ...li, draft_order_name: o.name }))
                        )
                        .flat() || [],
            }),
        }
    )
}
export const draftOrderLineItems = async (
    draftOrderLineItemId: string | undefined,
    draftOrderIds: string[] | undefined
) => {
    const queryParams = [
        draftOrderLineItemId ? `draftOrderLineItemId=${draftOrderLineItemId}` : '',
        draftOrderIds?.length ? `draftOrderIds=${draftOrderIds}` : '',
    ]
        .filter((v: any) => v)
        .join('&')
    return vFetch(`/v1/draftOrders/lineItems${queryParams.length ? `?${queryParams}` : ''}`, {
        reThrowErr: true,
    })
}

export const searchDraftOrders = async (search: string) => {
    return vFetch(`/v1/draftOrders?fields=name,id,customer${search ? `&search=${search}` : ''}`, {
        reThrowErr: true,
    })
}

export const createRecurringTask = async (recurringTask: any) => {
    return vFetch('/v2/tasks/recurring', {
        method: 'POST',
        body: JSON.stringify(recurringTask),
        reThrowErr: true,
    })
}

export const deleteRecurringTask = async (recurringTaskId: string) => {
    return vFetch('/v2/tasks/recurring', {
        method: 'DELETE',
        body: JSON.stringify({ id: recurringTaskId }),
        reThrowErr: true,
    })
}

export const createProject = async (project: any) => {
    return vFetch('/v2/tasks/projects', {
        method: 'POST',
        body: JSON.stringify(project),
        reThrowErr: true,
    })
}

export const getProjects = async (filters: any) => {
    const params = Object.entries(filters)
        .map(([key, value]) => (value ? `${key}=${value}` : null))
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/tasks/projects${params ? `?${params}` : ''}`, {
        reThrowErr: true,
    })
}

export const getProject = async (projectId: string | number) => {
    return vFetch(`/v2/tasks/projects?id=${projectId}`, {
        cb: (res: any) => {
            if (res.projects.length) {
                res.project = res.projects[0]
            }
            return res
        },
        reThrowErr: true,
    })
}

export const getProjectTasks = async (projectId: string | number) => {
    return vFetch(`/v2/tasks/projects/${projectId}/tasks`, {
        reThrowErr: true,
    })
}

export const updateProject = async (project: any) => {
    return vFetch(`/v2/tasks/projects`, {
        method: 'PUT',
        body: JSON.stringify(project),
        reThrowErr: true,
    })
}

export const deleteProject = async (projectId: string | number) => {
    return vFetch(`/v2/tasks/projects`, {
        method: 'DELETE',
        body: JSON.stringify({ id: projectId }),
        reThrowErr: true,
    })
}

export const getProjectEvents = async (project_id: string | number) => {
    return vFetch(`/v2/tasks/projects/events?project_id=${project_id}`, {
        reThrowErr: true,
    })
}

export const createRoadmap = async (roadmap: any) => {
    return vFetch('/v2/tasks/roadmaps', {
        method: 'POST',
        body: JSON.stringify(roadmap),
        reThrowErr: true,
    })
}

export const getRoadmaps = async (filters: any) => {
    const params = Object.entries(filters)
        .map(([key, value]) => (value ? `${key}=${value}` : null))
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/tasks/roadmaps${params ? `?${params}` : ''}`, {
        reThrowErr: true,
    })
}

export const getRoadmap = async (roadmapId: string | number) => {
    return vFetch(`/v2/tasks/roadmaps?id=${roadmapId}`, {
        cb: (res: any) => {
            if (res.roadmaps.length) {
                res.roadmap = res.roadmaps[0]
            }
            return res
        },
        reThrowErr: true,
    })
}

export const getRoadmapProjects = async (roadmapId: string | number) => {
    return vFetch(`/v2/tasks/roadmaps/${roadmapId}/projects`, {
        reThrowErr: true,
    })
}

export const updateRoadmap = async (roadmap: any) => {
    return vFetch(`/v2/tasks/roadmaps`, {
        method: 'PUT',
        body: JSON.stringify(roadmap),
        reThrowErr: true,
    })
}

export const deleteRoadmap = async (roadmapId: string | number) => {
    return vFetch(`/v2/tasks/roadmaps`, {
        method: 'DELETE',
        body: JSON.stringify({ id: roadmapId }),
        reThrowErr: true,
    })
}

export const getRoadmapEvents = async (roadmap_id: string | number) => {
    return vFetch(`/v2/tasks/roadmaps/events?roadmap_id=${roadmap_id}`, {
        reThrowErr: true,
    })
}

export const getResourceTasks = async ({ resource_name, resource_id }: any) => {
    return await vFetch(`/v2/tasks/associations/${resource_name}/${resource_id}`)
}
