import { vFetch } from 'helpers'

export const createInventorySyncShop = async (shop: any) => {
    return vFetch(`/v2/inventorySyncShops`, {
        method: 'POST',
        body: JSON.stringify(shop),
    })
}

export const getInventorySyncShops = async (filters: any = {}) => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/inventorySyncShops${params ? `?${params}` : ''}`)
}

export const updateInventorySyncShop = async (update: any) => {
    return vFetch(`/v2/inventorySyncShops`, {
        method: 'PUT',
        body: JSON.stringify(update),
    })
}

export const deleteInventorySyncShop = async (id: number) => {
    return vFetch(`/v2/inventorySyncShops`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
    })
}
