import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { getDateToday } from 'procurement/constants'
import { CalendarBasev2 } from 'custom_components/component_Basics/CalendarBasev2'
import { DateRange } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'

export function V2TaskCalendarRange<TData, TValue>({
    date,
    dateRange,
    setDate,
    openFlag,
}: {
    date?: Date | undefined
    dateRange?: DateRange | undefined
    setDate: any
    openFlag?: boolean
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <Popover open={openFlag || undefined}>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className={cn(
                        date !== getDateToday() && '',
                        'w-full justify-center text-left font-bold max-h-[29.5px]',
                        !date && ''
                    )}
                >
                    <CalendarIcon className='mr-2 h-4 w-4' />
                    {dateRange && dateRange.from ? format(dateRange?.from, 'P') : <span>Pick a date</span>}
                    {dateRange && dateRange.to && ` - ${format(dateRange?.to, 'P')}`}
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 z-50 ' align='start'>
                <CalendarBasev2
                    className='border border-primary rounded bg-bg1 dark:bg-darkbg1'
                    mode='range'
                    selected={dateRange}
                    onSelect={(e: any) => {
                        if (e === undefined) {
                            searchParams.delete('from')
                            searchParams.delete('to')
                            setSearchParams(searchParams)
                            setDate({ from: getDateToday() })
                        } else {
                            searchParams.set('from', e.from.toString())
                            e.to ? searchParams.set('to', e.to.toString()) : searchParams.delete('to')
                            setSearchParams(searchParams)
                            setDate(e)
                        }
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
