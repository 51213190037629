import { useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { cn } from '../../../helpers'
import { useGetPathname } from 'productSetupV2/api/useQueries'

export default function CompanyModalV2Loading() {
    const modalRef = useRef(null)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const pathname = useGetPathname()
    const address = pathname.includes('procurement') ? 'procurement' : 'vendors'

    function handleCloseModal() {
        searchParams.delete('view')
        setSearchParams(searchParams)
        navigate(`/${address}/` + '?' + searchParams.toString())
    }

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center company-modal'
        >
            <div
                className={cn(
                    'p-[26px] gap-[16px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative h-full w-full flex'
                )}
            >
                <button
                    onClick={handleCloseModal}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
            </div>
        </div>
    )
}
