import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useCreateInfoBlock } from 'procurement/api/useQueries'
import InputV2 from 'procurement/components/InputV2'
import Select from 'procurement/components/Select'
import { CompanyType } from 'procurement/constants'
import { ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { BLOCK_INFO_OPTIONS } from 'vendors/constants'

type CreateInfoBlockProps = {
    setShowCreate: Function
    company: CompanyType
}

export default function CreateInfoBlock({ company, setShowCreate }: CreateInfoBlockProps) {
    const modalRef = useRef(null)
    const [selectedHeader, setSelectedHeader] = useState<string>('Custom Header')
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)

    const createInfoBlock = useCreateInfoBlock()
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (target.value === 'Custom Header') {
            setValue('header', '')
            return
        }
        setValue('header', target.value)
        setSelectedHeader(target.value)
    }

    interface IFormValues {
        header: string
        body: string
    }

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<IFormValues>({
        values: {
            header: '',
            body: '',
        },
    })
    const watchHeader = watch('header', '')

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowCreate(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    useEffect(() => {
        if (!BLOCK_INFO_OPTIONS.includes(watchHeader)) {
            setSelectedHeader('Custom Header')
        }
    }, [watchHeader])

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        createInfoBlock.mutate(
            { infoBlock: { company_id: company.id, ...data } },
            {
                onSettled: () => {
                    return setShowCreate(false)
                },
            }
        )
    }

    const labelStyle = 'font-bai text-[12px] font-bold text-[#4a4a4a] uppercase dark:text-offwhite'
    const inputStyle =
        'block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'

    return (
        <div className='fixed top-0 left-0 w-[100%] h-[100%] justify-center bg-[rgba(0,0,0,0.5)] z-50'>
            <div ref={modalRef} className='relative flex justify-center items-center h-[calc(100%-51px)] w-[100%]'>
                <form
                    id='infoBlock_form'
                    onSubmit={handleSubmit(onSubmit)}
                    className='relative min-w-[600px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'
                >
                    <button
                        onClick={() => {
                            setShowCreate(false)
                        }}
                        className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                    >
                        &times;
                    </button>
                    <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                        Create Info Block
                    </h2>

                    <div className='grid gap-[8px] overflow-auto max-h-[calc(70vh-51px)]'>
                        <InputV2
                            register={register}
                            label='Header'
                            id='header'
                            field='header'
                            type='text'
                            required={true}
                            errors={errors}
                        />
                        <Select
                            value={selectedHeader}
                            onChange={handleChange}
                            label='Header Presets'
                            id='selectedHeader'
                            name='selectedHeader'
                        >
                            {BLOCK_INFO_OPTIONS?.map((type: string) => {
                                return <option value={type}>{type}</option>
                            })}
                        </Select>
                        <div className='grow'>
                            <label className={labelStyle}>
                                Body <span className='text-danger dark:text-darkdanger'>*</span>
                            </label>
                            <textarea
                                {...register('body', { required: true })}
                                className={`${inputStyle} h-[120px] outline-none`}
                            />
                            {errors && errors['body'] && errors['body'].type === 'required' && (
                                <span className='text-danger dark:text-lightred'>This is required</span>
                            )}
                        </div>
                        <div className='relative flex justify-center mt-[16px] gap-4 h-[40px]'>
                            <Button variant={'outline'} className={cn('col relative')} type='submit'>
                                <p className={cn(createInfoBlock.isPending && 'opacity-0')}>Create</p>
                                {createInfoBlock.isPending && (
                                    <div className='absolute '>
                                        <FetchingSpinner isFetching={createInfoBlock.isPending} />
                                    </div>
                                )}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
