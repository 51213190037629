import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { cn } from 'helpers'
import { CompanyType, fileTypeNames } from 'procurement/constants'
import { useContext, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { BsArrowDown } from 'react-icons/bs'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { useSearchParams } from 'react-router-dom'
import { useCompanyFilesQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import FilterToolBarV2 from '../FilterToolBarV2'
import CompanyFileV2 from '../listItems/CompanyFileV2'
import CSVMapper from '../modals/CSVMapper'

export type FileNameOptionsType =
    | 'FP Import Template'
    | 'Intake Form'
    | 'Dealer Application'
    | 'Price List / SKUs / UPCs'
    | 'Product Reviews'
    | 'Inventory'
    | 'Brochure / Listing Material'
    | 'Credit Card / ACH Info'
    | 'Shipping Weight & Dimensions'
    | 'Warranty & Returns'

export default function FileListV2({ company }: { company: CompanyType }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const { setSelectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [showCSVMapper, setShowCSVMapper] = useState(false)
    const [selectedFile, setSelectedFile] = useState()
    const fileTypesString = searchParams.get('fileTypes') || ''
    const searchParam = searchParams.get('pModalSearch') || ''
    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const page = searchParams.get('page') || 1
    const [cursorList, setCursorList] = useState<any>([0])

    const fileTypeValues: string[] = decodeURIComponent(fileTypesString)
        .split(',')
        .filter((v) => v)
    const companyFilesQuery = useCompanyFilesQuery({
        companyId: company.id,
        filters: { fileTypes: fileTypeValues, after, before, page },
        search: searchParam,
    })
    const { files }: { files: any[] } = companyFilesQuery?.data || {}

    const filteredFiles = files?.filter((file) => {
        return (
            file.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
            file.mime_type?.toLowerCase().includes(searchParam.toLowerCase())
        )
    })

    const fileTypeOptions = fileTypeNames.map((option) => {
        return {
            label: option,
            value: option,
            icon: undefined,
        }
    })

    const filterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: fileTypeNames.map((option) => {
                    return {
                        label: option,
                        value: option,
                        icon: undefined,
                    }
                }),
                title: 'File Type',
                field: 'fileTypes',
                values: fileTypeValues,
                searchToggle: true,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('fileTypes')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    if (!companyFilesQuery.isFetching && !companyFilesQuery.isLoading) {
        if (after && !(after[2] === null)) {
            if (!cursorList.includes(after)) {
                setCursorList((prev: any[]) => {
                    return [...prev, after]
                })
            }
        }
    }
    function handlePageIncrease() {
        if (files?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', files[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.set('before', files[0].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'fileTypes',
            label: 'file type',
            options: fileTypeOptions,
            values: fileTypeValues,
        },
        {
            searchParamField: 'pModalSearch',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
    ]
    // ACTIVE FILTERS
    return (
        <div className={cn(' px-0 h-[80vh]', showCSVMapper && 'h-[85vh]')}>
            {!showCSVMapper && (
                <>
                    <FilterToolBarV2
                        activeFilters={activeFilters}
                        showLoading={true}
                        isLoading={companyFilesQuery.isLoading}
                    >
                        <div className='flex gap-2 items-center'>
                            {filterBarObject.params.length &&
                                filterBarObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.field}
                                            searchToggle={param.searchToggle}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={filterBarObject.setFunction}
                                            editSearchParams={param.editSearchParams}
                                        />
                                    )
                                })}
                            <Button
                                variant={'outline'}
                                size={'sm'}
                                onClick={() => {
                                    setSelectedCompany(company)
                                    return setShowQuickModal('file')
                                }}
                            >
                                + New File
                            </Button>
                            <Button variant={'outline'} size={'sm'} className='px-1 pr-2'>
                                <a
                                    href={
                                        'https://fpdash-bucket.s3.amazonaws.com/procurement/1/1857/product_import_template_csv.csv'
                                    }
                                    target='_blank'
                                    className=' flex items-center'
                                >
                                    <BsArrowDown className='text-darkgrey dark:text-offwhite p-[4px] w-[24px] h-[24px]' />
                                    <p className=' text-[12px] '>Import Template</p>
                                </a>
                            </Button>
                        </div>
                    </FilterToolBarV2>

                    <div className='flex flex-col max-h-full'>
                        <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>File Type</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                File Mime Type
                            </div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                Uploaded On
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 max-h-full overflow-scroll'>
                            {filteredFiles?.map((file: any) => (
                                <CompanyFileV2
                                    key={file.id}
                                    file={file}
                                    setSelectedFile={setSelectedFile}
                                    setShowCSVMapper={setShowCSVMapper}
                                />
                            ))}
                            <div className='m-auto p-2 opacity-80 rounded-md text-darkgrey dark:text-lightgrey border border-darkgrey dark:border-darkgrey'>
                                Upload a .csv file to import products
                            </div>
                        </div>
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={companyFilesQuery.isLoading || companyFilesQuery.isFetching || files?.length < 50}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            )}
            {showCSVMapper && (
                <div className='max-h-full overflow-scroll'>
                    <CSVMapper company={company} file={selectedFile} setShowCSVMapper={setShowCSVMapper} />
                </div>
            )}
        </div>
    )
}
