import { Button } from 'custom_components/component_Basics/Button'
import { useSearchParams } from 'react-router-dom'
import ShopList from './lists/ShopList'
import CreateInventorySyncShopModal from './modal/CreateInventorySyncShopModal'
import EditInventorySyncShopModal from './modal/EditInventorySyncShopModal'

export default function InventorySyncShopsScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedShop = searchParams.get('inventory_sync_shop')
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }
    const toggleEditModal = (shopId?: string) => {
        if (!shopId) {
            searchParams.delete('inventory_sync_shop')
            return setSearchParams(searchParams)
        }
        searchParams.set('inventory_sync_shop', shopId)
        return setSearchParams(searchParams)
    }

    return (
        <div className='text-sm'>
            <div className='flex gap-2 justify-between mb-4'>
                <h1 className='font-semibold text-2xl'>Inventory Sync Shops</h1>
                <Button variant='outline' size='sm' onClick={toggleCreateModal}>
                    + New Shop
                </Button>
            </div>
            <div className='flex flex-col h-full gap-2 min-h-0'>
                <ShopList />
            </div>
            {showCreateModal && <CreateInventorySyncShopModal toggleModal={toggleCreateModal} />}
            {selectedShop && (
                <EditInventorySyncShopModal selectedInventorySyncShopId={selectedShop} toggleModal={toggleEditModal} />
            )}
        </div>
    )
}
