import {
    CaretDownIcon,
    CaretSortIcon,
    CaretUpIcon,
    CheckCircledIcon,
    CrossCircledIcon,
    InfoCircledIcon,
    StopwatchIcon,
} from '@radix-ui/react-icons'
import { FileNameOptionsType } from 'procurement/components/lists/FileListV2'
import { FaCheck, FaPlusCircle } from 'react-icons/fa'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { MdPending } from 'react-icons/md'
import { TbAlertCircleFilled } from 'react-icons/tb'

//audit constants
export const companyProductsTable = 'company_products'
export const companyAlertsTable = 'company_alerts'
export const companiesTable = 'companies'

//analytics constants
export const procurementDepartment = 'procurement'
export const procurementEventType = 'company'
export const taskEventType = 'task'

// private event constants
export const PROCUREMENT_PRIVATE_EVENT_URL = '/v1/companyEventsPrivate'
export const PROCUREMENT_PRIVATE_EVENT_ELEMENT = 'company_id'

//company tasks
const date = new Date()
date.setHours(0, 0, 0, 0)
export const DATE_TODAY = date

export const getDateToday = () => {
    const newDate = new Date()
    newDate.setHours(0, 0, 0, 0)
    return newDate
}

export type CompanyType = {
    accept_logo: string
    accounting_payment_card: null
    accounting_payment_notes: null
    accounting_payment_terms: null
    accounting_payment_type: null
    active_score_groups: null
    active_score_options: null
    assigned_to: any
    canada_ships_from: null
    city: string
    company_id: null
    competition_score: number | undefined
    created_at: string
    description: string
    domain: string
    email: string
    facebook: string
    has_api: string
    has_edi: string
    id: number
    industry: string
    instagram: string
    is_archived: number | undefined
    latest_note: string
    lead_type: string
    lifecycle_status: string
    linkedin: string
    logo: string
    map_enforcement_score: number | undefined
    name: string
    notes: string
    number_of_employees: string
    overall_score: number | undefined
    overall_score_v2: number | undefined
    parent_company: string
    percent_ownership: null
    phone: string
    price_point_score: number | undefined
    processing_time: null
    product_count: number | undefined
    product_type: string
    refurbs_score: number | undefined
    reviews_score: number | undefined
    score_value_1: null
    score_value_2: null
    sells_to_canada: null
    shipping_details: null
    shipping_terms: null
    shopify_vendor_name: string
    state: string
    store_id: number | undefined
    task_due_at: null
    task_id: null
    timezone: string
    updated_at: string
    user_id: null
    us_ships_from: null
    warranty: null
    work_phone: null
    zip: string
}

export const COMPANY_PRODUCT_FIELD_OPTIONS = [
    {
        value: 'sku',
        label: 'Shopify Sku',
        icon: undefined,
    },
    {
        value: 'product_type',
        label: 'Product Type',
        icon: undefined,
    },
    {
        value: 'status',
        label: 'Listing Status',
        icon: undefined,
    },
    {
        value: 'weblinks',
        label: 'Web Links',
        icon: undefined,
    },
    {
        value: 'supplier_sku',
        label: 'Supplier Sku',
        icon: undefined,
    },
    {
        value: 'upc',
        label: 'UPC',
        icon: undefined,
    },
    {
        value: 'cost',
        label: 'Cost',
        icon: undefined,
    },
    {
        value: 'shipping_fee',
        label: 'Shipping Fee',
        icon: undefined,
    },
    {
        value: 'list_price',
        label: 'List Price',
        icon: undefined,
    },
    {
        value: 'lowest_competitor_price',
        label: 'Lowest Price',
        icon: undefined,
    },
    {
        value: 'lowest_competitor_link',
        label: 'Lowest Comp. Link',
        icon: undefined,
    },
    {
        value: 'notes',
        label: 'Notes',
        icon: undefined,
    },
    {
        value: 'related_variants_group_id',
        label: 'Related Variants Group',
        icon: undefined,
    },
    {
        value: 'variant_of',
        label: 'Variant of',
        icon: undefined,
    },
    {
        value: 'shipping_dimensions',
        label: 'Shipping Dimensions',
        icon: undefined,
    },
    {
        value: 'shipping_weight',
        label: 'Shipping Weight',
        icon: undefined,
    },
    {
        value: 'shipping_method',
        label: 'Shipping Method',
        icon: undefined,
    },
]
export const TASK_STATUS_OPTIONS = [
    {
        value: 'OPEN',
        label: 'Open',
        icon: StopwatchIcon,
    },
    {
        value: 'COMPLETED',
        label: 'Completed',
        icon: CheckCircledIcon,
    },
]
export const TASK_DATE_OPTIONS = [
    {
        value: 'overdue',
        label: 'Overdue',
        icon: CrossCircledIcon,
    },
    {
        value: 'due_today',
        label: 'Due Today',
        icon: StopwatchIcon,
    },
    {
        value: 'custom',
        label: '',
        icon: StopwatchIcon,
    },
]

//companies

export const SHIPPING_TERMS_OPTIONS = [
    {
        value: 'free_shipping',
        label: 'Free Shipping',
    },
    {
        value: 'we_ship',
        label: 'We ship using our account',
    },
    {
        value: 'they_ship',
        label: 'They ship using their account',
    },
    {
        value: 'both_ship',
        label: 'Ship from either account (see details)',
    },
]

export const REQUIRED_LISTING_FIELDS = ['has_edi', 'has_api', 'accept_logo', 'sells_to_canada', 'shipping_terms']

export const COMPANY_RESOURCE_VIEW_TABS = [
    'activity',
    'emails',
    'products',
    'contacts',
    'files',
    'accounting',
    'info',
    'unfulfilled',
]

export const COMPANY_SORT_OPTIONS = {
    overall_score: [
        {
            value: undefined,
            label: 'Score',
            icon: CaretSortIcon,
        },
        {
            value: { field: 'overall_score_v2', direction: 'DESC' },
            label: 'Score',
            icon: CaretUpIcon,
        },
        {
            value: { field: 'overall_score_v2', direction: 'ASC' },
            label: 'Score',
            icon: CaretDownIcon,
        },
    ],
    name: [
        {
            value: undefined,
            label: 'Name',
            icon: CaretSortIcon,
        },
        {
            value: { field: 'name', direction: 'ASC' },
            label: 'Name',
            icon: CaretUpIcon,
        },
        {
            value: { field: 'name', direction: 'DESC' },
            label: 'Name',
            icon: CaretDownIcon,
        },
    ],
}
export const PROCUREMENT_PRODUCT_LIST_FILTER_OPTIONS = [
    {
        value: 'calculating-margins',
        label: 'Calculating Margins',
        icon: MdPending,
    },
    {
        value: 'ready',
        label: `Ready To List`,
        icon: FaPlusCircle,
    },
    {
        value: 'needs-review',
        label: `Needs Review`,
        icon: TbAlertCircleFilled,
    },
    {
        value: 'unlistable',
        label: 'Unlistable',
        icon: IoMdCloseCircleOutline,
    },
    {
        value: 'listed-needs-review',
        label: 'Listed / Needs Review',
        icon: TbAlertCircleFilled,
    },
    {
        value: 'listed',
        label: 'Listed',
        icon: FaCheck,
    },
]
export const PROCUREMENT_PRODUCT_SORT_OPTIONS = [
    {
        value: { field: 'created_at', direction: 'ASC' },
        label: 'old',
        icon: CaretUpIcon,
    },
    {
        value: { field: 'list_price', direction: 'DESC' },
        label: 'high $',
        icon: CaretUpIcon,
    },
    {
        value: { field: 'created_at', direction: 'DESC' },
        label: 'new',
        icon: CaretDownIcon,
    },
    {
        value: { field: 'list_price', direction: 'ASC' },
        label: 'low $',
        icon: CaretDownIcon,
    },
]
export const FP_IMPORT_TEMPLATE_HEADINGS_MAP = {
    'Shopify SKU': 'sku',
    'Supplier SKU': 'supplier_sku',
    UPC: 'upc',
    'Model Number': 'model_number',
    'Product Type': 'product_type',
    'Manufacturer Weblink': 'manufacturer_website',
    Cost: 'cost',
    'Shipping Fee': 'shipping_fee',
    'List Price': 'list_price',
    'Lowest Price': 'lowest_competitor_price',
    'Lowest Price Link': 'lowest_competitor_link',
    Notes: 'notes',
    'Shipping Dimensions': 'shipping_dimensions',
    'Shipping Weight': 'shipping_weight',
    'Shipping Method': 'shipping_method',
}

export const price_fields = ['Cost', 'Shipping Fee', 'List Price', 'Lowest Price']

export const FP_IMPORT_TEMPLATE_FIELD_OPTIONS = [
    {
        value: 'sku',
        label: 'shopify sku',
    },
    {
        value: 'supplier_sku',
        label: 'supplier sku',
    },
    {
        value: 'manufacturer_website',
        label: 'manufacturer website',
    },
    {
        value: 'upc',
        label: 'UPC',
    },
    {
        value: 'model_number',
        label: 'model number',
    },
    {
        value: 'product_type',
        label: 'product type',
    },
    {
        value: 'cost',
        label: 'cost',
    },
    {
        value: 'shipping_fee',
        label: 'shipping fee',
    },
    {
        value: 'list_price',
        label: 'list price',
    },
    {
        value: 'lowest_competitor_price',
        label: 'lowest price',
    },
    {
        value: 'lowest_competitor_link',
        label: 'lowest price link',
    },
    {
        value: 'notes',
        label: 'notes',
    },
    {
        value: 'shipping_dimensions',
        label: 'Shipping Dimensions',
    },
    {
        value: 'shipping_weight',
        label: 'Shipping Weight',
    },
    {
        value: 'shipping_method',
        label: 'Shipping Method',
    },
]
export const PROCUREMENT_PRODUCT_CSV_MAP_FIELD_OPTIONS = [
    {
        value: 'sku, supplier_sku',
        label: 'shopify sku + supplier sku',
    },
    {
        value: 'sku',
        label: 'shopify sku',
    },
    {
        value: 'supplier_sku',
        label: 'supplier sku',
    },
    {
        value: 'manufacturer_website',
        label: 'Manufacturer Website',
    },
    {
        value: 'upc',
        label: 'upc',
    },
    {
        value: 'model_number',
        label: 'model number',
    },
    {
        value: 'product_type',
        label: 'product type',
    },
    {
        value: 'cost',
        label: 'cost',
    },
    {
        value: 'shipping_fee',
        label: 'shipping fee',
    },
    {
        value: 'list_price',
        label: 'list price',
    },
    {
        value: 'lowest_competitor_price',
        label: 'lowest price',
    },
    {
        value: 'lowest_competitor_link',
        label: 'lowest price link',
    },
    {
        value: 'notes',
        label: 'notes',
    },
    {
        value: 'shipping_dimensions',
        label: 'shipping dimensions',
    },
    {
        value: 'shipping_weight',
        label: 'shipping weight',
    },
    {
        value: 'shipping_method',
        label: 'shipping method',
    },
]

export const LEAD_TYPE_OPTIONS = [
    {
        tag: 'lead_type',
        value: 'New',
        label: 'New',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'High Priority',
        label: 'High Interest',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Low / Medium Priority',
        label: 'Low / Medium Interest',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Active',
        label: 'Active',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lead_type',
        value: 'Unqualified - Follow Up',
        label: 'Unqualified - Follow Up',
        icon: InfoCircledIcon,
    },
]

export const LIFECYCLE_STATUS_OPTIONS = [
    {
        tag: 'lifecycle_status',
        value: 'No Contact',
        label: 'No Contact',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Contacted',
        label: 'Contacted',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Contacted w/response',
        label: 'Contacted w/response',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Awaiting Dealer Forms',
        label: 'Awaiting Dealer Forms',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Need to Submit Dealer Form',
        label: 'Need to Submit Dealer Form',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Submitted Dealer Forms',
        label: 'Submitted Dealer Forms',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Awaiting Price List / Intake',
        label: 'Awaiting Price List / Intake',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Intake Form Revisions Requested',
        label: 'Intake Form Revisions Requested',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Pre-Calculating Margins',
        label: 'Pre-Calculating Margins',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Calculating Margins',
        label: 'Calculating Margins',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Bad Margins / Unlistable',
        label: 'Bad Margins / Unlistable',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Waiting to be Listed',
        label: 'Waiting to be Listed',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Listing',
        label: 'Listing',
        icon: InfoCircledIcon,
    },
    {
        tag: 'lifecycle_status',
        value: 'Listed',
        label: 'Listed',
        icon: InfoCircledIcon,
    },
]

export const defaultCompanyFilterParams = {
    id: undefined,
    name: undefined,
    domain: undefined,
    lead_type: [],
    lifecycle_status: [],
    score: undefined,
    search: undefined,
    assigned_to: [],
    lastCursor: [],
}
export const defaultTaskFilterParams = {
    id: undefined,
    due_at: undefined,
    company_id: undefined,
    search: '',
    lastCursor: undefined,
    user_ids: [],
    statuses: [],
    due_at_options: [],
}

export const defaultCompanyLogo = 'https://fpdash-bucket.s3.us-east-1.amazonaws.com/default-company-logo.jpeg'

export const fileTypeNames: FileNameOptionsType[] = [
    'FP Import Template',
    'Intake Form',
    'Dealer Application',
    'Price List / SKUs / UPCs',
    'Product Reviews',
    'Inventory',
    'Brochure / Listing Material',
    'Credit Card / ACH Info',
    'Shipping Weight & Dimensions',
    'Warranty & Returns',
]
