import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { useUnfulfilledLineItemsQuery } from 'procurement/api/useQueries'
import { formatDateWithYear } from 'procurement/helpers'
import React, { useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Link, useSearchParams } from 'react-router-dom'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import FilterToolBarV2 from '../FilterToolBarV2'
import UnfulfilledOrderEmail from '../modals/quickActions/UnfulfilledOrderEmail'
import UnfulfilledOrdersTable from './UnfulfilledOrdersTable'

export type unfulfilledLineItemType = {
    id: number
    vendor: string
    created_at: any
    fulfillment_status: string
    order_id: number
    price: number
    quantity: number
    sku: string
    title: string
    name: string
    email_sent_at: any
}
export default function UnfulfilledOrdersList({ company, rowSelection, setRowSelection }: any) {
    const unfulfilledLineItemsQuery = useUnfulfilledLineItemsQuery(
        company.shopify_vendor_name,
        undefined,
        Boolean(company.shopify_vendor_name)
    )
    const { unfulfilledLineItems } = unfulfilledLineItemsQuery?.data || {}
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get('pModalSearch') || ''
    const [showEmailModal, setShowEmailModal] = useState(false)

    const tableData = unfulfilledLineItems?.length > 0 ? unfulfilledLineItems : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<unfulfilledLineItemType>()

    const formatter = new Intl.NumberFormat('en-US')

    const selectedLineItems = unfulfilledLineItems?.filter((lineItem: unfulfilledLineItemType) => {
        return Object.keys(rowSelection).includes(lineItem.id.toString())
    })
    const unfulfilledColumns = [
        {
            enableGlobalFilter: false,
            id: 'select-col',
            header: ({ table }: { table: any }) => (
                <div className='flex items-center justify-center '>
                    <input
                        className='h-[15px] w-[15px]'
                        type='checkbox'
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
                    />
                </div>
            ),
            cell: ({ row }: { row: any }) => (
                <div onClick={row.getToggleSelectedHandler()} className='h-full flex items-center justify-center'>
                    <input
                        className='h-[15px] w-[15px] '
                        type='checkbox'
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                </div>
            ),
            size: 20,
        },

        columnHelper.accessor('name', {
            header: 'Name',
            enableGlobalFilter: true,
            cell: (info) => {
                return (
                    <div className='flex gap-2 items-center justify-between '>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                        <Link
                            to={`https://fpdash.com/orders/${info.row.original.order_id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
            size: 100,
        }),
        columnHelper.accessor('order_id', {
            header: 'Order ID',
            enableGlobalFilter: false,
            cell: (info) => {
                return (
                    <div className='flex gap-2 items-center justify-between '>
                        {typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                        <Link
                            to={`https://admin.shopify.com/store/factorypure/orders/${info.getValue()}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
        }),
        columnHelper.accessor('price', {
            enableGlobalFilter: false,
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        ${typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
            size: 80,
        }),
        columnHelper.accessor('quantity', {
            enableGlobalFilter: false,
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
            size: 60,
        }),
        columnHelper.accessor('sku', {
            enableGlobalFilter: true,
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group'>
                        <div>{truncate(info.getValue(), 55)}</div>
                        {info.getValue().length > 55 && (
                            <div className='z-10 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 200,
        }),
        columnHelper.accessor('title', {
            enableGlobalFilter: true,
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group'>
                        <div>{truncate(info.getValue(), 55)}</div>
                        {info.getValue().length > 55 && (
                            <div className='z-10 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 380,
        }),
        columnHelper.accessor('created_at', {
            enableGlobalFilter: false,
            header: 'Order Created at',

            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{formatDateWithYear(new Date(info.getValue()))}</div>
            },
        }),
        columnHelper.accessor('email_sent_at', {
            enableGlobalFilter: false,
            header: () => {
                return (
                    <div className='relative flex gap-1 peer items-center'>
                        <p>Last Emailed</p>
                        <AiFillInfoCircle className='peer cursor-help' />
                        <div className='dark:bg-darkaccent opacity-0 peer-hover:opacity-100 dark:border-[1px] absolute pointer-events-none top-[24px] z-[5] bg-white shadow-small p-2 min-w-[150px] rounded-md font-normal normal-case'>
                            <p className=' text-text1 dark:text-darktext1'>
                                Emails not sent from this tool will not be accounted for here
                            </p>
                        </div>
                    </div>
                )
            },

            cell: (info) => {
                return (
                    <div className='flex gap-2'>
                        {info.getValue() ? formatDateWithYear(new Date(info.getValue())) : ''}
                    </div>
                )
            },
            size: 160,
        }),
    ]

    const columnCount = unfulfilledColumns?.length.toString() || 10

    // ACTIVE FILTERS
    const activeFilters = [
        {
            searchParamField: 'pModalSearch',
            label: 'search',
            options: [{ label: searchParam, value: searchParam, icon: undefined }],
            values: searchParam ? [searchParam] : [],
        },
    ]
    // ACTIVE FILTERS

    return (
        <div className='p-[16px] pt-0 pl-0 min-h-0  h-[calc(100vh-130px)]'>
            <FilterToolBarV2
                activeFilters={activeFilters}
                showLoading={true}
                isLoading={unfulfilledLineItemsQuery.isLoading}
            ></FilterToolBarV2>
            {!company.shopify_vendor_name && (
                <div className='flex justify-center w-full mb-2'>
                    <div className='flex justify-center p-1 px-2  items-center gap-2 border rounded '>
                        <ExclamationTriangleIcon className='text-danger' />
                        Company must have Shopify Vendor Name to fetch related orders
                    </div>
                </div>
            )}
            {tableDataMemo && (
                <div className='overflow-x-scroll h-full min-h-0 text-sm'>
                    <UnfulfilledOrdersTable
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                        size={'auto'}
                        search={searchParam}
                        showHeaders={true}
                        columns={unfulfilledColumns}
                        tableData={tableDataMemo}
                        columnCount={columnCount}
                        handleRowClick={() => {}}
                    />
                </div>
            )}

            {Object.keys(rowSelection).length > 0 && (
                <div className='flex fixed bottom-[8px] z-50 left-[50%] translate-x-[-50%] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <Button onClick={() => setShowEmailModal(true)} variant={'outline'} size={'default'}>
                        Email Selected
                    </Button>
                </div>
            )}
            {showEmailModal && company && (
                <>
                    <UnfulfilledOrderEmail
                        company={company}
                        showQuickModal={showEmailModal}
                        setShowQuickModal={setShowEmailModal}
                        unfulfilledLineItems={selectedLineItems}
                    />
                </>
            )}
        </div>
    )
}
