import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as service from './service'
import queryKeys from './queryKeys'

export const useCreateInventorySyncShop = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (shop: any) => service.createInventorySyncShop(shop),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.inventorySyncShops._def }),
    })
}

export const useGetInventorySyncShops = (filters: any) => {
    return useQuery({
        ...queryKeys.inventorySyncShops.list(filters),
    })
}

export const useUpdateInventorySyncShop = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (shop: any) => service.updateInventorySyncShop(shop),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.inventorySyncShops._def }),
    })
}

export const useDeleteInventorySyncShop = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (id: number) => service.deleteInventorySyncShop(id),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.inventorySyncShops._def }),
    })
}
