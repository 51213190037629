import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const departments = createQueryKeys('taskDepartments', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getDepartments(filters),
    }),
    detail: (departmentId: string) => ({
        queryKey: [departmentId],
        queryFn: () => service.getDepartmentConfig(departmentId),
    }),
    userDepartments: (userId: string) => ({
        queryKey: [userId],
        queryFn: () => service.getAllDepartmentConfigs(userId),
    }),
})

export const recurringTasks = createQueryKeys('recurringTasks', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getRecurringTasks(filters),
    }),
})

export const tasks = createQueryKeys('tasks', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getTasks(filters),
    }),
    detail: (task_id: string) => ({
        queryKey: [task_id],
        queryFn: () => service.getTask(task_id),
    }),
    options: (type: 'estimates' | 'types' | 'statuses') => ({
        queryKey: [type],
        queryFn: () => service.getTaskOptions(type),
    }),
})
export const events = createQueryKeys('events', {
    list: (taskIds: string[] | undefined) => ({
        queryKey: [taskIds],
        queryFn: () => service.getTaskEvents(taskIds),
    }),
})

export const associations = createQueryKeys('associations', {
    orders: (search: string) => ({
        queryKey: [search],
        queryFn: () => service.searchOrders(search),
    }),
    draftOrders: (search: string) => ({
        queryKey: [search],
        queryFn: () => service.searchDraftOrders(search),
    }),
    orderLineItemsSearch: (search: string) => ({
        queryKey: [search],
        queryFn: () => service.searchOrderLineItems(search),
    }),
    orderLineItems: (orderLineItemId: string | undefined, orderIds: string[] | undefined = []) => ({
        queryKey: [orderLineItemId, orderIds],
        queryFn: () => service.orderLineItems(orderLineItemId, orderIds),
    }),
    draftOrderLineItemsSearch: (search: string) => ({
        queryKey: [search],
        queryFn: () => service.searchDraftOrderLineItems(search),
    }),
    draftOrderLineItems: (draftOrderLineItemId: string | undefined, draftOrderIds: string[] | undefined = []) => ({
        queryKey: [draftOrderLineItemId, draftOrderIds],
        queryFn: () => service.draftOrderLineItems(draftOrderLineItemId, draftOrderIds),
    }),
})

export const projects = createQueryKeys('projects', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getProjects(filters),
    }),
    detail: (projectId: string | number) => ({
        queryKey: [projectId],
        queryFn: () => service.getProject(projectId),
        contextQueries: {
            tasks: () => ({
                queryKey: [projectId],
                queryFn: () => service.getProjectTasks(projectId),
            }),
            events: () => ({
                queryKey: [projectId],
                queryFn: () => service.getProjectEvents(projectId),
            }),
        },
    }),
})

export const roadmaps = createQueryKeys('roadmaps', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getRoadmaps(filters),
    }),
    detail: (roadmapId: string | number) => ({
        queryKey: [roadmapId],
        queryFn: () => service.getRoadmap(roadmapId),
        contextQueries: {
            projects: () => ({
                queryKey: [roadmapId],
                queryFn: () => service.getRoadmapProjects(roadmapId),
            }),
            events: () => ({
                queryKey: [roadmapId],
                queryFn: () => service.getRoadmapEvents(roadmapId),
            }),
        },
    }),
})

export const resourceTasks = createQueryKeys('resourceTasks', {
    list: (resource_name: string, resource_id: number) => ({
        queryKey: [resource_name, resource_id],
        queryFn: () => service.getResourceTasks({ resource_name, resource_id }),
    }),
})

export default mergeQueryKeys(
    departments,
    recurringTasks,
    tasks,
    events,
    associations,
    projects,
    roadmaps,
    resourceTasks
)
