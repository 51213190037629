import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { procurementPrivateEventQueries } from './privateEventQueryKeys'
import * as service from './service'

//company detail

export function useGetPrivateEvents({
    privateEventUrl,
    element,
    id,
    enabled = true,
}: {
    privateEventUrl: string
    element: string
    id?: number | string | undefined
    enabled?: boolean
}): any {
    const query = useQuery({
        ...procurementPrivateEventQueries.privateEvents.list({ privateEventUrl, element, id }),
        enabled: Boolean(id && enabled),
    })

    return query
}
export function useGetPrivateEventsV2({
    privateEventUrl,
    ids,
    enabled = true,
}: {
    privateEventUrl: string
    ids: number[] | string[] | undefined
    enabled?: boolean
}): any {
    const query = useQuery({
        ...procurementPrivateEventQueries.privateEvents.listV2({ privateEventUrl, ids }),
        enabled: Boolean(enabled),
    })

    return query
}

export function useCreatePrivateEvent() {
    return useMutation({
        mutationFn: ({
            privateEventUrl,
            id,
            ids,
            user_id,
        }: {
            privateEventUrl: string
            id?: number | string
            ids?: number[] | string[]
            user_id: number
        }) => {
            return service.createPrivateEvent({ privateEventUrl, id, ids, user_id })
        },
    })
}
