import { CheckCircledIcon, ExclamationTriangleIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import { ChangeEvent, useEffect, useState } from 'react'
import { BsArrowLeftSquare, BsArrowRightSquare } from 'react-icons/bs'
import { RiArrowGoBackLine, RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn, sendToast, vFetch } from '../../../helpers'
import { useCreateImportedProducts } from '../../api/useQueries'
import {
    CompanyType,
    FP_IMPORT_TEMPLATE_FIELD_OPTIONS,
    FP_IMPORT_TEMPLATE_HEADINGS_MAP,
    PROCUREMENT_PRODUCT_CSV_MAP_FIELD_OPTIONS,
    price_fields,
} from '../../constants'
import CompanyProductView from '../CompanyProductView'
import Input from '../Input'
import Select from '../Select'

export default function CSVMapper({
    company,
    file,
    setShowCSVMapper,
}: {
    company: CompanyType
    file?: any
    setShowCSVMapper: any
}) {
    const file_path_URL = new URL(file.src)
    const file_path = file_path_URL.pathname.slice(1)
    const [confirmed, setConfirmed] = useState(false)
    const [loading, setLoading] = useState(true)
    const [_searchParams, setSearchParams] = useSearchParams()
    const [exampleProductIndex, setExampleProductIndex] = useState(0)

    const [headings, setHeadings] = useState<string[]>([])

    const [headingsMap, setHeadingsMap] = useState<{}>(
        headings?.reduce((acc: any, curr: any) => ((acc[curr] = ''), acc), {})
    )
    const [rows, setRows] = useState<{ [key: string]: string }[]>([])
    const [csvFile, setCsvFile] = useState<File | undefined>()
    const selectedFields = new Set(Object.values(headingsMap))

    const MODE = process.env.REACT_APP_MODE
    const API_URL =
        MODE === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : MODE === 'development'
              ? process.env.REACT_APP_DEVELOPMENT_API_URL
              : process.env.REACT_APP_LOCAL_API_URL

    const isFPImport = file.type === 'FP Import Template' ? true : false
    const currentFieldOptions =
        file.type === 'FP Import Template'
            ? FP_IMPORT_TEMPLATE_FIELD_OPTIONS
            : PROCUREMENT_PRODUCT_CSV_MAP_FIELD_OPTIONS

    const hasRequiredFields = !Boolean(
        !selectedFields.has('sku') &&
            !selectedFields.has('supplier_sku') &&
            !selectedFields.has('model_number') &&
            !selectedFields.has('upc') &&
            !selectedFields.has('sku, supplier_sku')
    )
    const priceInputs = price_fields.map((field) => {
        return {
            field,
            values:
                rows
                    .map((row) => {
                        if (row[field]) {
                            return row[field]
                        }
                        return undefined
                    })
                    .filter((v) => v) || [],
        }
    })
    const invalidPriceInputs = priceInputs
        .map((inputs: any) => {
            if (!inputs.values) {
                return
            }
            const isValid = inputs?.values?.every((value: string) => {
                return getIsValidPrice(value.replace('$', '').replace(',', '').trim())
            })
            if (!isValid) {
                return inputs.field
            }
        })
        .filter((v) => v)

    const shopifySkus = rows
        .map((row) => {
            if (row['Shopify SKU']) {
                return row['Shopify SKU']
            }
            return undefined
        })
        .filter((v) => v)

    const UPCs: any =
        rows
            .map((row) => {
                if (row['UPC']) {
                    return row['UPC']
                }
                return undefined
            })
            .filter((v) => v) || []

    const shopifySkusSet = new Set(shopifySkus)
    const filteredRows = rows.filter((row: any) => {
        if (row['Shopify SKU']) {
            return row
        }
    })
    const hasUniqueShopifySkus = shopifySkusSet.size === filteredRows.length ? true : false

    const UPCSet = new Set(UPCs)
    const hasUniqueUPCs = UPCSet.size === UPCs.length ? true : false
    const hasValidUPCs = UPCs?.every((UPC: string) => {
        return getIsOnlyNumeric(UPC)
    })

    function getIsValidPrice(string: string) {
        // returns true if valid price format
        // numeric only, one '.', two decimal positions
        // eg - 345.99
        if (/^((\d|[1-9]\d+)(\.\d{1,2})?|\.\d{1,2})$/.test(string.trim())) {
            return true
        }
        return false
    }
    function getIsOnlyNumeric(string: string) {
        if (!/([^0-9])/.test(string.trim())) {
            return true
        }
    }

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setConfirmed(false)

        setHeadingsMap({
            ...headingsMap,
            [target.name]: target.value,
        })
    }

    const createImportedProducts = useCreateImportedProducts()

    const handleNext = () => {
        setExampleProductIndex((prev: any) => {
            if (!(prev + 1 > rows.length - 1)) {
                return prev + 1
            } else {
                return 0
            }
        })
    }
    const handlePrev = () => {
        setExampleProductIndex((prev: any) => {
            if (!(prev - 1 === -1)) {
                return prev - 1
            } else {
                return rows.length - 1
            }
        })
    }

    useEffect(() => {
        if (file_path) {
            setLoading(true)
            fetch(`${API_URL}/v1/csvParser?file_path=${encodeURIComponent(file_path)}`, {
                headers: {
                    Authorization: localStorage.getItem('session_token'),
                } as HeadersInit,
            }).then(async (res: any) => {
                const result = await getResultStream(res.body)
                const trimmedKeyRows = result.rows.map((row: any) => {
                    return Object.entries(row).reduce((acc: any, curr) => {
                        let [key, value] = curr
                        acc[typeof key === 'string' ? key.trim() : key] = value
                        return acc
                    }, {})
                })
                setHeadings(result.headings.map((heading: string) => heading.trim()))
                setRows(trimmedKeyRows)
                setLoading(false)
                if (file.type === 'FP Import Template') {
                    setHeadingsMap(FP_IMPORT_TEMPLATE_HEADINGS_MAP)
                } else {
                    setHeadingsMap(result.headings.reduce((acc: any, curr: any) => ((acc[curr] = curr), acc), {}))
                }
            })
        }
    }, [file_path])

    useEffect(() => {
        if (csvFile) {
            setLoading(true)
            let uploadId = ''
            parseFile(csvFile, async (result: string, chunkDetails: any) => {
                uploadId = chunkDetails.uploadId
                await vFetch('/v1/csvParser/upload', {
                    method: 'POST',
                    body: JSON.stringify({
                        total_chunks: chunkDetails.totalChunks,
                        chunk: { position: chunkDetails.position, value: result },
                        upload_id: chunkDetails.uploadId,
                    }),
                })
            }).then(() =>
                fetch(`${API_URL}/v1/csvParser?upload_id=${uploadId}`, {
                    headers: {
                        Authorization: localStorage.getItem('session_token'),
                    } as HeadersInit,
                }).then(async (res: any) => {
                    const result = await getResultStream(res.body)
                    setHeadings(result.headings.map((heading: string) => heading.trim()))
                    setHeadingsMap(result.headings.reduce((acc: any, curr: any) => ((acc[curr] = ''), acc), {}))
                    setRows(result.rows)
                    setLoading(false)
                })
            )
        }
    }, [csvFile])

    function handleCreate() {
        if (!confirmed) {
            return sendToast({ message: `Please confirm selections` })
        }
        if (isFPImport && !hasUniqueShopifySkus) {
            return sendToast({ message: `Please correct import so each product has a unique Shopify SKU` })
        }
        if (isFPImport && UPCs.length > 0 && !hasUniqueUPCs) {
            return sendToast({ message: `Please correct import so each product has a unique UPC` })
        }
        if (!hasRequiredFields) {
            return sendToast({ message: `Must provide shopify sku, supplier sku, upc or part number` })
        }
        if (!hasValidUPCs) {
            return sendToast({ message: `Please correct invalid value in UPC` })
        }
        if (invalidPriceInputs.length > 0) {
            return sendToast({ message: `Please correct invalid value in ${invalidPriceInputs.join(', ')}` })
        }
        const products = rows
            .map((row: any, index: number) => {
                let mappedProductInsert: any = {}
                Object.entries(headingsMap).forEach(([key, value]) => {
                    if (value === 'sku, supplier_sku') {
                        mappedProductInsert[value.split(',')[0].trim() as keyof typeof mappedProductInsert] =
                            row[key].trim()
                        mappedProductInsert[value.split(',')[1].trim() as keyof typeof mappedProductInsert] =
                            row[key].trim()
                    } else if (
                        value === 'cost' ||
                        value === 'list_price' ||
                        value === 'shipping_fee' ||
                        value === 'lowest_competitor_price'
                    ) {
                        mappedProductInsert[value as keyof typeof mappedProductInsert] = Number(
                            row[key].replace('$', '').replace(',', '').trim()
                        )
                    } else {
                        mappedProductInsert[value as keyof typeof mappedProductInsert] = row[key]?.trim()
                    }
                })
                return {
                    sku: `${index}-NEWPRODUCT-${Date.now()}`,
                    company_id: company.id,
                    status: 'calculating-margins',
                    variant_of: 0,
                    weblinks: [
                        { title: 'Manufacturer Website', link: mappedProductInsert?.manufacturer_website || '' },
                    ],
                    ...mappedProductInsert,
                }
            })
            .filter((product) => {
                if (product.sku) {
                    return product
                }
            })
        createImportedProducts.mutate(
            { products },
            {
                onSuccess: () => {
                    setSearchParams((prev: any) => {
                        prev.set('view', 'products')
                        return prev
                    })
                },
            }
        )
    }
    const productPlaceholder: any = {}
    const productFieldKeys = currentFieldOptions.map((option: any) => option.value)
    productFieldKeys.forEach((fieldKey) => {
        productPlaceholder[fieldKey as keyof typeof productPlaceholder] = undefined
    })
    productPlaceholder.cost = 0
    productPlaceholder.shipping_fee = 0
    productPlaceholder.list_price = 0
    let mappedProductInsert: any = {}
    Object.entries(headingsMap).forEach(([key, value]) => {
        if (rows.length > 0) {
            if (value === 'sku, supplier_sku') {
                mappedProductInsert[value.split(',')[0].trim() as keyof typeof mappedProductInsert] =
                    rows[exampleProductIndex][key]
                mappedProductInsert[value.split(',')[1].trim() as keyof typeof mappedProductInsert] =
                    rows[exampleProductIndex][key]
            } else if (
                value === 'cost' ||
                value === 'list_price' ||
                value === 'shipping_fee' ||
                value === 'lowest_competitor_price'
            ) {
                mappedProductInsert[value as keyof typeof mappedProductInsert] = Number(
                    rows[exampleProductIndex][key]
                        ? rows[exampleProductIndex][key].replace('$', '').replace(',', '')
                        : rows[exampleProductIndex][key]
                )
            } else {
                mappedProductInsert[value as keyof typeof mappedProductInsert] = rows[exampleProductIndex][key]
            }
        } else {
            mappedProductInsert[value as keyof typeof mappedProductInsert] = []
        }
    })
    const exampleProduct = sanitizeProduct({
        ...productPlaceholder,
        ...mappedProductInsert,
    })

    return (
        <div className='flex flex-col items-start gap-3 p-3 pb-20'>
            <div className='flex w-full gap-4'>
                <div className='flex flex-col gap-1'>
                    <label
                        className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit min-h-[12px]'
                        htmlFor='instructions'
                    >
                        Instructions
                    </label>
                    <div id='instructions' className='p-2 rounded-md border border-lightgrey dark:border-darkgrey'>
                        <p className='flex gap-2 items-center mb-4'>
                            <InfoCircledIcon />
                            New products only. Duplicate Shopify Skus will be ignored and no changes made.{' '}
                        </p>
                        <p>- Match imported fields with our internal labels</p>
                        <p>- Use example imported product values as a reference</p>
                        <p>- Match is not required for every field</p>
                        {!isFPImport && (
                            <p className={cn('flex gap-1 items-center')}>
                                -
                                {!hasRequiredFields ? (
                                    <span>
                                        <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                    </span>
                                ) : (
                                    <CheckCircledIcon className='text-success' />
                                )}
                                At least one of shopify sku, supplier sku, UPC, model number must be selected to
                                differentiate created products
                            </p>
                        )}
                        {isFPImport && (
                            <div className={cn('flex gap-1 items-center')}>
                                -
                                {!hasUniqueShopifySkus ? (
                                    <p className='flex gap-1 items-center'>
                                        <span>
                                            <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                        </span>
                                        Please correct import so each product has a unique Shopify SKU
                                    </p>
                                ) : (
                                    <p className='flex gap-1 items-center'>
                                        <CheckCircledIcon className='text-success' />
                                        Every import must have a unique shopify SKU
                                    </p>
                                )}
                            </div>
                        )}
                        {isFPImport && UPCs.length > 0 && (
                            <>
                                <div className={cn('flex gap-1 items-center')}>
                                    {!hasUniqueUPCs ? (
                                        <p className='flex gap-1 items-center'>
                                            -
                                            <span>
                                                <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                            </span>
                                            Please correct import so each product has a unique UPC
                                        </p>
                                    ) : (
                                        <p className='flex gap-1 items-center'>
                                            -
                                            <span>
                                                <CheckCircledIcon className='text-success' />
                                            </span>
                                            Each UPC is unique
                                        </p>
                                    )}
                                </div>
                                <div className={cn('flex gap-1 items-center')}>
                                    {!hasValidUPCs ? (
                                        <p className='flex gap-1 items-center'>
                                            -
                                            <span>
                                                <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                            </span>
                                            UPC may only contain numeric values
                                        </p>
                                    ) : (
                                        <p className='flex gap-1 items-center'>
                                            -
                                            <span>
                                                <CheckCircledIcon className='text-success' />
                                            </span>
                                            UPC only contains numeric values
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                        {invalidPriceInputs.length > 0 &&
                            invalidPriceInputs.map((input) => {
                                return (
                                    <div key={input} className={cn('flex gap-1 items-center')}>
                                        <p className='flex gap-1 items-center'>
                                            -
                                            <span>
                                                <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                            </span>
                                            Please correct invalid value in {input}
                                        </p>
                                    </div>
                                )
                            })}
                        <p>- Confirm Example Product</p>
                        <p>- Import Products</p>
                    </div>
                </div>
                <div className='flex flex-col gap-2 justify-between items-center'>
                    <Button
                        onClick={() => {
                            setShowCSVMapper(false)
                        }}
                        variant={'outline'}
                        size={'sm'}
                        className='mt-4'
                    >
                        <div className='flex gap-2'>
                            <RiArrowGoBackLine className='self-center' />
                            Back
                        </div>
                    </Button>
                    <div className='flex flex-col gap-2 items-center mx-auto'>
                        <p>Viewing Product: </p>
                        <div className='flex gap-3 items-end  mb-[-2px]'>
                            <BsArrowLeftSquare onClick={handlePrev} />
                            <p className='leading-none'>{exampleProductIndex + 1}</p>
                            <BsArrowRightSquare onClick={handleNext} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-[auto_1fr] w-full gap-3 '>
                <div className='w-fit flex flex-col gap-3'>
                    {loading &&
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((skeleton) => {
                            return (
                                <Input
                                    label={' '}
                                    id={skeleton.toString()}
                                    name={skeleton.toString()}
                                    onChange={handleChange}
                                    value={''}
                                    type='text'
                                    readOnly={true}
                                ></Input>
                            )
                        })}
                    {headings &&
                        headings?.map((heading: string, index: number) => {
                            return (
                                <Select
                                    label={heading}
                                    id={heading}
                                    name={heading}
                                    onChange={handleChange}
                                    value={headingsMap[heading.trim() as keyof typeof headingsMap] ?? ''}
                                    className='capitalize'
                                    disabled={isFPImport}
                                >
                                    <option value={''}></option>
                                    {currentFieldOptions.map((option: { value: string; label: string }) => {
                                        if (
                                            (option.value === 'sku' || option.value === 'supplier_sku') &&
                                            selectedFields.has('sku, supplier_sku')
                                        ) {
                                            return (
                                                <option disabled={true} value={option.value}>
                                                    {option.label}
                                                </option>
                                            )
                                        }
                                        if (
                                            option.value === 'sku, supplier_sku' &&
                                            (selectedFields.has('sku') || selectedFields.has('supplier_sku'))
                                        ) {
                                            return (
                                                <option disabled={true} value={option.value}>
                                                    {option.label}
                                                </option>
                                            )
                                        }
                                        if (!selectedFields.has(option.value)) {
                                            return <option value={option.value}>{option.label}</option>
                                        } else {
                                            return (
                                                <option disabled={true} value={option.value}>
                                                    {option.label}
                                                </option>
                                            )
                                        }
                                    })}
                                </Select>
                            )
                        })}
                </div>
                <div className='w-full flex flex-col gap-3'>
                    {loading &&
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((skeleton) => {
                            return (
                                <Input
                                    label={' '}
                                    id={skeleton.toString()}
                                    name={skeleton.toString()}
                                    onChange={handleChange}
                                    value={''}
                                    type='text'
                                    readOnly={true}
                                ></Input>
                            )
                        })}
                    {headings &&
                        rows.length > 0 &&
                        headings?.map((heading: string, index: number) => {
                            let className = ''

                            if (invalidPriceInputs.includes(heading)) {
                                if (!getIsValidPrice(rows[exampleProductIndex][heading].replace('$', '').trim())) {
                                    className = 'border border-danger dark:border-darkdanger'
                                }
                            }
                            if ((!hasUniqueUPCs || !hasValidUPCs) && heading === 'UPC') {
                                className = 'border border-danger dark:border-darkdanger'
                            }
                            if (!hasUniqueShopifySkus && heading === 'Shopify SKU') {
                                className = 'border border-danger dark:border-darkdanger'
                            }
                            return (
                                <Input
                                    label={index === 0 ? 'Example Import Values' : ' '}
                                    id={heading}
                                    name={heading}
                                    onChange={handleChange}
                                    type='text'
                                    value={rows[exampleProductIndex][heading]}
                                    readOnly={true}
                                    className={className}
                                ></Input>
                            )
                        })}
                </div>
            </div>
            <div className='flex flex-col gap-2'>
                <div className='flex gap-8 border-t w-full border-darkgrey dark:border-darkgrey'>
                    <p className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative pt-2 min-h-[12px] '>
                        Example Product Map Result
                    </p>
                    <div className='flex gap-3 items-end mb-[-2px]'>
                        <BsArrowLeftSquare onClick={handlePrev} />
                        <p className='leading-none'>{exampleProductIndex + 1}</p>
                        <BsArrowRightSquare onClick={handleNext} />
                    </div>
                </div>
                <CompanyProductView key={exampleProduct.id} product={exampleProduct} />
                <div className='flex items-center gap-2 justify-center'>
                    {!confirmed && <RiCheckboxBlankLine />}
                    {confirmed && <RiCheckboxLine />}

                    <Button onClick={() => setConfirmed(true)} className='w-fit' variant={'outline'} size={'default'}>
                        Confirm Import Selections
                    </Button>
                    <Button
                        onClick={handleCreate}
                        className={cn((!confirmed || !hasRequiredFields) && 'opacity-60', 'w-fit ml-[48px]')}
                        variant={'outline'}
                        size={'default'}
                    >
                        Import Products
                    </Button>
                </div>
            </div>
            <div className='flex items-center justify-end gap-2 w-full'></div>
        </div>
    )
}

async function parseFile(file: File, callback: any) {
    const fileDetails: FileDetailsType = {
        uploadId: guidGenerator(),
        file,
        fileSize: file.size,
        chunkSize: 4 * 1024 * 1024,
        totalChunks: Math.floor(file.size / (4 * 1024 * 1024)) + (file.size % (4 * 1024 * 1024) !== 0 ? 1 : 0),
    }
    const promises = []
    for (let i = 0; i < fileDetails.totalChunks; i++) {
        const offset = fileDetails.chunkSize * i
        const chunk = { ...fileDetails, position: i + 1, startingByte: offset }
        promises.push(readChunk(chunk, callback))
    }
    await Promise.all(promises).catch(console.error)
}
async function readChunk(
    options: ChunkDetailsType,
    callback: (chunk: any, currentFileDetails: FileDetailsType) => Promise<void>
) {
    const { file, chunkSize, startingByte } = options
    const chunk = file.slice(startingByte, startingByte + chunkSize)
    const result = await chunk.text()
    await callback(result, options)
    return 'SUCCESS'
}
function guidGenerator() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4()
}
async function getResultStream(readableStream: any) {
    const reader = readableStream.getReader()
    let result = ''

    let done, value
    while (!done) {
        ;({ value, done } = await reader.read())
        if (done) {
            return JSON.parse(result)
        }
        result += new TextDecoder().decode(value)
    }
}
function sanitizeProduct(product: any) {
    return {
        id: 1,
        product_type: product.product_type,
        status: product.status,
        upc: product.upc,
        sku: product.sku,
        supplier_sku: product.supplier_sku,
        model_number: product.model_number,
        part_number: product.part_number,
        weblinks: product.weblinks
            ? typeof product.weblinks === 'string'
                ? product.weblinks
                : JSON.stringify(product.weblinks)
            : undefined,
        cost: product.cost ? parseFloat(product.cost) : 0,
        shipping_fee: product.shipping_fee ? parseFloat(product.shipping_fee) : 0,
        list_price: product.list_price ? parseFloat(product.list_price) : 0,
        lowest_competitor_price: product.lowest_competitor_price,
        lowest_competitor_link: product.lowest_competitor_link,
        shopify_product_id: product.shopify_product_id,
        shopify_variant_id: product.shopify_variant_id,
        is_variant: product.is_variant,
        related_variants_group_id: product.related_variants_group_id,
        is_primary_variant: product.is_primary_variant,
        listed_by: product.listed_by,
        notes: product.notes,
        shipping_dimensions: product.shipping_dimensions,
        shipping_weight: product.shipping_weight,
        shipping_method: product.shipping_method,
        company_id: product.company_id,
        store_id: product.store_id,
    }
}

type FileDetailsType = {
    uploadId: string
    file: File
    fileSize: number
    chunkSize: number
    totalChunks: number
}
type ChunkDetailsType = FileDetailsType & {
    position: number
    startingByte: number
}
