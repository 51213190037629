import { CheckIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { addDays } from 'date-fns'
import { cn, sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { getDateToday } from 'procurement/constants'
import { useCreateLeadTime, useGetLeadTimeDetail, useGetProductVariantsQuery } from 'productSetupV2/api/useQueries'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FaExternalLinkAlt, FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { CalendarSelectorLeadTimes } from './CalendarSelectorLeadTimes'
import { LeadTime } from './InventoryLeadTimesList'
import LeadTimeProductSearch from './LeadTimeProductSearch'
import LeadTimeVariantSelector from './LeadTimeVariantSelector'
import RelatedLeadTimeList from './RelatedLeadTimeList'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

export default function LeadTimeDetailModal({
    leadTimeId,
    filters,
    search,
}: {
    leadTimeId: string
    filters?: any
    search?: string
}) {
    const user = useSelector((state: any) => state.user)
    const [searchParams] = useSearchParams()
    const modalRef = useRef<HTMLDivElement>(null)
    const [editableFields, setEditableFields] = useState<any>({
        inventory_policy: 'continue',
        preorder_days: 21,
    })
    const navigate = useNavigate()
    const [selectedDate, setSelectedDate] = useState<Date>(addDays(getDateToday(), 1))
    const [selectedProduct, setSelectedProduct] = useState<any>(undefined)
    const [selectedVariant, setSelectedVariant] = useState<any>(undefined)

    const createLeadTime = useCreateLeadTime()

    const handleEditableChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }
    const leadTimeDetailQuery = useGetLeadTimeDetail(leadTimeId, {
        filters,
        search,
    })
    const { leadTime }: { leadTime: LeadTime } = leadTimeDetailQuery?.data || {}

    const relatedLeadTimes: { id: number; quantity: number; available_at: any; status: string }[] | [] =
        leadTime?.related_lead_times ? JSON.parse(leadTime?.related_lead_times) : []

    const getProductVariantsQuery = useGetProductVariantsQuery(selectedProduct ? selectedProduct?.id : undefined)
    const { variants: productVariants }: { variants: any[] } = getProductVariantsQuery?.data || {}

    if (productVariants?.length === 1 && !selectedVariant) {
        setSelectedVariant(productVariants[0])
    }

    const tracks_inventory = editableFields?.tracks_inventory ?? selectedVariant?.tracks_inventory

    const closeModal = () => {
        navigate(`/products/inventory-lead-times?${searchParams.toString()}`)
    }

    const handleSave = () => {
        if (createLeadTime.isPending) {
            return
        }
        if (!editableFields.quantity) {
            return sendToast({ message: 'Must include quantity' })
        }
        createLeadTime.mutate(
            {
                leadTime: {
                    product_id: selectedProduct.id,
                    variant_id: selectedVariant.id,
                    quantity: editableFields.quantity,
                    inventory_policy: editableFields.inventory_policy,
                    preorder_days: editableFields.preorder_days,
                    date: selectedDate,
                    user_alias: user.user_id,
                },
            },
            {
                onSuccess: () => {
                    closeModal()
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                closeModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed top-[-40px] left-0 w-full h-[105%] bg-black/50 flex items-center justify-center z-50'
        >
            <div className='bg-bg1 dark:bg-darkbg1 max-w-[90%] max-h-[90%] min-w-[65%] min-h-[50%] p-6 rounded shadow-md relative'>
                <button className='absolute top-1 right-1 text-lg' onClick={closeModal}>
                    <FaTimes className='text-red' />
                </button>
                <div className='flex justify-between pb-1 border-b border-lightrey dark:border-darkgrey mb-2 min-h-[42px]'>
                    <h2 className='font-bold text-lg leading-tight flex gap-2'>
                        <p>Create New Lead Time</p>
                        {selectedVariant && (
                            <>
                                <p>-</p>
                                <p>{selectedVariant?.title}</p>
                            </>
                        )}
                    </h2>
                </div>
                {!selectedVariant && (!selectedProduct || getProductVariantsQuery.isFetching) && (
                    <LeadTimeProductSearch setSelectedProduct={setSelectedProduct} />
                )}
                {!getProductVariantsQuery.isFetching && selectedProduct && !selectedVariant && (
                    <LeadTimeVariantSelector setSelectedVariant={setSelectedVariant} variants={productVariants} />
                )}
                {selectedProduct && selectedVariant && (
                    <>
                        <div className='grid grid-cols-2 gap-4 min-h-[280px]'>
                            <div className='mb-2'>
                                <div className='flex gap-2 mb-2 items-center'>
                                    <h2 className='font-bold'>Product Details </h2>
                                    <Link
                                        className='flex gap-2 items-center font-normal text-sm'
                                        to={`/products/${selectedProduct.id}`}
                                    >
                                        <FaExternalLinkAlt />
                                    </Link>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex gap-2 items-center mb-2'>
                                        <Input
                                            className='cursor-default'
                                            id='sku'
                                            name='sku'
                                            label='Sku'
                                            type='text'
                                            readOnly={true}
                                            value={editableFields.sku ?? selectedVariant?.custom_supplier_sku}
                                            onChange={() => {}}
                                        ></Input>
                                        <Input
                                            className='cursor-default'
                                            id='product_id'
                                            name='product_id'
                                            label='Product Id'
                                            type='text'
                                            readOnly={true}
                                            value={editableFields.product_id ?? selectedVariant?.product_id}
                                            onChange={() => {}}
                                        ></Input>
                                    </div>
                                    <div className='flex gap-2 items-center mb-2 w-full'>
                                        <div className='flex gap-2 items-center'>
                                            <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                                Inventory Tracked
                                            </p>
                                            <div
                                                onClick={() =>
                                                    setEditableFields({
                                                        ...editableFields,
                                                        tracks_inventory: !tracks_inventory,
                                                    })
                                                }
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    tracks_inventory
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                        </div>
                                        <div className='flex gap-2 items-center'>
                                            <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                                Allow Preorders
                                            </p>
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    selectedVariant?.inventory_policy === 'continue'
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                        </div>
                                    </div>

                                    <Select
                                        className={cn()}
                                        label='Ignore Auto Updates'
                                        id='custom_ignore_auto_updates'
                                        name='custom_ignore_auto_updates'
                                        onChange={handleEditableChange}
                                        value={
                                            editableFields.custom_ignore_auto_updates ??
                                            selectedVariant?.custom_ignore_auto_updates
                                        }
                                    >
                                        <option value='1'>True</option>
                                        <option value='0'>False</option>
                                    </Select>
                                </div>
                            </div>
                            <div className='mb-6'>
                                <h2 className='font-bold mb-2'>Lead Time Details</h2>
                                <div className='flex h-full flex-col justify-end gap-2 pb-4'>
                                    <div className='flex flex-col gap-4'>
                                        <div className='flex gap-2 items-center'>
                                            <p className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                                Continue Selling during lead time window
                                            </p>
                                            <div
                                                onClick={() => {
                                                    return setEditableFields({
                                                        ...editableFields,
                                                        inventory_policy:
                                                            editableFields?.inventory_policy === 'continue'
                                                                ? 'deny'
                                                                : 'continue',
                                                    })
                                                }}
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    editableFields.inventory_policy === 'continue'
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                        </div>
                                        <Input
                                            className=''
                                            id='preorder_days'
                                            name='preorder_days'
                                            label='Lead Time Window (days)'
                                            type='number'
                                            value={editableFields.preorder_days ?? leadTime?.preorder_days}
                                            onChange={handleEditableChange}
                                        ></Input>
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-[1fr_15px_1fr] gap-1 col-span-2 mb-2'>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='available'
                                    >
                                        Current Available Quantity
                                    </label>
                                    <div className='flex gap-2 relative w-full'>
                                        <input
                                            id='available'
                                            type='text'
                                            value={selectedVariant?.inventory_quantity}
                                            name='available'
                                            autoComplete='off'
                                            readOnly={true}
                                            className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-darkbg2 dark:text-white rounded-[4px]'
                                            onChange={() => {}}
                                        />
                                    </div>
                                </div>
                                <div className='mt-[18px]'>{'=>'}</div>
                                <div className='flex flex-col gap-2'>
                                    <Input
                                        className=''
                                        id='quantity'
                                        name='quantity'
                                        label='Incoming Quantity'
                                        type='number'
                                        value={editableFields.quantity ?? ''}
                                        onChange={handleEditableChange}
                                    ></Input>

                                    <CalendarSelectorLeadTimes
                                        className={''}
                                        date={selectedDate}
                                        setDate={setSelectedDate}
                                        label='Available At'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                            <div className='flex gap-3 items-center'>
                                {createLeadTime.isPending && <FetchingSpinner isFetching={createLeadTime.isPending} />}
                                <Button
                                    onClick={handleSave}
                                    variant={'outline'}
                                    className={cn('border-success', createLeadTime.isPending && 'opacity-50')}
                                >
                                    Create Lead Time
                                </Button>
                            </div>
                        </div>
                        <RelatedLeadTimeList relatedLeadTimes={relatedLeadTimes} />
                    </>
                )}
            </div>
        </div>
    )
}
