import { fileTypeNames } from 'procurement/constants'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { cn, sendToast, sendToastWarning } from '../../../../helpers'
import { useCreateFile, useParentCompanyQuery } from '../../../api/useQueries'
import { CompanyContext, CompanyContextType, ProcurementContext, ProcurementContextType } from '../../../helpers'
import { CheckIcon } from '@radix-ui/react-icons'

export default function UploadFileV2({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { editableCompany } = useContext<CompanyContextType>(CompanyContext)
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)

    const user = useSelector<any, any>((state) => state.user)
    const [files, setFiles] = useState<FileList | null>(null)
    const [types, setTypes] = useState<string[]>([])
    const [selectedCompanyIds, setSelectedCompanyIds] = useState<number[]>([selectedCompany.id])

    const parentCompanyQuery = useParentCompanyQuery(editableCompany?.parent_company)

    const relatedCompanies = parentCompanyQuery?.data?.parentCompany?.companies
    const location = useLocation()
    const isOnVendorsPage = location.pathname.includes('vendors')
    const vendorFileList = ['Price List / SKUs', 'Inventory', 'Intake Form']
    let fileNameOptionsV2: any = []
    isOnVendorsPage ? (fileNameOptionsV2 = vendorFileList) : (fileNameOptionsV2 = fileTypeNames)

    const createFile = useCreateFile(setShowQuickModal)
    const { isPending } = createFile

    const handleSubmit = async () => {
        if (selectedCompanyIds.length === 0) {
            return sendToastWarning({ message: 'Please select a company to upload to' })
        }
        selectedCompanyIds.forEach((companyId) => {
            return createFile.mutate(
                { files, types, companyId, user },
                {
                    onSettled: () => {
                        return setShowQuickModal(undefined)
                    },
                }
            )
        })
    }

    useEffect(() => {
        if (files) {
            setTypes(Array(files.length).fill(''))
        }
    }, [files])

    console.log(selectedCompanyIds)

    return (
        <>
            <div className={cn(createFile.isPending && 'opacity-50')}>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex gap-[8px] items-center'>
                    <h3 className='leading-[1] font-bold'>New Upload</h3>
                </div>
                <div className='p-[16px] w-full min-h-[100px] flex flex-col gap-[4px] items-center justify-center text-center'>
                    {files &&
                        Array.from(files).map((file, index) => (
                            <div className='flex gap-[8px] w-full items-center'>
                                <p className='w-1/2 text-left break-all'>{file.name}</p>
                                <select
                                    className='bg-transparent rounded-[4px] border border-darkgrey focus:outline-none w-1/2'
                                    disabled={isPending}
                                    onChange={({ target }) =>
                                        setTypes((previousState) => {
                                            const newState = structuredClone(previousState)
                                            newState[index] = target.value
                                            return newState
                                        })
                                    }
                                >
                                    <option className='dark:bg-darkness/90' value=''>
                                        Choose A Type
                                    </option>
                                    {fileNameOptionsV2.map((option: any) => {
                                        return (
                                            <option className='dark:bg-darkness/90' value={option}>
                                                {option}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        ))}
                    <label
                        className='bg-darkgrey cursor-pointer text-white dark:text-offwhite font-bold py-[4px] px-[8px] rounded-[4px]'
                        htmlFor='file'
                    >
                        {files ? 'Change Files' : 'Choose Files'}
                    </label>
                    <input
                        className='appearance-none w-full h-full hidden'
                        id='file'
                        onChange={({ target }) => setFiles(target.files)}
                        type='file'
                        multiple
                        disabled={isPending}
                    />
                </div>
                {editableCompany?.parent_company && (
                    <div className='flex flex-col gap-2 px-2'>
                        <p>Upload to:</p>
                        {relatedCompanies?.length > 0 &&
                            relatedCompanies.map((relatedCompany: { id: number; name: string }) => {
                                return (
                                    <div className='flex gap-2 items-center'>
                                        <div
                                            onClick={() => {
                                                setSelectedCompanyIds((prev) => {
                                                    const newValue = new Set(prev)
                                                    if (newValue.has(relatedCompany.id)) {
                                                        newValue.delete(relatedCompany.id)
                                                    } else {
                                                        newValue.add(relatedCompany.id)
                                                    }
                                                    return Array.from(newValue)
                                                })
                                            }}
                                            className='flex gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    selectedCompanyIds.find((id) => id === relatedCompany.id)
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            <p>{relatedCompany.name}</p>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                )}
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <button
                        onClick={handleSubmit}
                        disabled={!files || createFile.isPending}
                        className='disabled:opacity-50 py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                    >
                        {createFile.isPending ? 'Uploading...' : 'Upload Files'}
                    </button>
                </div>
            </div>
        </>
    )
}
