import { useContext, useEffect, useRef, useState } from 'react'
import { CompanyContext } from '../helpers'
import { CompanyType, defaultCompanyLogo } from '../constants'
import { useSelector } from 'react-redux'
import { cn } from 'helpers'

export default function CompanyLogoV2({ company, updateCompany }: { company: CompanyType; updateCompany: any }) {
    const user = useSelector<any, any>((state) => state.user)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id') || '']
    const { write, read } = permissionSet?.procurement
    const newRef = useRef<HTMLDivElement>(null)
    const { setSelectedCompany } = useContext<any>(CompanyContext)
    const [editableCompany, setEditableCompany] = useState<any>(structuredClone(company))
    const [showChangeBox, setShowChangeBox] = useState(false)
    const [newSrc, setNewSrc] = useState('')

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!newRef.current?.contains(e.target as Element)) {
                setShowChangeBox(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <>
            <div className='relative shrink-0'>
                <img
                    className={cn(
                        'peer p-[4px] bg-lightgrey dark:bg-darkness w-[72px] h-[72px] object-contain rounded-full shrink-0',
                        !write && 'pointer-events-none'
                    )}
                    src={editableCompany.logo || defaultCompanyLogo}
                />
                <button
                    onClick={() => setShowChangeBox(true)}
                    className={cn(
                        'hidden peer-hover:block hover:block absolute top-[50%] translate-y-[-50%] left-0 w-full bg-blue py-[2px] px-[4px] rounded-[4px] text-[12px] font-bold text-white dark:bg-accent dark:text-darkness'
                    )}
                >
                    Change
                </button>
                {showChangeBox && (
                    <div
                        ref={newRef}
                        className='flex flex-col gap-[4px] w-[300px] bg-white p-[8px] dark:bg-darkaccent z-[41] absolute top-[100%] left-0'
                    >
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            New Image Src
                        </label>
                        <div className='flex gap-[8px] items-center'>
                            <input
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='name'
                                name='name'
                                onChange={({ target }) => setNewSrc(target.value)}
                                value={newSrc}
                            />
                            <button
                                onClick={() => {
                                    return updateCompany.mutate(
                                        { company: editableCompany },
                                        {
                                            onSuccess: () => {
                                                setSelectedCompany(editableCompany)
                                            },
                                        }
                                    )
                                }}
                                className='mx-auto py-[4px] px-[8px] text-[14px] font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] shadow-small'
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
