import { cn, formatDateToLocale } from 'helpers'
import { EventType } from 'procurement/Procurements.types'

export default function BasicEvent({ event, className }: { event: EventType; className?: string }) {
    const notTask = !event.message.split(' ').includes('task')

    const date = new Date(event.created_at)
    const monthDateYear = [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('/')
    const time = formatDateToLocale(event.created_at).split(' ').slice(-2).join(' ')
    return (
        <div className={cn('w-full relative p-2 rounded-[4px] dark:bg-darkness', className)}>
            <div className='max-w-full grid grid-cols-[minmax(200px,_1fr)_100px]'>
                <div className={cn('flex flex-col gap-2 text-[14px]', className)}>
                    <p className='break-words max-w-full font-bold'>{event.message}</p>
                    {(event.previous_value || event.value) && notTask && (
                        <p>
                            <span className='line-through'>{event.previous_value || 'NONE'}</span> {'=>'} {event.value}
                        </p>
                    )}
                </div>
                <div className={cn('flex flex-col  items-end justify-center text-[12px] h-[100%]', className)}>
                    <p className='dark:text-offwhite'>{monthDateYear}</p>
                    <p className={cn('text-darkgrey dark:text-grey', className)}>{time}</p>
                </div>
            </div>
        </div>
    )
}
