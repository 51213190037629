import { Cross2Icon } from '@radix-ui/react-icons'
import { cn } from 'helpers'
import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'
import { Button } from './Button'

export type ActiveFilterType = {
    // string in search params
    searchParamField: string
    // string client sees in active filter list
    label: string
    options: {
        label: string
        value: string
        icon: any
    }[]
    values: any[]
}[]

export function ActiveFilters({
    activeFilters,
    hideWhenNone = false,
}: {
    activeFilters: ActiveFilterType
    hideWhenNone?: boolean
}) {
    const [searchParams, setSearchParams] = useSearchParams()

    const filterValuesMap: any = {}
    const filterOptionsMap: any = {}
    const labelMap: any = {}
    activeFilters.forEach((activeFilter) => {
        filterValuesMap[activeFilter.searchParamField] = activeFilter.values
        filterOptionsMap[activeFilter.searchParamField] = activeFilter.options
        labelMap[activeFilter.searchParamField] = activeFilter.label
    })

    const hasFilterOptions = Object.entries(filterValuesMap).filter(([_key, val]: any) => val.length > 0).length > 0

    const clearFilterValue = (groupName: string, value: string) => {
        const newValues = filterValuesMap[groupName as keyof typeof filterValuesMap].filter((v: any) => v !== value)
        if (!newValues.length) {
            searchParams.delete(groupName.replaceAll(' ', ''))
            return setSearchParams(searchParams)
        }
        searchParams.set(groupName.replaceAll(' ', ''), encodeURIComponent(newValues.join(',')))
        setSearchParams(searchParams)
    }
    const handleResetAll = () => {
        const fieldList = activeFilters.map((activeFilter) => activeFilter.searchParamField)
        fieldList.forEach((field) => searchParams.delete(field))
        setSearchParams(searchParams)
    }
    if (hideWhenNone && !hasFilterOptions) {
        return <div></div>
    }

    return (
        <div className='flex items-center gap-2 min-h-[25px] flex-wrap w-full'>
            <div className='self-center py-[2px]'>
                <Button
                    disabled={hasFilterOptions ? false : true}
                    variant='ghost'
                    onClick={handleResetAll}
                    className={`${cn(
                        !hasFilterOptions && 'text-darkgrey dark:text-darkgrey opacity-50 bg-bg1 dark:bg-darkbg1',
                        hasFilterOptions && 'text-danger'
                    )} h-6 px-1 flex items-center `}
                >
                    <Cross2Icon className='h-4 w-4 dark:text-darktext1' />
                </Button>
            </div>
            <h3 className='text-xs font-semibold'>Active Filters:</h3>
            {hasFilterOptions && (
                <>
                    {Object.entries(filterValuesMap)
                        .filter(([_fvGroup, values]: any) => values.length > 0)
                        .map(([fvGroup, values]: any) => (
                            <React.Fragment key={fvGroup}>
                                <span className='text-xs font-medium capitalize'>
                                    {labelMap[fvGroup as keyof typeof labelMap]} ={' '}
                                </span>
                                {values.map((val: any) => (
                                    <button
                                        key={val}
                                        className='flex gap-1 items-center text-xs font-medium rounded border border-lightgrey dark:border-darkgrey p-1 leading-none bg-bg1 dark:bg-darkbg1 flex-nowrap'
                                        onClick={() => clearFilterValue(fvGroup, val)}
                                    >
                                        {filterOptionsMap[fvGroup as keyof typeof filterOptionsMap]?.find(
                                            (option: any) => option?.value === val
                                        )?.label || 'Unknown Filter'}
                                        <FaTimes className='text-darkgrey dark:text-darktext1' />{' '}
                                    </button>
                                ))}
                            </React.Fragment>
                        ))}
                </>
            )}
        </div>
    )
}
