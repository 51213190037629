import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const coreWebVitals = createQueryKeys('coreWebVitals', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getCoreWebVitals(filters),
    }),
})

export default mergeQueryKeys(coreWebVitals)
