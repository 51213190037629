import { useState } from 'react'
import DepartmentOptionRow from './DepartmentOptionRow'
import { Button } from 'custom_components/component_Basics/Button'
import { useCreateDepartmentOption, useGetTaskOptions, useUpdateDepartmentOption } from 'tasksV2/api/useQueries'
import TaskOptionsModal from '../modals/TaskOptionsModal'

export default function OptionEditor({
    departmentId,
    optionName,
    options,
}: {
    departmentId: string
    optionName: 'estimates' | 'types' | 'statuses'
    options: any
}) {
    const [showOptionsModal, setShowOptionsModal] = useState(false)
    const foreignColumnName = `task_${optionName != 'statuses' ? optionName.slice(0, -1) : optionName.slice(0, -2)}_id`
    const existingOptionsQuery = useGetTaskOptions({ type: optionName })
    const existingOptions = (existingOptionsQuery.data || {})[optionName] || []
    const availableOptions = existingOptions.filter(
        (option: any) => !options.find((o: any) => o[foreignColumnName] === option.id)
    )
    const createDepartmentOptionMutation = useCreateDepartmentOption(optionName)
    const updateOptionsMutation = useUpdateDepartmentOption(optionName)
    const [draggedItem, setDraggedItem] = useState(-1)
    const [draggedOverItem, setDraggedOverItem] = useState(-1)

    const handleCreateDepartmentOption = (optionId?: number) => {
        createDepartmentOptionMutation.mutate({
            task_type_id: optionId && optionName === 'types' ? optionId : undefined,
            task_estimate_id: optionId && optionName === 'estimates' ? optionId : undefined,
            task_status_id: optionId && optionName === 'statuses' ? optionId : undefined,
            position: options.length + 1,
            department_id: departmentId,
        })
    }

    const handleDrop = () => {
        if (draggedItem !== draggedOverItem) {
            updateOptionsMutation.mutate({
                id: options[draggedItem].id,
                position: draggedOverItem + 1,
            })
        }
    }

    return (
        <div className='w-full'>
            <h2 className='capitalize font-semibold mb-1'>Task {optionName}</h2>
            <div className='flex flex-col gap-1 mb-2 max-h-[200px] pr-2 overflow-auto'>
                {options.length === 0 ? (
                    <p className='p-2'>None selected</p>
                ) : (
                    <>
                        {options
                            .sort(({ position: a }: any, { position: b }: any) => a - b)
                            .map((option: any, index: number) => (
                                <DepartmentOptionRow
                                    onDragStart={(e: any) => setDraggedItem(index)}
                                    onDragEnter={(e: any) => setDraggedOverItem(index)}
                                    onDragOver={(e: any) => e.preventDefault()}
                                    onDrop={handleDrop}
                                    optionName={optionName}
                                    option={option}
                                />
                            ))}
                    </>
                )}
            </div>
            {availableOptions.length > 0 && (
                <div className='flex flex-col gap-1 border-b border-lightgrey dark:border-darkgrey pb-2'>
                    <p className='font-semibold'>Choose From Existing {optionName}</p>
                    <div className='flex flex-wrap gap-1 max-h-[200px] overflow-auto pr-2'>
                        {availableOptions.map((option: any) => (
                            <Button
                                className='h-fit p-2 py-1 dark:border-darkgrey'
                                variant={'outline'}
                                onClick={() => handleCreateDepartmentOption(option.id)}
                            >
                                + {option.value}
                            </Button>
                        ))}
                    </div>
                </div>
            )}
            {optionName != 'statuses' && (
                <Button
                    variant={'outline'}
                    className='mt-2 h-fit text-xs font-bold p-1 dark:border-darkgrey'
                    onClick={() => setShowOptionsModal(true)}
                >
                    Manage {optionName}
                </Button>
            )}
            {showOptionsModal && (
                <TaskOptionsModal optionName={optionName} closeModal={() => setShowOptionsModal(false)} />
            )}
        </div>
    )
}
